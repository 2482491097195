import {
    getCatelogListAPI,
    getCatelogByIdAPI
} from "./api"

export function getCatelogList(data) {
    return new Promise((resolve, reject) => {
        getCatelogListAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function getCatelogById(id) {
    return new Promise((resolve, reject) => {
        getCatelogByIdAPI(id).then((res) => resolve(res)).catch(err => reject(err))
    })
}