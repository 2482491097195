import React, { useEffect, useState } from 'react'
import { Grid, Box, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, withStyles, InputLabel, TextField } from "@material-ui/core"
import * as actions from "./actions"
import { toastError, toastSuccess } from '../../utils';
import moment from "moment"
import AddIcon from '@material-ui/icons/Add';
import CustomAddModal from "../../components/Common/CustomAddModal"
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SelectCmp from "react-select"
import { reasonList, ticketFilterOptions } from '../../constants';
import * as commonActions from "../common/actions"
import { useHistory } from "react-router-dom"
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const StyledButton = withStyles({
    root: {
        fontFamily: "'DM Sans', sans-serif",
        fontSize: 14,
        height: 35,
        width: 150,
        marginBottom: 20,
        // float: 'right',
        borderColor: "#0597b2",
        color: "#0597b2"
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);
const selectStyles = {
    control: (base, state) => ({
        ...base,
        border: `1px solid ${state.isFocused ? '#00a1ff' : '#767676'} `,
        boxShadow: 'none'
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#9f9f9f',
            fontFamily: `"DM Sans", sans-serif`
        }
    }
}

const inititalTicket = {
    reason: '',
    reply: '',
    attachement: []
}

export default function Tickets(props) {

    const history = useHistory();

    const [ticketsList, setTicketsList] = useState();
    const [ticket, setTicket] = useState({ ...inititalTicket })
    const [open, setOpen] = useState(false)
    const [files, setFiles] = useState(null)
    const [selectedTicket, setSelectedTicket] = useState({ label: "All", value: "all" })
    const [count, setCount] = useState(0)

    useEffect(() => {
        getAllTickets()
    }, [])

    const getAllTickets = () => {
        props.setLoader(true)
        actions.getTicketListForUser().then(res => {
            props.setLoader(false)
            setTicketsList(res.body)
            setCount(res.total || 0)
        }).catch(err => {
            props.setLoader(false)
            toastError(err.message)
        })
    }

    const onFileSelect = (filesObj) => {
        const files = Object.values(filesObj)

        if (Array.isArray(files) && files.length > 5) {
            return toastError('Max 5 attachments are allowed.')
        }
        let allFiles = []
        if (Array.isArray(files)) {
            for (let i = 0; i < files.length; i++) {
                if (!isValidExtension(files[i])) {
                    return toastError('Invalid file format selected.')
                }
                if (files[i].size > 2097152) {
                    return toastError('File more than 2 MB is not allowed.')
                } else {
                    allFiles.push(files[i])
                }
            }
        }
        setFiles(allFiles)
    }

    const handleClose = () => {
        setOpen(false)
        setTicket({ ...inititalTicket })
        setFiles(null)
    }

    const isValidExtension = (file) => {
        let lastDot = file.name.lastIndexOf(".")
        let ext = file.name.substring(lastDot + 1)
        if (ext === 'jpg' ||
            ext === 'txt' ||
            ext === 'pdf' ||
            ext === 'png' ||
            ext === 'doc' ||
            ext === 'jpeg' ||
            ext === 'excel'
        ) {
            return true
        }
        return false
    }

    const onSubmitTicket = async () => {
        if (!ticket.reason) {
            return toastError('Please select a reason.')
        }
        if (!ticket.reply) {
            return toastError('Reason details is Required.')
        }
        const filesArray = []
        props.setLoader(true)
        if (Array.isArray(files) && files.length) {
            for (let i = 0; i < files.length; i++) {
                await commonActions.upload(files[i]).then(res => {
                    filesArray.push(res.body.filename)
                }).catch(err => {
                    props.setLoader(false)
                    return toastError(err.message)
                })
            }
        }

        actions.createTicket({ ...ticket, attachement: filesArray.join(",") }).then(res => {
            props.setLoader(false)
            setOpen(false)
            setTicket({ ...inititalTicket })
            toastSuccess(res.message)
            getAllTickets()
        }).catch(err => {
            props.setLoader(false)
            toastError(err.message)
        })

    }

    let showTicketsList = ticketsList
    if (selectedTicket.value !== "all") {
        showTicketsList = ticketsList.filter(item => item.status === selectedTicket.value)

    }

    return (
        <Grid container>

            <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between">
                    <div style={{ width: 200 }}>
                        <SelectCmp
                            styles={selectStyles}
                            value={selectedTicket}
                            options={ticketFilterOptions}
                            onChange={e => setSelectedTicket(e)}
                        />
                    </div>
                    <StyledButton variant="outlined" onClick={() => setOpen(true)}><AddIcon style={{ fontSize: 18, marginRight: 5 }} /> New Ticket</StyledButton>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <h2 style={{ float: "right", marginBottom: 10 }}>Total Tickets: {count}</h2>
            </Grid>
            <Grid item xs={12}>
                <div className="ticket-info-tag"><p><ErrorOutlineIcon /> Due to high traffic of tickets, the response may vary from 24 hours to 1 week.</p></div>
            </Grid>
            <Grid item xs={12}>
                <Box>
                    <TableContainer component={Paper}>
                        <Table className="universal-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>No.</TableCell>
                                    <TableCell>Reason</TableCell>
                                    <TableCell>Created At</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    showTicketsList && showTicketsList.length ? showTicketsList.map((item, index) => {
                                        return <TableRow key={index} hover onClick={() => history.push(`/tickets/${item.ticket_id}`)}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell style={{ fontWeight: 700, fontSize: '18px' }}>{item.reason}</TableCell>
                                            <TableCell>{moment(item.date).format('DD/MM/yyyy')}</TableCell>
                                            <TableCell>{item.status === 0 ? <span className="pending-report">PENDING</span> : <span className="received-report">DONE</span>}</TableCell>
                                        </TableRow>
                                    })
                                        : null
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Grid>

            <CustomAddModal
                open={open}
                saveLabel="Create"
                handleClose={handleClose}
                onSubmitConfirm={onSubmitTicket}
                title="Add New Ticket"
                maxWidth="sm"
            >
                <Grid container className="add-song-details-container">

                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel>Reason</InputLabel>
                            <SelectCmp
                                styles={selectStyles}
                                options={reasonList}
                                onChange={e => setTicket({ ...ticket, reason: e.value })}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel>Tell us more about it</InputLabel>
                            <textarea
                                style={{ height: 150 }}
                                className="textarea-input-field"
                                placeholder="Type here..."
                                value={ticket.reply}
                                onChange={(e) => setTicket({ ...ticket, reply: e.target.value })}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel>Upload Attachement</InputLabel>
                            <label htmlFor="upload-reason" className="lable-upload-reason">
                                <div>
                                    <p>Choose file</p>
                                    <p>{files && Array.isArray(files) ? `${files.length} file(s) selected.` : `Click here to select files (max 5)`}</p>
                                </div>
                            </label>
                            <p style={{ fontSize: 13, textAlign: 'center', margin: 8 }}>Supported file formats: .jpg , .txt , .pdf , .png , .doc , .excel</p>
                            <p style={{ fontSize: 13, textAlign: 'center', margin: 8 }}>(You can select multiple files)</p>
                            <input type="file" id="upload-reason" onChange={e => onFileSelect(e.target.files)} multiple />
                        </Box>
                    </Grid>
                    {files && Array.isArray(files) && files.length ? <p style={{ fontWeight: 600 }}>Selected file list:</p> : null}
                    {
                        files && Array.isArray(files) && files.length ? files.map((item, index) => {
                            return <Grid item xs={12} key={index}>
                                <p style={{ paddingLeft: 8 }}>{index + 1}.&nbsp;{item.name}</p>
                            </Grid>
                        }) : null
                    }

                </Grid>
            </CustomAddModal>
        </Grid >
    )
}
