//----this is playlist pitchment---

import "./tools.scss";
import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Grid,
  InputLabel,
  TextField,
  Box,
  withStyles,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Chip,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import {
  API_URL,
  guidelinesTools,
  guidelinesToolsLyrics,
  trackTitleLanguage,
} from "../../constants";
import SelectCmp from "react-select";
import SaveIcon from "@material-ui/icons/Save";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CustomShowModal from "../../components/Common/CustomShowModal";
import { useLocation } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Instagram from "@material-ui/icons/Instagram";
import Facebook from "@material-ui/icons/Facebook";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { getHeaders, toastError, toastSuccess } from "../../utils";
import * as actions from "./actions";
import axios from "axios";
const selectStyles = {
  control: (base, state) => ({
    ...base,
    border: `1px solid ${state.isFocused ? "#00a1ff" : "#767676"} `,
    boxShadow: "none",
    background: "transparent",
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#9f9f9f",
      fontFamily: `"DM Sans", sans-serif`,
    };
  },
};

const platformOptions = [
  { value: "Youtube Content ID", label: "Youtube Content ID" },
];
const policyOptions = [
  { value: "Monetize", label: "Monetize" },
  { value: "Remove", label: "Remove" },
  { value: "Block", label: "Block" },
];

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const moodsOptions = [
  "Chill",
  "Motivation",
  "Happy",
  "Energetic",
  "Sad",
  "Romantic",
  "Sexy",
  "Spirtual",
  "Relax",
  "None of these",
];

const StyledButton = withStyles({
  root: {
    fontFamily: "'DM Sans', sans-serif",
    background: "#2d2d2d",
    fontSize: 14,
    height: 35,
    width: 150,
    borderColor: "#0597b2",
    margin: "auto",
    color: "white",
    "&:hover": {
      color: "black",
      border: "1px solid black",
    },
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

const CssTextField = withStyles({
  root: {
    // background: "transparent",
    fontFamily: "'DM Sans', sans-serif",
    color: "#fff",
    "& label": {
      fontFamily: "'DM Sans', sans-serif",
      // color: '#10baa8',
      color: "white",
    },
    "& label.Mui-focused": {
      // color: '#10baa8',
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "red",
    },
    "& .MuiOutlinedInput-root": {
      // '& fieldset': {
      // borderColor: 'white',
      // },
      // '&:hover fieldset': {
      // borderColor: 'white',
      // },
      "&.Mui-focused fieldset": {
        borderColor: "#10baa8",
        borderWidth: "1px",
      },
    },
  },
})(TextField);

const intitalUGCState = {
  release_id: "",
  release_title: "",
  audio_title: "",
  platform: "",
  policy: "",
  url: [], // multi url
};

const intitalProfileState = {
  release_id: "",
  release_title: "",
  audio_title: "",
  artist: "",
  facebook_link: "", // single url
  instagram_link: "", // single url
};

const intitalPlaylistState = {
  release_id: 1,
  release_title: "",
  audio_title: "",
  press_release: [], // multi url
  social_media: [], // multi url
  artwork_asset: "", // single url
  additional_assets: "", // single url
  moods: [], // multi url
  why_pitch: "",
  quick_pitch: "",
  additional_info: "",
};

function isValidUrl(str) {
  const pattern = new RegExp(
    "^([a-zA-Z]+:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i"
  );
  return pattern.test(str);
}

export default function Tools(props) {
  const { setLoader = () => {} } = props;
  const { pathname } = useLocation();

  const [toolType, setToolType] = useState("ugc"); // ugc, profile, playlist
  const [releases, setReleases] = useState([]);
  const [songs, setSongs] = useState([]);
  const [dataHistory, setDataHistory] = useState([]);
  const [guidelinesOpen, setGuidelinesOpen] = useState(false);
  const [selectedRelease, setSelectedRelease] = useState(null);
  const [selectedSong, setselectedSong] = useState(null);
  const [data, setData] = useState(JSON.parse(JSON.stringify(intitalUGCState))); // deep clone
  const [modalViewType, setModalViewType] = useState({
    type: "",
    urls: [],
  }); // url, press, social, moods, facebook, instagram,  artwork, additional,
  const [modalContentView, setModalContentView] = useState({
    show: false,
    component: null,
  });
  const [viewDetailsModal, setViewDetailsModal] = useState(null);
  const [detailsData, setDetailsData] = useState();
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState({
    limit: 20,
    offset: 0,
    page: 1,
  });
  const [lyricalData, setLyricalData] = useState({});

  useEffect(() => {
    setInitialData();
  }, [pathname]);

  useEffect(() => {
    onGetSongsByRelease();
  }, [selectedRelease]);

  let guidelinesToolsArray = "";
  if (toolType === "playlist") {
    guidelinesToolsArray = guidelinesTools;
  } else guidelinesToolsArray = guidelinesToolsLyrics;

  const setInitialData = async () => {
    try {
      let toolTypePath = "ugc";
      if (pathname === "/tools/profile-linking") {
        toolTypePath = "profile";
        setData(JSON.parse(JSON.stringify(intitalProfileState)));
      } else if (pathname === "/tools/playlist-pitchment") {
        toolTypePath = "playlist";
        setData(JSON.parse(JSON.stringify(intitalPlaylistState)));
      } else if (pathname === "/tools/distribute-lyrics") {
        toolTypePath = "lyrics";
      } else {
        toolTypePath = "ugc";
        setData(JSON.parse(JSON.stringify(intitalUGCState)));
      }
      setToolType(toolTypePath);
      if (toolTypePath !== toolType) {
        setDataHistory(null);
      }
      getInitialData(toolTypePath, filters);
      setSelectedRelease(null);
      setselectedSong(null);
    } catch (ex) {
      setLoader(false);
    }
  };

  const getInitialData = async (toolType, filters = {}) => {
    try {
      let releaseActions = Promise.resolve({});
      let historyAction = Promise.resolve({});
      if (toolType === "ugc") {
        releaseActions = actions.getReleaseTitles;
        historyAction = actions.getUGCClaimHistory;
      } else if (toolType === "profile") {
        releaseActions = actions.getReleaseTitles;
        historyAction = actions.getProfileLinkingHistory;
      } else if (toolType === "lyrics") {
        releaseActions = actions.getReleaseTitles;
        historyAction = actions.getLyricsHistory;
      } else {
        releaseActions = actions.getReleaseTitlesPlaylist;
        historyAction = actions.getPlaylistPitchHistory;
      }
      setLoader(true);
      const [{ body: releases = [] }, { body: dataHistory = [], total = 0 }] =
        await Promise.all([releaseActions(), historyAction(filters)]);
      setReleases(
        releases.map((item) => ({
          ...item,
          label: item.release_title,
          value: item.release_id,
        }))
      );
      setCount(total);
      setDataHistory(dataHistory);
      setLoader(false);
    } catch (ex) {
      setLoader(false);
    }
  };

  const onGetSongsByRelease = async () => {
    try {
      if (!selectedRelease.release_id) return;
      setSongs([]);
      setselectedSong(null);
      setLoader(true);
      const { body: songs = [] } =
        (await actions.getAudioTitlesByReleaseId(selectedRelease.release_id)) ||
        {};
      setSongs(
        songs.map((item) => ({
          ...item,
          label: item.title,
          value: item.audio_id,
        }))
      );
      setLoader(false);
    } catch (ex) {
      setLoader(false);
    }
  };

  const onPageChange = (event, page) => {
    const offset = (page - 1) * filters.limit;
    const newFilters = { ...filters, page, offset };
    setFilters(newFilters);
    getInitialData(toolType, newFilters);
  };

  const onHandleChange = (e = {}) => {
    const { name = "", value = "" } = e.target || {};
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const onHandleModalView = (type, urls = []) => {
    setModalViewType({
      type,
      urls: !urls.length && type !== "moods" ? [""] : urls,
    });
  };

  const onChangeMoods = (action, value) => {
    let updatedMoods = [...data.moods];
    if (action === "update") {
      if (value === "None of these") {
        updatedMoods = [value];
      } else {
        updatedMoods.push(value);
      }
    } else {
      updatedMoods = updatedMoods.filter((item) => item !== value);
    }
    setData((prev) => ({ ...prev, moods: updatedMoods }));
  };

  const onChangeUrls = (action, type, value, index) => {
    const updatedData = { ...data };
    if (type === "moods") {
      if (action === "update") {
        const prevdata = { ...modalViewType };
        prevdata.urls.push(value);
        setModalViewType(prevdata);
      } else {
        const updatedMoods = modalViewType.urls.filter(
          (item) => item !== value
        );
        setModalViewType((prev) => ({ ...prev, urls: updatedMoods }));
      }
    } else {
      if (action === "add") {
        setModalViewType((prev) => ({ ...prev, urls: [...prev.urls, ""] }));
        updatedData[type].push("");
      } else if (action === "delete") {
        const prevdata = { ...modalViewType };
        prevdata.urls.splice(index, 1);
        setModalViewType(prevdata);
      } else if (action === "update") {
        const prevdata = { ...modalViewType };
        prevdata.urls[index] = value;
        setModalViewType(prevdata);
      }
    }
  };

  const onAddUrls = () => {
    const { type, urls = [] } = modalViewType;
    if (type !== "moods") {
      if (!urls.length) return;
      for (let i = 0; i < urls.length; i++) {
        const isValid = isValidUrl(urls[i]);
        if (!isValid) {
          toastError(`Invalid URL at position ${i + 1}`);
          return;
        }
      }
    }
    const updatedData = { ...data };
    updatedData[type] = urls;
    setData(updatedData);
    setModalViewType({
      type: "",
      urls: [],
    });
  };

  const onSubmitForm = async () => {
    if (!selectedRelease || !selectedRelease?.label || !selectedSong?.label) {
      toastError(`Please fill all mandatory fields`);
      return;
    }
    try {
      let request = {};
      let sendObj = {
        release_id: selectedRelease.value,
        release_title: selectedRelease.label,
        audio_title: selectedSong.label,
      };
      if (toolType === "ugc") {
        if (
          !data.platform ||
          !selectedRelease ||
          !data.policy ||
          data?.url.length === 0 ||
          !sendObj.audio_title
        ) {
          toastError(`Please fill all mandatory fields`);
          return;
        }
        sendObj = {
          ...sendObj,
          platform: data.platform,
          policy: data.policy,
          url: data.url,
        };
        request = actions.submitUGCClaim;
      } else if (toolType === "profile") {
        if (!data.artist || !data.facebook_link || !data.instagram_link) {
          toastError(`Please fill all mandatory fields`);
          return;
        }
        if (!!data.facebook_link) {
          if (!isValidUrl(data.facebook_link)) {
            return toastError(`Invalid Facebook Link`);
          }
        }
        if (!!data.instagram_link) {
          if (!isValidUrl(data.instagram_link)) {
            return toastError(`Invalid Instagram Link`);
          }
        }
        sendObj = {
          ...sendObj,
          artist: data.artist,
          facebook_link: data.facebook_link,
          instagram_link: data.instagram_link,
        };
        request = actions.submitProfileLinking;
      } else if (toolType === "lyrics") {
        if (
          !lyricalData.lyrics_language ||
          !lyricalData.p_rights ||
          !lyricalData.lyrics
        ) {
          toastError(`Please fill all mandatory fields`);
          return;
        }
        sendObj = {
          ...sendObj,
          ...lyricalData,
        };
        request = actions.submitDistributeLyrics;
      } else {
        if (
          data.social_media.length === 0 ||
          !data.why_pitch ||
          !data.quick_pitch ||
          data.moods.length === 0
        ) {
          toastError(`Please fill all mandatory fields`);
          return;
        }
        if (!!data.additional_assets) {
          if (!isValidUrl(data.additional_assets)) {
            return toastError(`Invalid Additional Assets`);
          }
        }
        sendObj = {
          ...sendObj,
          press_release: data.press_release,
          social_media: data.social_media,
          artwork_asset: data.artwork_asset,
          additional_assets: data.additional_assets,
          moods: data.moods,
          why_pitch: data.why_pitch,
          quick_pitch: data.quick_pitch,
          additional_info: data.additional_info,
        };
        request = actions.submitPitchPlaylist;
      }
      setLoader(true);
      await request(sendObj);
      setLyricalData({});
      setSelectedFileName("");
      await setInitialData();
      toastSuccess("Submission successful.");
    } catch (ex) {
      setLoader(false);
      toastError(ex.message);
    }
  };

  const handleViewDetails = (data) => {
    setDetailsData(data);
    setViewDetailsModal(true);
  };

  const renderModalViewContent = (renderType, content) => {
    let component = null;
    switch (renderType) {
      case "urls": {
        component = (
          <Box>
            {Array.isArray(content) && content.length
              ? content.map((item) => {
                  return (
                    <Box textAlign="center" mb={1}>
                      <a href={item} target="_blank">
                        {item}
                      </a>
                    </Box>
                  );
                })
              : null}
          </Box>
        );
        break;
      }
      case "": {
        // other cases
        component = <></>;
      }
      default:
        component = null;
    }
    setModalContentView({ show: true, component });
  };

  const selectedPolicy = useMemo(
    () => policyOptions.find((item) => item.value === data.policy),
    [data]
  );
  const selectedPlatform = useMemo(
    () => platformOptions.find((item) => item.value === data.platform),
    [data]
  );

  const heading = useMemo(() => {
    let heading = "UGC Claims";
    if (toolType === "profile") {
      heading = "Profile Linking";
    } else if (toolType === "playlist") {
      heading = "Playlist Pitchment";
    } else if (toolType === "lyrics") {
      heading = "Distribute Lyrics";
    }
    return heading;
  }, [toolType]);

  let totalPages = Math.ceil(Number(count) / filters.limit);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const fileInputRef = useRef(null);
  const [selectedFileName, setSelectedFileName] = useState("");

  const handleFileInputChange = (event) => {
    setLoader(true);
    const file = event.target.files[0];
    setSelectedFileName(file.name);

    const data1 = new FormData();
    data1.append("file", file);
    axios
      .post(`${API_URL}/api/videoUpload`, data1, {
        headers: {
          ...getHeaders(),
        },
      })
      .then((res) => {
        setLoader(false);
        toastSuccess(res.data.message);
        setLyricalData({
          ...lyricalData,
          lrc_link: `${API_URL}/${res.data.body.filename}`,
        });
      })
      .catch((err) => {
        setLoader(false);
        toastError(err.message);
      });
  };

  const handleButtonClick = () => {
    // Trigger the file input dialog when the button is clicked
    fileInputRef.current.click();
  };

  return (
    <Grid container className="tools-container">
      <p className="heading">{heading}</p>
      <Grid item xs={12}>
        {toolType === "ugc" && (
          <div className="ticket-info-tag">
            <p>
              <ErrorOutlineIcon /> Misuse of tool will result in suspension of
              the service. For more info please email us at
              support@fronicmedia.com
            </p>
          </div>
        )}
        {toolType === "playlist" && (
          <>
            <div className="ticket-info-tag">
              <p>
                <ErrorOutlineIcon /> You can only pitch your new upcoming
                releases with this tool. For more info please email us at
                support@fronicmedia.com
              </p>
            </div>
            <p style={{ textAlign: "center", color: "red", fontWeight: 500 }}>
              NOTE: We can't guarantee a place on an editorial playlist when we
              pitch music. It's at the discretion of the Editorial team when
              they select tracks.
            </p>
          </>
        )}
        {(toolType === "playlist" || toolType === "lyrics") && (
          <div
            style={{
              justifyContent: "end",
              display: "flex",
              cursor: "pointer",
            }}
          >
            <p
              onClick={() => setGuidelinesOpen(true)}
              className="artwork-guidlines"
            >
              VIEW GUIDELINES
            </p>
          </div>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={8}
        style={{ margin: "auto", marginTop: "8px" }}
      >
        <Grid container className="input-field-container">
          <Grid item xs={12} sm={6}>
            <Box p={1}>
              <InputLabel>
                Select Release<span className="required-span">*</span>
              </InputLabel>
              <SelectCmp
                styles={selectStyles}
                options={releases}
                value={selectedRelease}
                placeholder="Select Release"
                onChange={setSelectedRelease}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box p={1}>
              <InputLabel>
                Select Audio<span className="required-span">*</span>
              </InputLabel>
              <SelectCmp
                styles={selectStyles}
                options={songs}
                value={selectedSong}
                placeholder="Select Audio"
                onChange={setselectedSong}
              />
            </Box>
          </Grid>

          {toolType === "ugc" && (
            <>
              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <InputLabel>
                    Select Platform<span className="required-span">*</span>
                  </InputLabel>
                  <SelectCmp
                    styles={selectStyles}
                    value={selectedPlatform || null}
                    options={platformOptions}
                    placeholder="Select Platform"
                    onChange={(e) =>
                      onHandleChange({
                        target: { name: "platform", value: e.value },
                      })
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <InputLabel>
                    Select Policy<span className="required-span">*</span>
                  </InputLabel>
                  <SelectCmp
                    styles={selectStyles}
                    value={selectedPolicy || null}
                    options={policyOptions}
                    placeholder="Select Policy"
                    onChange={(e) =>
                      onHandleChange({
                        target: { name: "policy", value: e.value },
                      })
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <InputLabel>
                    URL<span className="required-span">*</span>
                  </InputLabel>
                  <span
                    style={{
                      color: "blue",
                      cursor: "pointer",
                      fontWeight: 500,
                    }}
                    onClick={() => onHandleModalView("url", data.url || [])}
                  >
                    {(data.url && data.url.length) || 0} URL added (Click to
                    add)
                  </span>
                </Box>
              </Grid>
            </>
          )}
          {toolType === "profile" && (
            <>
              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <InputLabel>
                    Artist<span className="required-span">*</span>
                  </InputLabel>
                  <CssTextField
                    variant="outlined"
                    name="artist"
                    fullWidth
                    placeholder={`Make sure to enter the exact name of artist.`}
                    size="small"
                    value={data.artist || ""}
                    onChange={(e) => onHandleChange(e)}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <InputLabel>
                    Facebook Link<span className="required-span">*</span>
                  </InputLabel>
                  <CssTextField
                    variant="outlined"
                    name="facebook_link"
                    fullWidth
                    placeholder={`Enter the URL of the artist's Facebook page/account.`}
                    size="small"
                    value={data.facebook_link || ""}
                    onChange={(e) => onHandleChange(e)}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <InputLabel>
                    Instagram Link<span className="required-span">*</span>
                  </InputLabel>
                  <CssTextField
                    variant="outlined"
                    name="instagram_link"
                    fullWidth
                    placeholder={`Enter the URL of the artist's Instagram account.`}
                    size="small"
                    value={data.instagram_link || ""}
                    onChange={(e) => onHandleChange(e)}
                  />
                </Box>
              </Grid>
            </>
          )}
          {toolType === "playlist" && (
            <>
              <Grid item xs={12} sm={12}>
                <Box p={1}>
                  <InputLabel>
                    Why are you pitching this song?
                    <span className="required-span">*</span>
                  </InputLabel>
                  <CssTextField
                    variant="outlined"
                    name="why_pitch"
                    fullWidth
                    placeholder={`Describe the artist, the release, and any marketing or promotion efforts in detail. Only include a brief summary of important information in your copy. Always keep the most important details or sentences at the top!`}
                    size="small"
                    value={data.why_pitch || ""}
                    onChange={(e) => onHandleChange(e)}
                    multiline
                    rows="4"
                    inputProps={{
                      maxlength: 500,
                    }}
                    helperText={`${data.why_pitch.length}/${500}`}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box p={1}>
                  <InputLabel>
                    Quick Pitch<span className="required-span">*</span>
                  </InputLabel>
                  <CssTextField
                    variant="outlined"
                    name="quick_pitch"
                    fullWidth
                    placeholder={`One-sentence description of the release. Be unique!`}
                    size="small"
                    value={data.quick_pitch || ""}
                    onChange={(e) => onHandleChange(e)}
                    multiline
                    rows="2"
                    inputProps={{
                      maxlength: 100,
                    }}
                    helperText={`${data.quick_pitch.length}/${100}`}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box p={1}>
                  <InputLabel>Artwork Asset (PSD File of artwork)</InputLabel>
                  <CssTextField
                    variant="outlined"
                    name="artwork_asset"
                    fullWidth
                    placeholder={`Make sure the file/transfer is unlocked.`}
                    size="small"
                    value={data.artwork_asset || ""}
                    onChange={(e) => onHandleChange(e)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box p={1}>
                  <InputLabel>
                    Additional Assets (Artist Bytes, Promotional Clips etc)
                  </InputLabel>
                  <CssTextField
                    variant="outlined"
                    name="additional_assets"
                    fullWidth
                    placeholder={`Additional Assets`}
                    size="small"
                    value={data.additional_assets || ""}
                    onChange={(e) => onHandleChange(e)}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box p={1}>
                  <InputLabel>Additional Information</InputLabel>
                  <CssTextField
                    variant="outlined"
                    name="additional_info"
                    fullWidth
                    placeholder={`Write down some additional information.`}
                    size="small"
                    value={data.additional_info || ""}
                    onChange={(e) => onHandleChange(e)}
                    multiline
                    rows="3"
                    inputProps={{
                      maxlength: 250,
                    }}
                    helperText={`${data.additional_info.length}/${250}`}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <InputLabel>Link to Press Release</InputLabel>
                  <span
                    style={{
                      color: "blue",
                      cursor: "pointer",
                      fontWeight: 500,
                    }}
                    onClick={() =>
                      onHandleModalView(
                        "press_release",
                        data.press_release || []
                      )
                    }
                  >
                    {(data.press_release && data.press_release.length) || 0}{" "}
                    Link added (Click to add)
                  </span>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <InputLabel>
                    Artist's social links (Instagram, Facebook, Twitter etc)
                    <span className="required-span">*</span>
                  </InputLabel>
                  <span
                    style={{
                      color: "blue",
                      cursor: "pointer",
                      fontWeight: 500,
                    }}
                    onClick={() =>
                      onHandleModalView("social_media", data.social_media || [])
                    }
                  >
                    {(data.social_media && data.social_media.length) || 0} Links
                    added (Click to add)
                  </span>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <InputLabel>
                    Moods<span className="required-span">*</span>
                  </InputLabel>

                  <Box style={{ gap: "10px" }} display="flex" flexWrap="wrap">
                    {moodsOptions.map((item) => {
                      const isSelected = data.moods.includes(item);

                      let isDisabled =
                        (data.moods.includes("None of these") &&
                          item !== "None of these") ||
                        (data.moods.length === 2 &&
                          item !== data.moods[0] &&
                          item !== "None of these");
                      if (isSelected) {
                        return (
                          <Chip
                            disabled={isDisabled}
                            label={item}
                            onDelete={() => onChangeMoods("delete", item)}
                            color="primary"
                          />
                        );
                      }
                      return (
                        <Chip
                          disabled={isDisabled}
                          label={item}
                          onClick={() => onChangeMoods("update", item)}
                        />
                      );
                    })}
                  </Box>
                </Box>
              </Grid>
            </>
          )}
          {toolType === "lyrics" && (
            <>
              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <InputLabel>
                    Lyrics Language<span className="required-span">*</span>
                  </InputLabel>
                  <SelectCmp
                    styles={selectStyles}
                    options={trackTitleLanguage}
                    value={{
                      label: lyricalData.lyrics_language,
                      value: lyricalData.lyrics_language,
                    }}
                    placeholder="Select Lyrics Language"
                    onChange={(e) => {
                      setLyricalData({
                        ...lyricalData,
                        lyrics_language: e.value,
                      });
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <InputLabel>
                    Do you own the publishing rights of the lyrics ?
                    <span className="required-span">*</span>
                  </InputLabel>
                  <SelectCmp
                    styles={selectStyles}
                    options={[
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                      { label: "Not Sure", value: "Not Sure" },
                      {
                        label: "Public Domain Content",
                        value: "Public Domain Content",
                      },
                    ]}
                    value={{
                      label: lyricalData.p_rights,
                      value: lyricalData.p_rights,
                    }}
                    placeholder="Select Your Response"
                    onChange={(e) =>
                      setLyricalData({
                        ...lyricalData,
                        p_rights: e.value,
                      })
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <InputLabel>ISWC</InputLabel>
                  <CssTextField
                    variant="outlined"
                    name="instagram_link"
                    fullWidth
                    size="small"
                    value={lyricalData.iswc || ""}
                    onChange={(e) =>
                      setLyricalData({
                        ...lyricalData,
                        iswc: e.target.value,
                      })
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <InputLabel>Upload LRC</InputLabel>
                  <div className="custom-file-input-container">
                    <button
                      onClick={handleButtonClick}
                      className="custom-file-input-button"
                    >
                      Choose File
                    </button>
                    <div className="custom-file-input-selected">
                      <span>{selectedFileName}</span>
                      {selectedFileName && (
                        <CheckCircleIcon className="checkmark-icon" />
                      )}
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileInputChange}
                      accept=".lrc"
                    />
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box p={1}>
                  <InputLabel>
                    Lyrics
                    <span className="required-span">*</span>
                  </InputLabel>
                  <CssTextField
                    variant="outlined"
                    name="why_pitch"
                    fullWidth
                    placeholder={`You can even paste the lyrics in the below field, make sure to follow the spacing and formatting.`}
                    size="small"
                    value={lyricalData.lyrics || ""}
                    onChange={(e) =>
                      setLyricalData({
                        ...lyricalData,
                        lyrics: e.target.value,
                      })
                    }
                    multiline
                    rows="6"
                    // helperText={`${data.why_pitch.length}/${500}`}
                  />
                </Box>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6}>
            <Box
              p={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <StyledButton onClick={onSubmitForm}>
                <SaveIcon style={{ marginRight: "8px", fontSize: 17 }} /> Submit
              </StyledButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <h2 className="sub-heading">Your {heading} History</h2>
      </Grid>

      <Grid item xs={12}>
        <Box mt={4}>
          <TableContainer component={Paper}>
            <Table className="universal-table">
              <TableHead>
                <TableRow>
                  {/* {toolType === "ugc" && <TableCell>Claim Id</TableCell>} */}
                  <TableCell>Release Title</TableCell>
                  <TableCell>Audio Title</TableCell>
                  {toolType === "lyrics" && <TableCell>Language</TableCell>}
                  {toolType === "ugc" && <TableCell>Policy</TableCell>}
                  {toolType === "profile" && <TableCell>Artist</TableCell>}
                  {toolType === "profile" && <TableCell>FB</TableCell>}
                  {toolType === "profile" && <TableCell>IG</TableCell>}
                  <TableCell>Status</TableCell>
                  {toolType === "playlist" && (
                    <TableCell>Press Release</TableCell>
                  )}
                  {toolType === "playlist" && (
                    <TableCell>Social Links</TableCell>
                  )}
                  {toolType === "playlist" && <TableCell>Details</TableCell>}
                  <TableCell>Date</TableCell>
                  {toolType === "lyrics" && <TableCell>Details</TableCell>}
                  {toolType === "ugc" && <TableCell>URLs</TableCell>}
                </TableRow>
              </TableHead>

              <TableBody>
                {Array.isArray(dataHistory) && dataHistory.length
                  ? dataHistory.map((item, index) => {
                      return (
                        <TableRow key={index} hover>
                          {/* {toolType === "ugc" && <TableCell>{item.claim_id}</TableCell> } */}
                          <TableCell>{item.release_title || ""}</TableCell>
                          <TableCell>{item.audio_title || ""}</TableCell>
                          {toolType === "lyrics" && (
                            <TableCell>{item.lyrics_language}</TableCell>
                          )}
                          {toolType === "ugc" && (
                            <TableCell>{item.policy}</TableCell>
                          )}
                          {toolType === "profile" && (
                            <TableCell>{item.artist}</TableCell>
                          )}
                          {toolType === "profile" && (
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() => openInNewTab(item.facebook_link)}
                            >
                              <Facebook />
                            </TableCell>
                          )}
                          {toolType === "profile" && (
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() => openInNewTab(item.instagram_link)}
                            >
                              <Instagram />
                            </TableCell>
                          )}
                          <TableCell align="left">
                            <p>
                              {item.status === "in_review" ? (
                                <LightTooltip title="In Review">
                                  <AccessTimeIcon />
                                </LightTooltip>
                              ) : item.status === "rejected" ? (
                                <LightTooltip title="Rejected">
                                  <CancelIcon style={{ color: "red" }} />
                                </LightTooltip>
                              ) : (
                                <LightTooltip title="Approved">
                                  <CheckCircleIcon style={{ color: "green" }} />
                                </LightTooltip>
                              )}
                            </p>
                          </TableCell>
                          {toolType === "playlist" && (
                            <TableCell>
                              {item.press_release &&
                              !!item.press_release.length ? (
                                <span
                                  style={{
                                    color: "blue",
                                    cursor: "pointer",
                                    fontWeight: 500,
                                  }}
                                  onClick={() =>
                                    renderModalViewContent(
                                      "urls",
                                      item.press_release
                                    )
                                  }
                                >
                                  {item.press_release.length} Link(s)
                                </span>
                              ) : (
                                <span>No Links</span>
                              )}
                            </TableCell>
                          )}
                          {toolType === "playlist" && (
                            <TableCell>
                              {item.social_media &&
                              !!item.social_media.length ? (
                                <span
                                  style={{
                                    color: "blue",
                                    cursor: "pointer",
                                    fontWeight: 500,
                                  }}
                                  onClick={() =>
                                    renderModalViewContent(
                                      "urls",
                                      item.social_media
                                    )
                                  }
                                >
                                  {item.social_media.length} Link(s)
                                </span>
                              ) : (
                                <span>No Links</span>
                              )}
                            </TableCell>
                          )}
                          {toolType === "playlist" && (
                            <TableCell>
                              <span
                                style={{
                                  color: "blue",
                                  cursor: "pointer",
                                  fontWeight: 500,
                                }}
                                onClick={() => handleViewDetails(item)}
                              >
                                View Pitch
                              </span>
                            </TableCell>
                          )}
                          <TableCell>{item.date || ""}</TableCell>
                          {toolType === "lyrics" && (
                            <TableCell>
                              <span
                                style={{
                                  color: "blue",
                                  cursor: "pointer",
                                  fontWeight: 500,
                                }}
                                onClick={() => handleViewDetails(item)}
                              >
                                View Details
                              </span>
                            </TableCell>
                          )}
                          {toolType === "ugc" && (
                            <TableCell>
                              {item.url && !!item.url.length ? (
                                <span
                                  style={{
                                    color: "blue",
                                    cursor: "pointer",
                                    fontWeight: 500,
                                  }}
                                  onClick={() =>
                                    renderModalViewContent("urls", item.url)
                                  }
                                >
                                  {item.url.length} URL
                                </span>
                              ) : (
                                <span>No URL</span>
                              )}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
            <Pagination
              className="table-pagination"
              count={totalPages}
              showFirstButton
              showLastButton
              onChange={onPageChange}
              page={filters.page || 0}
            />
          </TableContainer>
        </Box>
      </Grid>
      <CustomShowModal
        open={guidelinesOpen}
        handleClose={() => setGuidelinesOpen(false)}
        maxWidth="sm"
        title="GUIDELINES"
      >
        {guidelinesToolsArray.map((item, key) => {
          return (
            <div style={{ display: "flex", padding: 5 }} key={key}>
              {key !== 0 ? (
                <span style={{ fontWeight: 600, marginRight: 5 }}>{key}.</span>
              ) : null}
              <p> {item}</p>
            </div>
          );
        })}
      </CustomShowModal>

      <CustomShowModal
        open={!!modalViewType.type}
        handleClose={() => setModalViewType({ type: "", urls: [] })}
        maxWidth="sm"
        title={modalViewType.type === "moods" ? "Select Moods" : "Add Url(s)"}
        description={
          toolType === "ugc"
            ? "Please remove '&feature=youtu.be' from your URL or the claim will not be released."
            : ""
        }
      >
        {generateModalContent(modalViewType, onChangeUrls, onAddUrls)}
      </CustomShowModal>

      <CustomShowModal
        open={modalContentView.show}
        handleClose={() =>
          setModalContentView({ show: false, component: null })
        }
        maxWidth="sm"
        title="View"
      >
        {modalContentView.component}
      </CustomShowModal>
      <CustomShowModal
        open={viewDetailsModal}
        handleClose={() => setViewDetailsModal(false)}
        maxWidth="sm"
        title={detailsData?.release_title || "Details"}
      >
        {toolType === "playlist" && (
          <div>
            <p style={{ wordBreak: "break-all", paddingBottom: "5px" }}>
              <strong>Artwork Assets:</strong>
              <br />
              <a href={detailsData?.artwork_asset}>
                {detailsData?.artwork_asset}
              </a>
            </p>

            <p style={{ wordBreak: "break-all", paddingBottom: "5px" }}>
              <strong>Additional Assets:</strong>
              <br />
              <a href={detailsData?.additional_assets}>
                {detailsData?.additional_assets}
              </a>
            </p>
            <strong>Moods:</strong>
            <ul>
              {detailsData?.moods.map((item) => {
                return <li>{item}</li>;
              })}
            </ul>
            <p style={{ wordBreak: "break-all", paddingBottom: "5px" }}>
              <strong>Your Pitch:</strong>
              <br />
              {detailsData?.why_pitch}
            </p>

            <p style={{ wordBreak: "break-all", paddingBottom: "5px" }}>
              <strong>Quick Pitch:</strong>
              <br />
              {detailsData?.quick_pitch}
            </p>

            <p style={{ wordBreak: "break-all", paddingBottom: "5px" }}>
              <strong>Additional Information:</strong>
              <br />
              {detailsData?.additional_info}
            </p>
          </div>
        )}

        {toolType === "lyrics" && (
          <div>
            <p style={{ wordBreak: "break-all", paddingBottom: "5px" }}>
              <strong>Publishing rights:</strong>
              <br />
              {detailsData?.p_rights}
            </p>

            <p style={{ wordBreak: "break-all", paddingBottom: "5px" }}>
              <strong>ISWC:</strong>
              <br />
              {detailsData?.iswc}
            </p>
            {/* <p style={{ wordBreak: "break-all", paddingBottom: "5px" }}>
              <a
                href={detailsData?.lrc_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Download LRC</strong>
              </a>
            </p> */}

            <p style={{ whiteSpace: "break-spaces", paddingBottom: "5px" }}>
              <strong>Lyrics:</strong>
              <br />
              {detailsData?.lyrics}
            </p>
          </div>
        )}
      </CustomShowModal>
    </Grid>
  );
}

const generateModalContent = (viewType, onChangeUrls, onAddUrls) => {
  const { type = "", urls = [] } = viewType;
  return (
    <Box>
      {type === "moods" ? (
        <>
          <Box style={{ gap: "10px" }} display="flex" flexWrap="wrap">
            {moodsOptions.map((item) => {
              const isSelected = urls.includes(item);
              if (isSelected) {
                return (
                  <Chip
                    label={item}
                    onDelete={() => onChangeUrls("delete", type, item, null)}
                    color="primary"
                  />
                );
              }
              return (
                <Chip
                  label={item}
                  onClick={() => onChangeUrls("update", type, item, null)}
                />
              );
            })}
          </Box>
          <Box mt={3} textAlign="center">
            {urls.length ? <span>{urls.length} Moods selected</span> : null}
          </Box>
        </>
      ) : (
        urls.map((item, index) => {
          const isValid = isValidUrl(item);
          return (
            <Box
              key={index}
              mb={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CssTextField
                variant="outlined"
                fullWidth
                placeholder={`Enter url ${index + 1}`}
                size="small"
                value={item}
                error={!isValid}
                onChange={(e) =>
                  onChangeUrls("update", type, e.target.value, index)
                }
              />
              {urls.length - 1 !== index || index > 8 || !isValid ? (
                <RemoveIcon
                  className="add-icon-button delete"
                  onClick={() => onChangeUrls("delete", type, null, index)}
                />
              ) : (
                <AddIcon
                  className="add-icon-button"
                  onClick={() => onChangeUrls("add", type, null, null)}
                />
              )}
            </Box>
          );
        })
      )}
      <Box mt={3} display="flex" justifyContent="center">
        <StyledButton onClick={onAddUrls}>Save</StyledButton>
      </Box>
    </Box>
  );
};
