import { toastError } from "../../../utils"
import {
    getArtistAPI,
    addArtistAPI,
    createReleaseInfoAPI,
    addSongInfoAPI,
    getLogosAPI,
    getDraftAPI,
    getSubmissionDetailsAPI,
    checkboxCrbtVideoPlatformsAPI,
    updateReleaseInfoAPI,
    updateSongInfoAPI,
    submissionButtonAPI,
    deleteDraftAPI,
    checkUserRevokeStatusAPI,
    getSubmissionDataChecksAPI,
    checkAwayStatusAPI
} from "./api"

export function addArtist(data) {
    return new Promise((resolve, reject) => {
        addArtistAPI(data).then((res) => resolve(res)).catch(err => {
            toastError(err.message)
            reject(err)
        })
    })
}

export function getArtist(data) {
    return new Promise((resolve, reject) => {
        getArtistAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function createReleaseInfo(data) {
    return new Promise((resolve, reject) => {
        createReleaseInfoAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function addSongInfo(data) {
    return new Promise((resolve, reject) => {
        addSongInfoAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function getLogos() {
    return new Promise((resolve, reject) => {
        getLogosAPI().then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function getSubmissionDetails(id) {
    return new Promise((resolve, reject) => {
        getSubmissionDetailsAPI(id).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function checkboxCrbtVideoPlatforms(id) {
    return new Promise((resolve, reject) => {
        checkboxCrbtVideoPlatformsAPI(id).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function getDraft(id) {
    return new Promise((resolve, reject) => {
        getDraftAPI(id).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function updateReleaseInfo(data) {
    return new Promise((resolve, reject) => {
        updateReleaseInfoAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function updateSongInfo(data) {
    return new Promise((resolve, reject) => {
        updateSongInfoAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function submissionButton(data) {
    return new Promise((resolve, reject) => {
        submissionButtonAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function deleteDraft(data) {
    return new Promise((resolve, reject) => {
        deleteDraftAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function checkUserRevokeStatus(data) {
    return new Promise((resolve, reject) => {
        checkUserRevokeStatusAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function getSubmissionDataChecks(data) {
    return new Promise((resolve, reject) => {
        getSubmissionDataChecksAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function checkAwayStatus(data) {
    return new Promise((resolve, reject) => {
        checkAwayStatusAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}