import React, { useState } from 'react'
import { Grid, Paper, Box, InputLabel, withStyles, TextField, Button } from "@material-ui/core"
import * as actions from "../common/actions"
import { toastError, toastSuccess, validateEmail } from '../../utils';

const CssTextField = withStyles({
    root: {
        fontFamily: "'DM Sans', sans-serif",
        color: "#fff",
        '& label': {
            fontFamily: "'DM Sans', sans-serif"
        },
        '& label.Mui-focused': {
            color: "#000"
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'red',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
            },
            '&:hover fieldset': {
            },
            '&.Mui-focused fieldset': {
                borderColor: '#10baa8',
                borderWidth: "1px"
            },
        },
    },
})(TextField);

const StyledSubmit = withStyles({
    root: {
        fontFamily: "'DM Sans', sans-serif",
        backgroundColor: "green",
        // background: 'linear-gradient(90deg, rgba(19,153,121,1) 0%, rgba(18,180,161,1) 45%, rgba(5,150,179,1) 100%)',
        fontSize: 14,
        height: 35,
        width: 150,
        borderColor: "#0597b2",
        margin: "auto",
        color: "white",
        '&:hover': {
            backgroundColor: "#1cb91c",

        }

    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

function Invite(props) {

    const [invite, setInvite] = useState({
        email: '',
        name: ''
    })

    const onSubmitSendEmail = () => {
        if (!validateEmail(invite.email)) return toastError('Invalid Email.')
        if (!invite.name) return toastError('Name is required.')
        props.setLoader(true)
        actions.sendInvite(invite).then(res => {
            props.setLoader(false)
            toastSuccess(res.message)
            setInvite({ email: '', name: '' })
        }).catch(err => {
            props.setLoader(false)
            toastError(err.message)
        })
    }

    return (
        <Grid container>
            <Grid item xs={12} sm={6} md={5} style={{ margin: "auto" }}>
                <Paper>
                    <Box p={2}>
                        <Grid container className="bank-details-modal">
                            <Grid item xs={12}>
                                <Box p={1}>
                                    <InputLabel>Email</InputLabel>
                                    <CssTextField
                                        placeholder="Email"
                                        variant="outlined"
                                        size="small"
                                        value={invite.email}
                                        onChange={(e) => setInvite({ ...invite, email: e.target.value })}
                                        fullWidth
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box p={1}>
                                    <InputLabel>Name</InputLabel>
                                    <CssTextField
                                        placeholder="Name"
                                        variant="outlined"
                                        size="small"
                                        value={invite.name}
                                        onChange={(e) => setInvite({ ...invite, name: e.target.value })}
                                        fullWidth
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} style={{ display: "flex", marginTop: 10 }}>
                                <StyledSubmit onClick={onSubmitSendEmail}>Send Invite</StyledSubmit>
                            </Grid>

                        </Grid>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default Invite
