import { apiGet, apiPost } from "../../utils"

export function getUserAPI() {
    return apiGet('/api/getUserDetails')
}

export function updateUserAPI(data) {
    return apiPost('/api/updateUserProfile', data)
}

export function getUserBankDetailsAPI(data) {
    return apiGet('/api/getUserBankDetails', data)
}

export function addBankDetailsAPI(data) {
    return apiPost('/api/addBankDetails', data)
}

export function withdrawMoneyAPI(data) {
    return apiPost('/api/withdrawMoney', data)
}