import {
    getUserAPI,
    updateUserAPI,
    withdrawMoneyAPI,
    addBankDetailsAPI,
    getUserBankDetailsAPI
} from "./api"

export function getUser(data) {
    return new Promise((resolve, reject) => {
        getUserAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function updateUser(data) {
    return new Promise((resolve, reject) => {
        updateUserAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function addBankDetails(data) {
    return new Promise((resolve, reject) => {
        addBankDetailsAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function withdrawMoney(data) {
    return new Promise((resolve, reject) => {
        withdrawMoneyAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function getUserBankDetails(data) {
    return new Promise((resolve, reject) => {
        getUserBankDetailsAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}
