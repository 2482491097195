import React, { useEffect, useState } from "react";
import {
  Grid,
  InputLabel,
  TextField,
  Box,
  withStyles,
  Button,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import WallpaperIcon from "@material-ui/icons/Wallpaper";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import SaveIcon from "@material-ui/icons/Save";
import * as actionRelease from "../../../containers/Releases/VideoReleases/actions";
import SelectCmp from "react-select";
import CustomShowModal from "../../../components/Common/CustomShowModal";

const selectStyles = {
  control: (base, state) => ({
    ...base,
    border: `1px solid ${state.isFocused ? "#00a1ff" : "#767676"} `,
    boxShadow: "none",
    background: "transparent",
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#9f9f9f",
      fontFamily: `"DM Sans", sans-serif`,
    };
  },
};

const StyledButton = withStyles({
  root: {
    fontFamily: "'DM Sans', sans-serif",
    background: "#2d2d2d",
    fontSize: 14,
    height: 35,
    width: 150,
    borderColor: "#0597b2",
    margin: "auto",
    color: "white",
    "&:hover": {
      color: "black",
      border: "1px solid black",
    },
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

const CssTextField = withStyles({
  root: {
    // background: "transparent",
    fontFamily: "'DM Sans', sans-serif",
    color: "#fff",
    "& label": {
      fontFamily: "'DM Sans', sans-serif",
      // color: '#10baa8',
      color: "white",
    },
    "& label.Mui-focused": {
      // color: '#10baa8',
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "red",
    },
    "& .MuiOutlinedInput-root": {
      // '& fieldset': {
      // borderColor: 'white',
      // },
      // '&:hover fieldset': {
      // borderColor: 'white',
      // },
      "&.Mui-focused fieldset": {
        borderColor: "#10baa8",
        borderWidth: "1px",
      },
    },
  },
})(TextField);

function CreateVideoRelease(props) {
  const {
    recipeFilePreview,
    onFileHandler,
    setVReleaseData,
    vReleaseData,
    selectedSong,
    setselectedSong,
    selectedRelease,
    setSelectedRelease,
    checkTerm,
    setCheckTerm,
    onSubmitVRelease,
  } = props;

  const [open, setOpen] = useState(false);
  const [releases, setReleases] = useState([]);
  const [songs, setSongs] = useState([]);
  const [platformLogo, setPlatformLogo] = useState([]);
  const [loader, setLoader] = useState(false);
  const [logoLoader, setLogoLoader] = useState(false);

  useEffect(() => {
    onFetchLogos();
    getInitialData();
    setTimeout(() => {
      setLoader(true);
    }, 4000);
  }, []);

  useEffect(() => {
    onGetSongsByRelease();
  }, [selectedRelease]);

  const getInitialData = async () => {
    try {
      setLoader(true);
      const response = await actionRelease.getReleaseTitlesForVRelease();
      setReleases(
        response.body.map((item) => ({
          ...item,
          label: item.release_title,
          value: item.release_id,
        }))
      );
      setLoader(false);
      setSelectedRelease(null);
    } catch (ex) {
      setLoader(false);
    }
  };

  const onGetSongsByRelease = async () => {
    try {
      if (!selectedRelease.release_id) return;
      setSongs([]);
      setselectedSong(null);
      setLoader(true);
      const { body: songs = [] } =
        (await actionRelease.getAudioTitlesByReleaseId(
          selectedRelease.release_id
        )) || {};
      setSongs(
        songs.map((item) => ({
          ...item,
          label: item.title,
          value: item.audio_id,
        }))
      );
      setLoader(false);
    } catch (ex) {
      setLoader(false);
    }
  };

  const onFetchLogos = () => {
    setLogoLoader(true);
    actionRelease
      .getLogos()
      .then((res) => {
        setLogoLoader(false);
        setPlatformLogo(res.body);
      })
      .catch(() => {
        setLogoLoader(false);
      });
  };

  let videoPlatformLogo = platformLogo.filter((item) => item.type === "3");

  return (
    <Grid container className="releaseinfo-container">
      <Grid item xs={12} sm={12} md={4}>
        <Grid container>
          <Box pt={2} width="100%" textAlign="center">
            <Grid item xs={12}>
              <div className="image-div" style={{width: '270px', borderRadius: '5px'}}>
                {recipeFilePreview ? (
                  <img src={recipeFilePreview} alt="" />
                ) : (
                  <label
                    htmlFor="releaseInfo-file-upload"
                    className="releaseInfo-file-upload-label"
                  >
                    <div>
                      <WallpaperIcon />
                      <p>Upload Video Thumbnail</p>
                      <p>Size: 1920px x 1080px</p>
                    </div>
                  </label>
                )}
              </div>
              {recipeFilePreview ? (
                <label
                  htmlFor="releaseInfo-file-upload"
                  className="change-image"
                >
                  Change
                </label>
              ) : null}
              <input
                type="file"
                id="releaseInfo-file-upload"
                accept="image/x-png,image/gif,image/jpeg"
                onChange={onFileHandler}
              />
            </Grid>
            <Grid item xs={12}>
              <Box textAlign="center" pt={3} style={{ cursor: "pointer" }}>
                <a className="artwork-guidlines" onClick={() => setOpen(true)}>
                MUSIC VIDEO GUIDELINES
                </a>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Grid container>
          <Grid item xs={12}>
            <Box p={1}>
              <InputLabel htmlFor="Genre">
                Select Release<span className="required-span">*</span>
              </InputLabel>

              <SelectCmp
                styles={selectStyles}
                options={releases}
                value={selectedRelease}
                placeholder="Select Release"
                onChange={setSelectedRelease}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box p={1}>
              <InputLabel htmlFor="ReleaseTitle">
                Download link to video<span className="required-span">*</span>
              </InputLabel>
              <CssTextField
                id="ReleaseTitle"
                variant="outlined"
                fullWidth
                placeholder="Make sure the file/transfer is unlocked."
                size="small"
                value={vReleaseData.download_link}
                onChange={(e) =>
                  setVReleaseData({
                    ...vReleaseData,
                    download_link: e.target.value,
                  })
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
                        <p style={{
                            fontSize: '12px',
                            textAlign: 'center',
                            marginTop: '-7px',
                            marginBottom: '12px',
                            color: '#555555'
                        }}>Preferred sites - <a href="https://wetransfer.com/" target="_blank" rel="noreferrer">wetransfer</a>, <a href="https://www.dropbox.com/" target="_blank" rel="noreferrer">Dropbox</a>, <a href="https://www.google.com/intl/en_ca/drive/download/" target="_blank" rel="noreferrer">GoogleDrive</a>, <a href="https://fromsmash.com/" target="_blank" rel="noreferrer">Formsmash</a></p>
                    </Grid>

          <Grid item xs={12}>
            <Box p={1}>
              <InputLabel htmlFor="Subgenre">
                Video UPC
              </InputLabel>
              <CssTextField
                id="Subgenre"
                variant="outlined"
                fullWidth
                size="small"
                value={vReleaseData.video_upc}
                onChange={(e) =>
                  setVReleaseData({
                    ...vReleaseData,
                    video_upc: e.target.value,
                  })
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={1}>
              <InputLabel htmlFor="Subgenre">
                Actor Name
              </InputLabel>
              <CssTextField
                id="Subgenre"
                variant="outlined"
                fullWidth
                size="small"
                placeholder="Separate them using comma (,)"
                value={vReleaseData.actor}
                onChange={(e) =>
                  setVReleaseData({
                    ...vReleaseData,
                    actor: e.target.value,
                  })
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <Grid container>
          <Grid item xs={12}>
            <Box p={1}>
              <InputLabel htmlFor="Genre">
                Select Audio<span className="required-span">*</span>
              </InputLabel>

              <SelectCmp
                styles={selectStyles}
                options={songs}
                value={selectedSong}
                placeholder="Select Audio"
                onChange={setselectedSong}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box p={1}>
              <InputLabel htmlFor="Releasedate">
                Release date<span className="required-span">*</span>
              </InputLabel>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  size="small"
                  format="DD/MM/yyyy"
                  placeholder="Select release date"
                  minDate={moment().add(6, "d").valueOf()}
                  fullWidth
                  InputProps={{ readOnly: true }}
                  InputAdornmentProps={{ position: "start" }}
                  onChange={(date) =>
                    setVReleaseData({
                      ...vReleaseData,
                      release_date: (date && date.valueOf()) || null,
                    })
                  }
                  value={vReleaseData.release_date || null}
                />
              </MuiPickersUtilsProvider>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box p={1} pt={4}>
              <InputLabel htmlFor="LabelName">
                Video ISRC
              </InputLabel>
              <CssTextField
                id="LabelName"
                variant="outlined"
                fullWidth
                // placeholder="Label Name"
                size="small"
                value={vReleaseData.video_isrc}
                onChange={(e) =>
                  setVReleaseData({
                    ...vReleaseData,
                    video_isrc: e.target.value,
                  })
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={1}>
              <InputLabel htmlFor="Subgenre">
                Actress Name
              </InputLabel>
              <CssTextField
                id="Subgenre"
                variant="outlined"
                fullWidth
                size="small"
                placeholder="Separate them using comma (,)"
                value={vReleaseData.actress}
                onChange={(e) =>
                  setVReleaseData({
                    ...vReleaseData,
                    actress: e.target.value,
                  })
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box mt={5}>
          <Grid container>
            <Grid item xs={12}>
              <Box p={1} display="flex" style={{ alignItems: "center" }}>
                <h2>Video Platform (8+)</h2>
                <Checkbox checked={"yes"} size="medium" name="video" />
              </Box>
            </Grid>
            {logoLoader ? (
              <div style={{ display: "flex", marginTop: 30, width: "100%" }}>
                <CircularProgress
                  size={30}
                  style={{ color: "black", margin: "auto" }}
                />
              </div>
            ) : (
              videoPlatformLogo.map((item, key) => {
                return (
                  <Grid key={key} item xs={12} sm={6} md={4}>
                    <Box pt={1} pb={1} display="flex" alignItems="center">
                      <img src={item.logo} height="40px" alt="" />
                      <Checkbox
                        checked
                        size="small"
                        name="video"
                        // onChange={handleChange}
                      />
                      <h4>{item.name}</h4>
                    </Box>
                  </Grid>
                );
              })
            )}
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box mt={4} display="flex" justifyContent="center" alignItems="center">
          <Checkbox
            value={checkTerm}
            onChange={(e) => setCheckTerm(e.target.checked)}
          />
          <h3>
            I understand and agree to the{" "}
            <a
              href="https://fronicmedia.com/distribution-agreement"
              target="_blank"
            >
              Fronic Media Distribution Terms
            </a>{" "}
            &{" "}
            <a target="_blank" href="http://fronicmedia.com/privacy-policy">
              Privacy Policy
            </a>
            .
          </h3>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box p={1} display="flex">
          <StyledButton onClick={onSubmitVRelease}>
            <SaveIcon style={{ marginRight: "8px", fontSize: 17 }} />
            Submit
          </StyledButton>
        </Box>
      </Grid>

      <CustomShowModal
                open={open}
                handleClose={() => setOpen(false)}
                maxWidth="md"
                title="MUSIC VIDEO GUIDELINES/SPECIFICATIONS"
            >
                <h2>Music Video Guidelines</h2>
                <p>Graphic Overlays.
                Music videos must not contain chyrons, static or scrolling lyrics, or lower-third graphics.
                <br /><br />
                Release Dates or Advertisements. Music videos must not contain release dates, logos, or advertisements.
                <br /><br />
                Nonstandard Music Videos. Music videos that are artist interviews, commentaries, behind the scenes, or similar in content must be bundled with a music album and cannot be sold individually.
                <br /><br />
                Promotional Still Image Videos. Promotional videos (such as videos displaying only the cover art and audio or slide shows) will not be accepted.
                <br /><br />
                Teasers, Trailers and Partial Videos. Music videos which have been shortened or edited into a promotional teaser, trailer or partial version will not be accepted.
                <br /><br />
                Music Video Quality. Music videos with poor quality (such as glitches, blurriness, incomplete video or audio, no audio, out of sync audio and video, and so on) will not be accepted.
                <br /><br />
                Explicit Content. Music videos that contain explicit language, nudity, drug references, or depictions of drug use must be marked “Explicit.”
                <br /><br />
                Music Videos from Films. Music videos taken from musical films must contain a title version that refers to the film such as (From “Name of Film”).</p>
                <br />
                <h2>Music Video Specifications.</h2>
                &#8226;&nbsp;<span style={{ fontWeight: 600 }}>File Format:</span> QuickTime (.mov files)<br />
                &#8226;&nbsp;<span style={{ fontWeight: 600 }}>Codec :</span>  Apple PRO RES 422 (HQ)<br />
                &#8226;&nbsp;<span style={{ fontWeight: 600 }}>Size :</span> 1920×1080 pixels<br />
                &#8226;&nbsp;<span style={{ fontWeight: 600 }}>Video Bitrate :</span> ~220 Mb/s<br />
                &#8226;&nbsp;<span style={{ fontWeight: 600 }}>Framerate :</span> 24 or 25 fps<br />
                &#8226;&nbsp;<span style={{ fontWeight: 600 }}>Colorspace :</span> YUV 422<br />
                &#8226;&nbsp;<span style={{ fontWeight: 600 }}>Gamma Range :</span> Between  2.15 and 2.25<br />
                &#8226;&nbsp;<span style={{ fontWeight: 600 }}>Pixel ratio :</span> Ratio must be 1:1 (square pixel)<br />
                &#8226;&nbsp;<span style={{ fontWeight: 600 }}>Progressive :</span> The video must not be interlaced<br />
                &#8226;&nbsp;<span style={{ fontWeight: 600 }}>Black frame begining/ending :</span> The video must contain one black frame at the beginning and at the end<br />
                &#8226;&nbsp;<span style={{ fontWeight: 600 }}>Black bar scopes and logos :</span> No logo will be accepted Black bars accepted but we need crop size information (in pixels). * crop left: The number of whole pixels from the left of the encoded image to remove. * crop top: The number of whole pixels from the top of the encoded image to remove. * crop right: The number of whole pixels from the right of the encoded image to remove. * crop bottom: The number of whole pixels from the bottom of the encoded image to remove.
                Sound Quality : Codec – PCM Sampling frequency, 48 kHz Sample size, 16 bits Audio bitrate, ~1536 kb/s, 2 Channels (stereo)
                {/* <p style={{ marginTop: 20 }}>Format: mp3 or wav</p>
                <p style={{ marginTop: 10 }}><span style={{ fontWeight: 600 }}>Requirements:</span> Minimum of 16 bit, 44.1 Khz, stereo</p>
                <p><span style={{ fontWeight: 600 }}>Recommended</span> 24 bits, 48Khz or 24 bits 96Khz.</p> */}
            </CustomShowModal>
    </Grid>
  );
}

export default CreateVideoRelease;
