import React, { useEffect, useState } from 'react'
import { Grid, Box, Paper } from '@material-ui/core'
import YoutubePlayer from '../../components/Common/YoutubePlayer'
import * as actions from "./actions"
import { toastError } from '../../utils'

export default function Dashboard() {

    const [videoLinks, setVideoLinks] = useState([])
    const [news, setNews] = useState([])

    useEffect(() => {
        actions.getVideoLinks().then(res => {
            setVideoLinks(res.body)
            // res.body.forEach((item, key) => setVideoLinks({ ...videoLinks, [key + 1]: item }))
        }).catch(err => {
            toastError(err.message)
        })
        actions.getNews().then(res => {
            setNews(res.body)
        }).catch(err => {
            toastError(err.message)
        })
    }, [])

    return (
        <Grid container className="dashboard-contianer">
            <Grid item xs={12} sm={12} md={4} lg={4}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box p={1}>
                            <h2>News</h2>
                        </Box>
                    </Grid>
                    {
                        news.map((item, key) => {
                            return <Grid key={key} item xs={12}>
                                <Box pt={2}>
                                    <Paper>
                                        <Box p={2}>
                                            <a style={{ fontWeight: 400, fontSize: 16, color: "black" }} href={item.news_link} target="_blank" rel="noreferrer" >{item.news_heading}</a>
                                        </Box>
                                    </Paper>
                                </Box>
                            </Grid>
                        })
                    }
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box p={1}>
                            <h2>Updates</h2>
                        </Box>
                    </Grid>
                    {
                        videoLinks.map((item, key) => {
                            if (key === 0) {
                                return <Grid key={key} item xs={12} className="youtube-large-video">
                                    <Box p={2}>
                                        <Paper>
                                            <YoutubePlayer videoId={item.video_link} />
                                        </Paper>
                                    </Box>
                                </Grid>

                            } else {
                                return <Grid key={key} item xs={12} sm={6} md={6} className="youtube-small-video">
                                    <Box p={2}>
                                        <Paper>
                                            <YoutubePlayer videoId={item.video_link} />
                                        </Paper>
                                    </Box>
                                </Grid>
                            }
                        })
                    }

                </Grid>
            </Grid>
        </Grid>
    )
}