import { toastError } from "../../../utils"
import {
    getLogosAPI,
    submitVideoReleaseAPI,
    checkAwayStatusAPI,
    getReleaseTitlesForVReleaseAPI,
    getAudioTitlesByReleaseIdAPI
} from "./api"

export function submitVideoRelease(data) {
    return new Promise((resolve, reject) => {
        submitVideoReleaseAPI(data).then((res) => resolve(res)).catch(err => {
            toastError(err.message)
            reject(err)
        })
    })
}

export function getLogos() {
    return new Promise((resolve, reject) => {
        getLogosAPI().then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function checkAwayStatus(data) {
    return new Promise((resolve, reject) => {
        checkAwayStatusAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function getReleaseTitlesForVRelease(data) {
    return new Promise((resolve, reject) => {
        getReleaseTitlesForVReleaseAPI(data)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }

  export function getAudioTitlesByReleaseId(data) {
    return new Promise((resolve, reject) => {
      getAudioTitlesByReleaseIdAPI(data)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }