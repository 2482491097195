import { apiPost, apiGet } from "../../utils"

export function uploadAPI(data) {
    const data1 = new FormData();
    data1.append("image", data)
    return apiPost(`/api/upload`, data1)
}

export function videoUploadAPI(data) {
    const data1 = new FormData();
    data1.append("image", data)
    return apiPost(`/api/videoUpload`, data1)
}

export function requestReportAPI(data) {
    return apiPost(`/api/requestReport`, data)
}

export function sendInviteAPI(data) {
    return apiPost(`/api/sendInvite`, data)
}

export function requestReportListAPI() {
    return apiGet(`/api/requestReportList`)
}

export function getNotificationsForUserAPI(offset = 0) {
    return apiGet(`/api/getNotificationsForUser?offset=${offset}`)
}

export function checkNotificationBellAPI() {
    return apiGet(`/api/checkNotificationBell`)
}