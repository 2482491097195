import React, { useEffect, useState, useCallback } from "react";
import * as actions from "./actions";
import {
  Box,
  Grid,
  TextField,
  withStyles,
  Table,
  TableBody,
  Tooltip,
  TableRow,
  TableHead,
  TableCell,
  TableContainer,
  Paper,
  Button,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { releaseFiltersForVideo } from "../../constants";
import moment from "moment";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CancelIcon from "@material-ui/icons/Cancel";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { toastError } from "../../utils";
import SelectCmp from "react-select";
import Pagination from "@material-ui/lab/Pagination";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const CssTextField = withStyles({
  root: {
    // background: "transparent",
    fontFamily: "'DM Sans', sans-serif",
    color: "#fff",
    width: "100%",
    "& label": {
      fontFamily: "'DM Sans', sans-serif",
      // color: '#10baa8',
      color: "white",
    },
    "& label.Mui-focused": {
      // color: '#10baa8',
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "red",
    },
    "& .MuiOutlinedInput-root": {
      // '& fieldset': {
      // borderColor: 'white',
      // },
      // '&:hover fieldset': {
      // borderColor: 'white',
      // },
      "&.Mui-focused fieldset": {
        borderColor: "#10baa8",
        borderWidth: "1px",
      },
    },
  },
})(TextField);

const selectStyles = {
  control: (base, state) => ({
    ...base,
    border: `1px solid ${state.isFocused ? "#00a1ff" : "#767676"} `,
    boxShadow: "none",
    background: "transparent",
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#c7c7c7",
    };
  },
};

export default function VideoCatalog(props) {
  const history = useHistory();

  const [catelogs, setCatelogs] = useState([]);
  const [loader, setLoader] = useState(true);
  const [count, setCount] = useState(0);

  const [filters, setFilters] = useState({
    sort: releaseFiltersForVideo[0],
    limit: 25,
    offset: 0,
    page: 1,
    search: "",
  });

  const delaySearch = useCallback(
    _.debounce((filters) => onGetCatelogList(filters), 500),
    []
  );

  useEffect(() => {
    onGetCatelogList(filters);
    setTimeout(() => {
      setLoader(false);
    }, 5000);
  }, []);

  const onGetCatelogList = (filters) => {
    props.setLoader(true);
    actions
      .getVReleaseHistory({ ...filters, sort: filters.sort.value })
      .then((res) => {
        props.setLoader(false);
        setCatelogs(res.body);
        setCount(res.total);
      })
      .catch((err) => {
        props.setLoader(false);
        toastError(err.message);
      });
  };

  const onPageChange = (event, page) => {
    const offset = (page - 1) * filters.limit;
    const newFilters = { ...filters, page, offset };
    setFilters(newFilters);
    onGetCatelogList(newFilters);
  };

  const onChangeSearch = (e) => {
    const newFilters = { ...filters, search: e.target.value };
    setFilters(newFilters);
    delaySearch(newFilters);
  };

  const onSelectType = (e) => {
    const newFilters = { ...filters, offset: 0, page: 1, sort: e };
    setFilters(newFilters);
    onGetCatelogList(newFilters);
  };

  let totalPages = Math.ceil(Number(count) / filters.limit);

  return (
    <Grid container className="catelog-container">
      <Grid item xs={12}>
        <Button
          className="edit-re-submit"
          onClick={() => history.push("/catalog")}
        >
          Go To Audio Catalog
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Box mt={3}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <CssTextField
                type="text"
                variant="outlined"
                size="small"
                onChange={onChangeSearch}
                value={filters.search}
                placeholder="Search..."
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div style={{ width: "90%", marginLeft: 10 }}>
                <SelectCmp
                  styles={selectStyles}
                  placeholder="Filters"
                  value={filters.sort}
                  onChange={onSelectType}
                  options={releaseFiltersForVideo}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <h2 style={{ float: "right" }}>
                <span style={{ fontWeight: 500 }}>Total Video Releases:</span>
                &nbsp;{count}
              </h2>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box mt={4}>
          <TableContainer component={Paper}>
            <Table className="universal-table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">No.</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Artwork</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Audio</TableCell>
                  <TableCell>Actor</TableCell>
                  <TableCell>Actress</TableCell>
                  {/* <TableCell>Link</TableCell> */}
                  <TableCell>Relase Date</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {catelogs && catelogs.length ? (
                  catelogs.map((item, key) => {
                    return (
                      <TableRow key={key}>
                        <TableCell align="center">
                          {(filters.page - 1) * filters.limit + (key + 1)}
                        </TableCell>
                        <TableCell align="center">
                          <p onClick={(e) => e.stopPropagation()}>
                            {item.status === "in_review" ? (
                              <LightTooltip title="In Review">
                                <AccessTimeIcon />
                              </LightTooltip>
                            ) : item.status === "draft" ? (
                              <LightTooltip title="Draft">
                                <BookmarksIcon />
                              </LightTooltip>
                            ) : item.status === "rejected" ? (
                              <LightTooltip title="Rejected">
                                <CancelIcon style={{ color: "red" }} />
                              </LightTooltip>
                            ) : item.status === "takendown" ? (
                              <LightTooltip title="Takendown">
                                <RemoveCircleOutlineIcon
                                  style={{ color: "red" }}
                                />
                              </LightTooltip>
                            ) : (
                              <LightTooltip title="Approved">
                                <CheckCircleIcon style={{ color: "green" }} />
                              </LightTooltip>
                            )}
                          </p>
                        </TableCell>
                        <TableCell style={{ padding: 4 }}>
                          <div className="album-artwork-catalog">
                            <img src={item.thumbnail} height="60px" alt="" />
                          </div>
                        </TableCell>
                        <TableCell>{item.release_title}</TableCell>
                        <TableCell>{item.audio_title}</TableCell>
                        <TableCell>{item.actor}</TableCell>
                        <TableCell>{item.actress}</TableCell>
                        {/* <TableCell>
                          <p>
                            <a href={item.download_link} target="_blank">
                              Click me
                            </a>
                          </p>
                        </TableCell> */}
                        <TableCell>
                          {moment(Number(item.release_date)).format(
                            "DD/MM/yyyy"
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan="10"
                      style={{ fontWeight: 600 }}
                    >
                      No Video Release Found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Pagination
              className="table-pagination"
              count={totalPages}
              showFirstButton
              showLastButton
              onChange={onPageChange}
              page={filters.page || 0}
            />
          </TableContainer>
        </Box>
      </Grid>
    </Grid>
  );
}
