import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
// import * as commonActions from "../common/actions"
import ReactDOM from "react-dom";
import moment from "moment";

function Notification(props) {
  const { notification = [], notificationLoader = false } = props;

  const notificationRef = useRef(null);

  let modifiedNotification = [];
  for (let i = 0; i < notification.length; i++) {
    let text = notification[i].notification || "";
    let date = notification[i].created_at || "";
    const textSplit = text.split(" ");
    const subContent = [];
    for (let j = 0; j < textSplit.length; j++) {
      if (textSplit[j].includes("http")) {
        subContent.push(
          <a href={textSplit[j]} target="_blank" rel="noreferrer">
            {textSplit[j]}&nbsp;
          </a>
        );
      } else {
        subContent.push(<span>{textSplit[j]}&nbsp;</span>);
      }
    }
    modifiedNotification.push(
      <div className="content-div">
        <p>
          <span style={{ marginRight: 5 }}>&bull;</span>
          {subContent.map((item) => {
            return item;
          })}
        </p>
        <span className="date-time">{moment(date).format("hh:mm A DD/MM/yyyy")}</span>
      </div>
    );
  }

  return (
    <div className="notification-popover">
      <div className="content-container" ref={notificationRef}>
        {modifiedNotification.length ? (
          modifiedNotification.map((item) => item)
        ) : (
          <div className="no-notification">
            <p>No notification yet.</p>
          </div>
        )}
      </div>
      {notificationLoader ? (
        <div className="loader-container">
          <CircularProgress size={25} style={{ color: "white" }} />
        </div>
      ) : null}
    </div>
  );
}

export default Notification;
