import React, { useState } from 'react'
import { Grid, InputLabel, TextField, Box, withStyles, Button, Tooltip } from '@material-ui/core'
import { artworkGuidlines, releaseType, staticGenre } from '../../../constants'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from "moment"
import AddIcon from '@material-ui/icons/Add';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import SelectCmp from "react-select"
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import CustomShowModal from "../../../components/Common/CustomShowModal"

const selectStyles = {
    control: (base, state) => ({
        ...base,
        border: `1px solid ${state.isFocused ? '#00a1ff' : '#767676'} `,
        boxShadow: 'none',
        background: "transparent"
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#9f9f9f',
            fontFamily: `"DM Sans", sans-serif`
        }
    }
}


const StyledButton = withStyles({
    root: {
        fontFamily: "'DM Sans', sans-serif",
        background: '#2d2d2d',
        fontSize: 14,
        height: 35,
        width: 150,
        borderColor: "#0597b2",
        margin: "auto",
        color: "white",
        '&:hover': {
            color: "black",
            border: '1px solid black'
        }
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

const CssTextField = withStyles({
    root: {
        // background: "transparent",
        fontFamily: "'DM Sans', sans-serif",
        color: "#fff",
        '& label': {
            fontFamily: "'DM Sans', sans-serif",
            // color: '#10baa8',
            color: "white"
        },
        '& label.Mui-focused': {
            // color: '#10baa8',
            color: "white"
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'red',
        },
        '& .MuiOutlinedInput-root': {
            // '& fieldset': {
            // borderColor: 'white',
            // },
            // '&:hover fieldset': {
            // borderColor: 'white',
            // },
            '&.Mui-focused fieldset': {
                borderColor: '#10baa8',
                borderWidth: "1px"
            },
        },
    },
})(TextField);


function ReleaseInfo(props) {

    const {
        releaseInfo,
        setReleaseInfo,
        recipeFilePreview,
        onFileHandler,
        primaryArtist,
        featuringArtist,
        setOpenAddPrimary,
        setOpenAddFeaturing,
        setAddArtist,
        addArtist,
        editMode,
        createReleaseInfo,
        removeSelectedPrimary,
        onChangeSelect,
        selectedPrimaryArtist = [],
        selectedFeaturingArtist = []
    } = props;

    const [open, setOpen] = useState(false)

    const selectedGenre = staticGenre.find(item => item.value === releaseInfo.genre)

    return (
        <Grid container className="releaseinfo-container">
            <Grid item xs={12} sm={12} md={4}>
                <Grid container>
                    <Box pt={2} width="100%" textAlign="center" >
                        <Grid item xs={12}>
                            <div className="image-div">
                                {recipeFilePreview ? <img src={recipeFilePreview} alt="" /> : <label htmlFor="releaseInfo-file-upload" className="releaseInfo-file-upload-label">
                                    <div>
                                        <WallpaperIcon />
                                        <p>Upload Album Artwork</p>
                                    </div>
                                </label>}
                            </div>
                            {
                                recipeFilePreview ? <label htmlFor="releaseInfo-file-upload" className="change-image">Change</label> : null
                            }
                            <input
                                type="file"
                                id="releaseInfo-file-upload"
                                accept="image/x-png,image/gif,image/jpeg"
                                onChange={onFileHandler}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box textAlign="center" pt={3}>
                                <a className="artwork-guidlines" onClick={() => setOpen(true)}>ARTWORK GUIDELINES</a>
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>

                <Grid container>

                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel htmlFor="ReleaseType">Release Type<span className="required-span">*</span></InputLabel>
                            <div className="release-type">
                                {
                                    releaseType.map((item, key) => {
                                        const isActive = item.label.toLowerCase() === releaseInfo.release_type
                                        return <p key={key} className={`${isActive ? 'active-type' : ""}`} onClick={() => setReleaseInfo({ ...releaseInfo, release_type: item.label.toLowerCase() })}>{item.label}</p>
                                    })
                                }
                            </div>

                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel htmlFor="ReleaseTitle">Release Title<span className="required-span">*</span></InputLabel>
                            <CssTextField
                                id="ReleaseTitle"
                                variant="outlined"
                                fullWidth
                                placeholder="Release Title"
                                size="small"
                                value={releaseInfo.release_title}
                                onChange={(e) => setReleaseInfo({ ...releaseInfo, release_title: e.target.value })}
                            />

                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel htmlFor="ReleaseArtist">Primary Artist<span className="required-span">*</span></InputLabel>
                            <Grid container>
                                <Grid item xs={11}>
                                    <SelectCmp
                                        styles={selectStyles}
                                        options={primaryArtist}
                                        placeholder="Select Primary Artist"
                                        onChange={(e) => onChangeSelect(e, "primary")}
                                    />

                                </Grid>
                                <Grid item xs={1} style={{ alignSelf: "center" }}>
                                    <AddIcon
                                        className="add-icon-button"
                                        onClick={() => { setAddArtist({ ...addArtist, 'artist_type': "primary" }); setOpenAddPrimary(true) }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                {
                                    selectedPrimaryArtist.length ? selectedPrimaryArtist.map((item, key) => <Grid item xs={12} key={key}>
                                        <Box display="flex" justifyContent="space-between" p={1}>
                                            <div style={{ fontSize: "12px", display: "flex" }}>
                                                <div><p style={{ fontWeight: 600, marginRight: 5 }}>{key + 1}.</p></div>
                                                <div>
                                                    <p style={{ fontWeight: 600 }}>Artist Name: {(item && item.artist_name) || ''}</p>
                                                    <p>Apple Id: {(item && item.artist_name) || ''}</p>
                                                    <p>Spotify Id: {(item && item.artist_name) || ''}</p>

                                                </div>
                                            </div>
                                            <div><ClearIcon onClick={() => removeSelectedPrimary("primary", key)} /></div>
                                        </Box>
                                    </Grid>)
                                        : null}
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel htmlFor="Featuring">Featuring Artist</InputLabel>
                            <Grid container>
                                <Grid item xs={11}>
                                    {/* <CssTextField
                                        id="Featuring"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Featuring"
                                        size="small"
                                        value={partner.companyName}
                                        onChange={(e) => onChangeHandler(e.target.value, 'companyName')}
                                    /> */}
                                    <SelectCmp
                                        styles={selectStyles}
                                        options={featuringArtist}
                                        placeholder="Select Featuring Artist"
                                        onChange={(e) => onChangeSelect(e, "")}
                                    />
                                </Grid>
                                <Grid item xs={1} style={{ alignSelf: "center" }}>
                                    <AddIcon
                                        className="add-icon-button"
                                        onClick={() => { setAddArtist({ ...addArtist, 'artist_type': "featuring" }); setOpenAddFeaturing(true) }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container>
                                {
                                    selectedFeaturingArtist.length ? selectedFeaturingArtist.map((item, key) => <Grid item xs={12} key={key}>
                                        <Box display="flex" justifyContent="space-between" p={1}>
                                            <div style={{ fontSize: "12px", display: "flex" }}>
                                                <div><p style={{ fontWeight: 600, marginRight: 5 }}>{key + 1}.</p></div>
                                                <div>
                                                    <p style={{ fontWeight: 600 }}>Artist Name: {(item && item.artist_name) || ''}</p>
                                                    <p>Apple Id: {(item && item.apple_id) || ''}</p>
                                                    <p>Spotify Id: {(item && item.spotify_id) || ''}</p>

                                                </div>
                                            </div>
                                            <div><ClearIcon onClick={() => removeSelectedPrimary("", key)} /></div>
                                        </Box>
                                    </Grid>)
                                        : null}
                            </Grid>

                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel htmlFor="Genre">Genre<span className="required-span">*</span></InputLabel>

                            <SelectCmp
                                styles={selectStyles}
                                options={staticGenre}
                                value={selectedGenre}
                                placeholder="Select Genre"
                                onChange={e => setReleaseInfo({ ...releaseInfo, genre: e.value })}
                            />

                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel htmlFor="Subgenre">Sub Genre<span className="required-span">*</span></InputLabel>
                            <CssTextField
                                id="Subgenre"
                                variant="outlined"
                                fullWidth
                                placeholder="Sub Genre"
                                size="small"
                                value={releaseInfo.sub_genre}
                                onChange={(e) => setReleaseInfo({ ...releaseInfo, sub_genre: e.target.value })}
                            />

                            {/* <SelectCmp
                                options={staticGenre}
                                placeholder="Select Sub Genre"
                                onChange={e => setReleaseInfo({ ...releaseInfo, sub_genre: e.value })}
                            /> */}

                        </Box>
                    </Grid>

                </Grid>

            </Grid>

            <Grid item xs={12} sm={12} md={4}>

                <Grid container>

                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel htmlFor="LabelName">Label Name<span className="required-span">*</span></InputLabel>
                            <CssTextField
                                id="LabelName"
                                variant="outlined"
                                fullWidth
                                placeholder="Label Name"
                                size="small"
                                value={releaseInfo.lable_name}
                                onChange={(e) => setReleaseInfo({ ...releaseInfo, lable_name: e.target.value })}
                            />

                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel htmlFor="Releasedate">Release date<span className="required-span">*</span></InputLabel>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    inputVariant="outlined"
                                    size="small"
                                    format="DD/MM/yyyy"
                                    minDate={moment().add(6, 'd').valueOf()}
                                    value={releaseInfo.release_date || null}
                                    fullWidth
                                    InputProps={{ readOnly: true }}
                                    InputAdornmentProps={{ position: "start" }}
                                    onChange={date => setReleaseInfo({ ...releaseInfo, release_date: date && date.valueOf() || null })}
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel htmlFor="ReleaseInfo"><i className="fab fa-product-hunt"></i> Line<span className="required-span">*</span></InputLabel>
                            <CssTextField
                                id="ReleaseInfo"
                                variant="outlined"
                                fullWidth
                                size="small"
                                value={releaseInfo.p_line}
                                onChange={(e) => setReleaseInfo({ ...releaseInfo, p_line: e.target.value })}
                            />

                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel htmlFor="ReleaseInfo"><i className="far fa-copyright"></i> Line<span className="required-span">*</span></InputLabel>
                            <CssTextField
                                id="ReleaseInfo"
                                variant="outlined"
                                fullWidth
                                size="small"
                                value={releaseInfo.c_line}
                                onChange={(e) => setReleaseInfo({ ...releaseInfo, c_line: e.target.value })}
                            />

                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel htmlFor="ReleaseInfo">UPC/EAN</InputLabel>
                            <CssTextField
                                id="ReleaseInfo"
                                variant="outlined"
                                fullWidth
                                size="small"
                                value={releaseInfo.upc_ean}
                                onChange={(e) => setReleaseInfo({ ...releaseInfo, upc_ean: e.target.value })}
                            />

                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box p={1} display="flex">
                            <StyledButton onClick={createReleaseInfo}><SaveIcon style={{ marginRight: "8px", fontSize: 17 }} /> {editMode ? 'Update' : 'Save'}</StyledButton>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>

            <CustomShowModal
                open={open}
                handleClose={() => setOpen(false)}
                maxWidth="sm"
                title="ARTWORK GUIDELINES"
            >
                {
                    artworkGuidlines.map((item, key) => {
                        return <div style={{ display: "flex", padding: 5 }} key={key}>
                            {key !== 0 ? <span style={{ fontWeight: 600, marginRight: 5 }}>{key}.</span> : null}
                            <p> {item}</p>
                        </div>
                    })
                }
            </CustomShowModal>
        </Grid >
    )
}

export default ReleaseInfo
