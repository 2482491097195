import {
    getVideoLinksAPI,
    getNewsAPI
} from "./api"

export function getVideoLinks(data) {
    return new Promise((resolve, reject) => {
        getVideoLinksAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function getNews(data) {
    return new Promise((resolve, reject) => {
        getNewsAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}