import React, { useEffect, useState } from 'react'
import { toastError, toastSuccess } from '../../utils'
import * as actions from "./actions"
import { Grid, Box, Paper, InputLabel, TextField, withStyles, Button } from "@material-ui/core"
import EditIcon from '@material-ui/icons/Edit';
import CustomAddModal from '../../components/Common/CustomAddModal';
import { Link } from 'react-router-dom';
import ConfirmModal from '../../components/Common/CommonModal';

const CssTextField = withStyles({
    root: {
        fontFamily: "'DM Sans', sans-serif",
        color: "#fff",
        '& label': {
            fontFamily: "'DM Sans', sans-serif"
        },
        '& label.Mui-focused': {
            color: "#000"
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'red',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
            },
            '&:hover fieldset': {
            },
            '&.Mui-focused fieldset': {
                borderColor: '#10baa8',
                borderWidth: "1px"
            },
        },
    },
})(TextField);

export default function Profile(props) {

    const [user, setUser] = useState({})
    const [open, setOpen] = useState(false)
    const [modalType, setModalType] = useState('phone')
    const [changeField, setChangeField] = useState("")
    const [password, setPassword] = useState({
        password: '',
        confirmPassword: ''
    })
    const [bankDetails, setBankDetails] = useState({
        bank_name: '',
        holder_name: '',
        account_number: '',
        ifsc_code: '',
        other_details: ''
    })
    const [showBank, setShowBank] = useState(false)
    const [addBankModal, setAddBankModal] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)


    useEffect(() => {
        getAllUserData()
        onGetUserBankDetails()
    }, [])

    const getAllUserData = () => {
        props.setLoader(true)
        actions.getUser().then(res => {
            props.setLoader(false)
            setUser(res.body)
        }).catch(err => {
            props.setLoader(false)
            toastError(err.message)
        })
    }

    const onGetUserBankDetails = () => {
        actions.getUserBankDetails().then(res => {
            setBankDetails(res.body)
        }).catch(err => {
            toastError(err.message)
        })
    }

    const setOpenModal = (type, value) => {
        if (type === 'phone' || type === 'address') {
            setChangeField(value)
        }
        setModalType(type)
        setOpen(true)
    }

    const onSubmitChange = (type) => {
        let sendObj = {}
        if (type === 'phone') {
            sendObj = {
                phone: changeField
            }
            if (changeField.toString().length < 10) {
                return toastError('Invalid phone number')
            }
        } else if (type === 'address') {
            sendObj = {
                address: changeField
            }
        } else {
            sendObj = password
            if (password.password.length < 8) {
                return toastError('Password must be 8 characters long.')
            }
            if (password.password !== password.confirmPassword) {
                return toastError("Password doesn't match")
            }

        }
        props.setLoader(true)
        actions.updateUser(sendObj).then(res => {
            props.setLoader(false)
            setOpen(false)
            toastSuccess(res.message)
            getAllUserData()
        }).catch(err => {
            props.setLoader(false)
            toastError(err.message)
        })
    }

    const onHandleClose = () => {
        setChangeField("")
        setPassword({
            password: "",
            confirmPassword: ""
        })
        setOpen(false)
    }

    const onClickSubmitBank = () => {
        props.setLoader(true)
        actions.addBankDetails(bankDetails).then(res => {
            props.setLoader(false)
            setAddBankModal(false)
            toastSuccess(res.message)
        }).catch(err => {
            props.setLoader(false)
            toastError(err.message)
        })
    }

    const requestForPayoutUser = () => {
        props.setLoader(true)
        actions.withdrawMoney().then(res => {
            props.setLoader(false)
            toastSuccess(res.message)
            setConfirmOpen(false)
        }).catch(err => {
            setConfirmOpen(false)
            props.setLoader(false)
            toastError(err.message)
        })
    }

    const { address = '', email = '', name = '', phone = '', revenue = '', user_id = '' } = user || {}

    const {
        bank_name = '',
        ifsc_code = '',
        account_number = '',
        holder_name = '',
        other_details = '',
    } = bankDetails || {}

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={7} lg={6} style={{ margin: "auto" }}>
                <Grid container>
                    <Grid item xs={12}>
                        <h2 style={{ marginBottom: 10, textAlign: "center" }}>User Details</h2>
                        <Paper>
                            <Box>

                                <Grid container className="container-item-user">
                                    <Grid item xs={12} sm={4}>
                                        <label>Client Code:</label>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <p>FR-US-{user_id}</p>
                                    </Grid>
                                </Grid>

                                <Grid container className="container-item-user">
                                    <Grid item xs={12} sm={4}>
                                        <label>Name:</label>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <p>{name}</p>
                                    </Grid>
                                </Grid>

                                <Grid container className="container-item-user">
                                    <Grid item xs={12} sm={4}>
                                        <label>Email:</label>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <p>{email}</p>
                                    </Grid>
                                </Grid>

                                <Grid container className="container-item-user">
                                    <Grid item xs={12} sm={4}>
                                        <label>Phone:</label>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <p>{phone}<EditIcon onClick={() => setOpenModal('phone', phone)} /></p>
                                    </Grid>
                                </Grid>

                                <Grid container className="container-item-user">
                                    <Grid item xs={12} sm={4}>
                                        <label>Address:</label>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <p>{address}<EditIcon onClick={() => setOpenModal('address', address)} /></p>
                                    </Grid>
                                </Grid>

                                <Grid container className="container-item-user">
                                    <Grid item xs={12} sm={4}>
                                        <label>Password:</label>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <p>*******<EditIcon onClick={() => setOpenModal('password')} /></p>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <p style={{ float: "right", padding: "10px" }}>To change your name or email please create a <Link to="/tickets">ticket</Link>.</p>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Grid>

                    {showBank ? <Grid item xs={12}>
                        <Paper>
                            <Box mt={3} position="relative">
                                <span style={{ position: "absolute", right: '25px', top: '25px' }}><EditIcon onClick={() => setAddBankModal(true)} /></span>
                                <Grid container className="container-item-user">
                                    <Grid item xs={12} sm={4}>
                                        <label>Bank Name:</label>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <p>{bank_name}</p>
                                    </Grid>
                                </Grid>

                                <Grid container className="container-item-user">
                                    <Grid item xs={12} sm={4}>
                                        <label>IFSC Code:</label>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <p>{ifsc_code}</p>
                                    </Grid>
                                </Grid>

                                <Grid container className="container-item-user">
                                    <Grid item xs={12} sm={4}>
                                        <label>Account Number:</label>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <p>{account_number}</p>
                                    </Grid>
                                </Grid>

                                <Grid container className="container-item-user">
                                    <Grid item xs={12} sm={4}>
                                        <label>Holder Name:</label>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <p>{holder_name}</p>
                                    </Grid>
                                </Grid>

                                <Grid container className="container-item-user">
                                    <Grid item xs={12} sm={4}>
                                        <label>Other Details:</label>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <p>{other_details}</p>
                                    </Grid>
                                </Grid>

                                <Grid container style={{ marginBottom: 10 }}>
                                    <Grid item xs={12}>
                                        <Box>
                                            <p className="request-payout-p-tag" onClick={() => setConfirmOpen(true)}>$ Request Payout</p>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Grid> : <Grid item xs={12} >
                        <Box mt={3} textAlign="center">
                            <Button className="request-payout-Button" onClick={() => {
                                if (Number(revenue) < 99) {
                                    return toastError('Minimum withdrawal amount is $100')
                                } else {
                                    props.setLoader(true)
                                    setTimeout(() => {
                                        props.setLoader(false)
                                        setShowBank(true)

                                    }, 1000)
                                }
                            }}>Add/Update Bank</Button>
                            <p style={{ color: "grey", marginTop: 10 }}>Note: Minimum balance should be $100 for withdrawal.</p>
                        </Box>
                    </Grid>}
                </Grid>

            </Grid>

            <CustomAddModal
                open={open}
                title="Update"
                animate
                handleClose={onHandleClose}
                onSubmitConfirm={() => onSubmitChange(`${modalType === 'phone' ? 'phone' : modalType === 'address' ? 'address' : 'password'}`)}
                saveLabel="Update"
            >
                {modalType !== 'password' ? <Grid container className="add-song-details-container">
                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel>{modalType === 'phone' ? 'Edit Phone Number' : 'Edit Address'}</InputLabel>
                            {modalType === 'phone' ? <CssTextField
                                type="number"
                                placeholder="Edit Phone Number"
                                variant="outlined"
                                size="small"
                                value={changeField}
                                onChange={(e) => {
                                    if ((e.target.value).toString().length > 10) {
                                        return
                                    }
                                    setChangeField(e.target.value)
                                }}
                                fullWidth
                            />
                                :
                                <textarea
                                    style={{ height: 150 }}
                                    className="textarea-input-field"
                                    placeholder="Edit Address"
                                    value={changeField}
                                    onChange={(e) => setChangeField(e.target.value)}
                                />
                            }
                        </Box>
                    </Grid>
                </Grid> : <Grid container className="add-song-details-container">
                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel>Enter New Password</InputLabel>
                            <CssTextField
                                type="password"
                                placeholder="Enter New Password"
                                variant="outlined"
                                size="small"
                                value={password.password}
                                onChange={(e) => setPassword({ ...password, password: e.target.value })}
                                fullWidth
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel>Confirm New Password</InputLabel>
                            <CssTextField
                                type="password"
                                placeholder="Confirm New Password"
                                variant="outlined"
                                size="small"
                                value={password.confirmPassword}
                                onChange={(e) => setPassword({ ...password, confirmPassword: e.target.value })}
                                fullWidth
                            />
                        </Box>
                    </Grid>
                </Grid>
                }

            </CustomAddModal>

            <CustomAddModal
                open={addBankModal}
                title="Add Bank Details"
                handleClose={() => setAddBankModal(false)}
                onSubmitConfirm={onClickSubmitBank}
                saveLabel="Save"
            >
                <Grid container className="bank-details-modal">
                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel>Bank Name</InputLabel>
                            <CssTextField
                                placeholder="Bank Name"
                                variant="outlined"
                                size="small"
                                value={bankDetails.bank_name}
                                onChange={(e) => setBankDetails({ ...bankDetails, bank_name: e.target.value })}
                                fullWidth
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel>Holder Name</InputLabel>
                            <CssTextField
                                placeholder="Holder Name"
                                variant="outlined"
                                size="small"
                                value={bankDetails.holder_name}
                                onChange={(e) => setBankDetails({ ...bankDetails, holder_name: e.target.value })}
                                fullWidth
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel>Account Number</InputLabel>
                            <CssTextField
                                placeholder="Account Number"
                                variant="outlined"
                                size="small"
                                value={bankDetails.account_number}
                                onChange={(e) => setBankDetails({ ...bankDetails, account_number: e.target.value })}
                                fullWidth
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel>IFSC Code</InputLabel>
                            <CssTextField
                                placeholder="IFSC Code"
                                variant="outlined"
                                size="small"
                                value={bankDetails.ifsc_code}
                                onChange={(e) => setBankDetails({ ...bankDetails, ifsc_code: e.target.value })}
                                fullWidth
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel>Other Details</InputLabel>
                            <CssTextField
                                placeholder="Other Details"
                                variant="outlined"
                                size="small"
                                value={bankDetails.other_details}
                                onChange={(e) => setBankDetails({ ...bankDetails, other_details: e.target.value })}
                                fullWidth
                            />
                        </Box>
                    </Grid>
                </Grid>
            </CustomAddModal>
            <ConfirmModal
                message={`Are you sure you want to request for payout?`}
                open={confirmOpen}
                title={`Confrimation`}
                onSubmitConfirm={requestForPayoutUser}
                handleClose={() => setConfirmOpen(false)}
            >

            </ConfirmModal>
        </Grid >
    )
}
