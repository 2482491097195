import React, { useState, useEffect } from 'react'
import { Grid, Box, Button, withStyles, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
import SelectCmp from "react-select"
import { reportStores } from '../../constants';
import moment from "moment"
import * as commonActions from "../../containers/common/actions"
import { toastError, toastSuccess } from '../../utils';

const StyledButton = withStyles({
    root: {
        fontFamily: "'DM Sans', sans-serif",
        background: '#2d2d2d',
        fontSize: 14,
        height: 35,
        width: 150,
        borderColor: "#0597b2",
        margin: "auto",
        color: "white",
        '&:hover': {
            color: "black",
            border: '1px solid black'
        }
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

export default function Reports(props) {
    const [reportDate, setReportDate] = useState({
        start_date: null,
        end_date: null,
        store: ''
    })
    const [reports, setReports] = useState([])

    useEffect(() => {
        onGetReportList()
    }, [])

    const onGetReportList = () => {
        props.setLoader(true)
        commonActions.requestReportList().then(res => {
            props.setLoader(false)
            setReports(res.body)
        }).catch(err => {
            props.setLoader(false)
        })
    }

    const setReportDates = (key, Date) => {
        setReportDate({ ...reportDate, [key]: Date.valueOf() })
    }

    const onSubmitReport = () => {
        if (!reportDate.store) {
            return toastError('Please select store')
        }
        if (!reportDate.start_date) {
            return toastError('Please select Start month')
        }
        if (!reportDate.end_date) {
            return toastError('Please select End month')
        }
        props.setLoader(true)
        commonActions.requestReport(reportDate).then(res => {
            props.setLoader(false)
            toastSuccess(res.message)
            onGetReportList()
        }).catch(err => {
            props.setLoader(false)
            toastError(err.message)
        })
    }

    return (
        <Grid container className="report-container">
            <Grid item xs={12}>
                <Box mb={2}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={8} lg={6} style={{ margin: "auto" }}>
                            <h2 style={{ textAlign: "center" }}>Select Stores:</h2>
                            <SelectCmp
                                options={reportStores}
                                onChange={(e) => setReportDate({ ...reportDate, store: e.value })}
                            />
                            <p style={{ color: "red", textAlign: "center", fontSize: 13, fontWeight: 600 }}>Please make sure that streaming services usually send these reports on a 3-4 month delay.</p>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Box p={2}>
                    <p className="start-heading">Start Month:</p>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            autoOk
                            variant="static"
                            orientation="landscape"
                            inputVariant="outlined"
                            size="small"
                            format="DD/MM/yyyy"
                            views={["year", "month"]}
                            style={{ width: "fit-content" }}
                            InputAdornmentProps={{ position: "start" }}
                            value={reportDate.start_date}
                            onChange={(Date) => setReportDates('start_date', Date)}
                        />
                    </MuiPickersUtilsProvider>
                    <p className="selectedDate">Selected Month: {reportDate.start_date ? <span>{moment(reportDate.start_date).format("MMM/yyyy")}</span> : "-"}</p>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Box p={2}>
                    <p className="start-heading">End Month:</p>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            autoOk
                            variant="static"
                            orientation="landscape"
                            inputVariant="outlined"
                            size="small"
                            format="DD/MM/yyyy"
                            views={["year", "month"]}
                            InputAdornmentProps={{ position: "start" }}
                            value={reportDate.end_date}
                            onChange={(Date) => setReportDates('end_date', Date)}
                        />
                    </MuiPickersUtilsProvider>
                    <p className="selectedDate">Selected Month: {reportDate.end_date ? <span>{moment(reportDate.end_date).format("MMM/yyyy")}</span> : "-"}</p>
                </Box>
            </Grid>
            <Grid item xs={12} >
                <Box mt={1} mb={3} textAlign="center">
                    <StyledButton onClick={onSubmitReport}>Request Report</StyledButton>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <h2 style={{ marginBottom: 10 }}>Previously Requested</h2>
                <TableContainer component={Paper}>
                    <Table className="universal-table">
                        <TableHead>
                            <TableRow>
                                <TableCell>No.</TableCell>
                                <TableCell>Report For</TableCell>
                                <TableCell>Start Date</TableCell>
                                <TableCell>End Date</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                Array.isArray(reports) && reports.length ? reports.map((item, key) => {
                                    return <TableRow key={key}>
                                        <TableCell>{key + 1}</TableCell>
                                        <TableCell>{item.store}</TableCell>
                                        <TableCell>{moment(Number(item.start_date)).format("MMM/yyyy")}</TableCell>
                                        <TableCell>{moment(Number(item.end_date)).format("MMM/yyyy")}</TableCell>
                                        <TableCell>{item.status === 0 ? <span className="pending-report">PENDING</span> : <span className="received-report">RECEIVED</span>}</TableCell>
                                    </TableRow>

                                }) : <TableRow><TableCell colSpan='5' align="center">No Report Found.</TableCell></TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}
