import { apiGet, apiPost } from "../../../utils";

export function getLogosAPI() {
  return apiGet("/api/getLogos");
}

export function submitVideoReleaseAPI(data) {
  return apiPost(`/api/submitVideoRelease`, data);
}

export function checkAwayStatusAPI() {
  return apiGet(`/api/checkAwayStatus`);
}

export function getReleaseTitlesForVReleaseAPI() {
  return apiGet("/api/getReleaseTitlesForVRelease");
}

export function getAudioTitlesByReleaseIdAPI(release_id = "") {
  return apiGet(`/api/getAudioTitles?release_id=${release_id}`);
}
