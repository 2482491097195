import {
    getVReleaseHistoryAPI,
} from "./api"

export function getVReleaseHistory(data) {
    return new Promise((resolve, reject) => {
        getVReleaseHistoryAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

// export function getCatelogById(id) {
//     return new Promise((resolve, reject) => {
//         getCatelogByIdAPI(id).then((res) => resolve(res)).catch(err => reject(err))
//     })
// }