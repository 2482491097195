import React, { useEffect, useState } from 'react'
import { Grid, Box, TextField, Button, CircularProgress, InputAdornment, IconButton } from "@material-ui/core"
import logo from "../../../assets/img/Fronic_Logo_white.png"
import { useHistory } from "react-router-dom"
import { withStyles, makeStyles, fade } from '@material-ui/core/styles';
import { getObject, removeObject, saveObject, toastError, toastSuccess } from '../../../utils';
import * as actions from "../actions"
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const StyledButton = withStyles({
    root: {
        fontFamily: "'DM Sans', sans-serif",
        background: 'linear-gradient(90deg, rgba(19,153,121,1) 0%, rgba(18,180,161,1) 45%, rgba(5,150,179,1) 100%)',
        fontSize: 18,
        height: 50
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

const CssTextField = withStyles({
    root: {
        // background: "transparent",
        fontFamily: "'DM Sans', sans-serif",
        color: "#fff",
        '& label': {
            fontFamily: "'DM Sans', sans-serif",
            // color: '#10baa8',
            color: "white"
        },
        '& label.Mui-focused': {
            // color: '#10baa8',
            color: "white"
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'red',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#10baa8',
            },
        },
    },
})(TextField);

const useStylesReddit = makeStyles((theme) => ({
    root: {
        border: '1px solid #e2e2e1',
        overflow: 'hidden',
        borderRadius: 4,
        backgroundColor: '#fcfcfb',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:hover': {
            backgroundColor: '#fff',
        },
        '&$focused': {
            backgroundColor: '#fff',
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
    },
    focused: {},
}));

export default function ResetPassword(props) {
    const { setShowReset } = props;
    const classes = useStylesReddit();
    const history = useHistory();
    const [password, setPassword] = useState({
        newPassword: "",
        confirmPassword: ""
    })
    const [token, setToken] = useState("")
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState({
        first: false,
        second: false
    })

    const onResetPassword = () => {
        if (!password.newPassword || !password.confirmPassword) {
            return toastError('Please enter password')
        }
        if (password.newPassword.length < 5) {

            return toastError('Password must be of 6 characters')
        }
        if (password.newPassword !== password.confirmPassword) {
            return toastError('Please must be same in both fields')
        }
        setLoading(true)
        actions.ChangeUserPassword({ password: password.newPassword }, { Authorization: getObject('tempToken') }).then(res => {
            setLoading(false)
            toastSuccess(res.message)
            removeObject('tempToken')
            saveObject('auth', getObject('tempToken'))
            return history.push("/")
        }).catch(err => {
            setLoading(false)
            toastError(err.message)
        })
    }

    return (
        <div className="login-div">
            <Grid container>
                <Grid item xs={12}>
                    <div className="logo-image-div">
                        <img src={logo} alt="" />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <Box pt={6} pb={3}>
                        <CssTextField
                            className={classes.margin}
                            type={showPassword.first ? "text" : "password"}
                            label="New Password"
                            variant="outlined"
                            id="custom-css-outlined-input"
                            value={password.newPassword}
                            onChange={(e) => setPassword({ ...password, newPassword: e.target.value })}
                            fullWidth
                            autoFocus
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        style={{ color: "white" }}
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword({ ...showPassword, first: !showPassword.first })}
                                        onMouseDown={(e) => e.preventDefault()}
                                    >
                                        {showPassword.first ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box pt={0.8} pb={5}>
                        <CssTextField
                            className={classes.margin}
                            label="Confirm Password"
                            type={showPassword.second ? "text" : "password"}
                            variant="outlined"
                            id="custom-css-outlined-input"
                            value={password.confirmPassword}
                            onChange={(e) => setPassword({ ...password, confirmPassword: e.target.value })}
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        style={{ color: "white" }}
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword({ ...showPassword, second: !showPassword.second })}
                                        onMouseDown={(e) => e.preventDefault()}
                                    >
                                        {showPassword.second ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box pt={0.5}>
                        <StyledButton
                            variant="contained"
                            color="primary"
                            onClick={onResetPassword}
                            fullWidth
                        >
                            {loading ? <CircularProgress size={25} style={{ color: "white" }} /> : "Change Password"}
                        </StyledButton>
                    </Box>

                </Grid>
            </Grid>
        </div>
    )
}