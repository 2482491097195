import { apiGet, apiPost } from "../../../utils"

export function getArtistAPI(type) {
    return apiGet(`/api/getArtist?artist_type=${type}`)
}

export function addArtistAPI(data) {
    return apiPost('/api/addArtist', data)
}

export function createReleaseInfoAPI(data) {
    return apiPost('/api/createReleaseInfo', data)
}

export function addSongInfoAPI(data) {
    return apiPost('/api/addSongInfo', data)
}

export function getLogosAPI() {
    return apiGet('/api/getLogos')
}

export function getSubmissionDetailsAPI(id) {
    return apiGet(`/api/getSubmissionDetails?release_id=${id}`)
}

export function checkboxCrbtVideoPlatformsAPI(data) {
    return apiPost(`/api/checkboxCrbtVideoPlatforms`, data)
}

export function getDraftAPI(id) {
    return apiGet(`/api/getDraft?release_id=${id}`)
}

export function updateReleaseInfoAPI(data) {
    return apiPost(`/api/updateReleaseInfo`, data)
}

export function updateSongInfoAPI(data) {
    return apiPost(`/api/updateSongInfo`, data)
}

export function submissionButtonAPI(data) {
    return apiPost(`/api/submissionButton`, data)
}

export function deleteDraftAPI(data) {
    return apiPost(`/api/deleteDraft`, data)
}

export function checkUserRevokeStatusAPI(data) {
    return apiGet(`/api/checkUserRevokeStatus`, data)
}

export function getSubmissionDataChecksAPI(data) {
    return apiGet(`/api/getSubmissionDataChecks?release_id=${data}`)
}

export function checkAwayStatusAPI() {
    return apiGet(`/api/checkAwayStatus`)
}
