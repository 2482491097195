import { apiGet, apiPost } from "../../utils"

export function getTicketListForUserAPI() {
    return apiGet('/api/getTicketListForUser')
}

export function createTicketAPI(data) {
    return apiPost('/api/createTicket', data)
}

export function getTicketByIdAPI(ticket_id) {
    return apiGet(`/api/getTicketById?ticket_id=${ticket_id}`)
}

export function replyTicketAPI(data) {
    return apiPost(`/api/replyTicket`, data)
}