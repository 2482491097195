import React from 'react'
import YouTube from 'react-youtube';

function YoutubePlayer(props) {

    const opts = {
        height: "100%",
        width: "100%",
        playerVars: {
            autoplay: 0,
        },
    };


    const _onReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
    const { videoId } = props
    return (
        <YouTube videoId={videoId} opts={opts} onReady={_onReady} />
    )
}

export default YoutubePlayer
