import React, { useState, useEffect } from "react";
import CreateVideoRelease from "../../../components/Releases/VideoReleases/CreateVideoRelease";
import * as actionRelease from "../../../containers/Releases/VideoReleases/actions";
import * as commonActions from "../../common/actions";
import * as actions from "../CreateReleases/actions";
import { toastError, toastSuccess } from "../../../utils";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import noaccess from "../../../assets/img/noaccess.png";
import { CircularProgress } from "@material-ui/core";


function CreateVideoReleases(props) {
  const [recipeFilePreview, setRecipeFilePreview] = useState("");
  const [vReleaseData, setVReleaseData] = useState([]); // deep clone
  const [selectedSong, setselectedSong] = useState(null);
  const [selectedRelease, setSelectedRelease] = useState(null);
  const [checkTerm, setCheckTerm] = useState(false);
  const [awayData, setAwayData] = useState({
    status: false,
    text: "",
  });
  const [isUserRevoked, setIsUserRevoked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  const onSubmitVRelease = async () => {
    if (!checkTerm) {
      return toastError("Please accept T & C.");
    }
    if (
      !selectedSong ||
      !selectedRelease ||
      !vReleaseData.download_link ||
      !vReleaseData.thumbnail ||
      !vReleaseData.release_date
    ) {
      return toastError("Please fill in all the fields before submit");
    }
    props.setLoader(true);

    actionRelease
      .submitVideoRelease({
        ...vReleaseData,
        release_id: selectedRelease.release_id,
        release_title: selectedRelease.release_title,
        audio_title: selectedSong.title,
      })
      .then((res) => {
        props.setLoader(false);
        toastSuccess("Release submitted for review");
        return history.push("video-catalog");

        // /video-catalog
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      })
      .catch((err) => {
        props.setLoader(false);
        toastError(err.message);
      });
  };

  const onFileHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1572864) {
        return toastError("File more than 1.5 MB is not allowed.");
      }
      props.setLoader(true);
      commonActions
        .videoUpload(file)
        .then((res) => {
          props.setLoader(false);
          setVReleaseData({
            ...vReleaseData,
            thumbnail: `https://backend.fronicmedia.com/${res.body.filename}`,
          });
          previewsFile(file);
          toastSuccess("Thumbnail uploaded successfully.");
        })
        .catch((err) => {
          props.setLoader(false);
          toastError(err.message || "Something went wrong");
        });
    }
  };

  const previewsFile = (file) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        //convert
        setRecipeFilePreview(reader.result);
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    props.setLoader(true);
    actions.checkAwayStatus().then((res) => {
      const isAway =
        (res &&
          res.body &&
          res.body.status &&
          res.body.status === "yes" &&
          true) ||
        false;
      const awayText =
        (res && res.body && res.body.text && res.body.text) || "";
      if (isAway) {
        setAwayData({
          status: true,
          text: awayText,
        });
        props.setLoader(false);
        setIsLoading(false);
      } else {
        actions
          .checkUserRevokeStatus()
          .then((res) => {
            if (res.revoked === 1) {
              setIsUserRevoked(true);
            }
          })
          .catch((err) => {
            toastError(err.message);
          })
          .finally(() => {
            props.setLoader(false);
            setIsLoading(false);
          });
      }
    });
  }, []);

  return (
    <>
      {awayData.status && !isLoading && (
        <Grid container style={{ height: "calc(90vh - 64px)" }}>
          <Grid item xs={12} sm={10} md={7} lg={6} style={{ margin: "auto" }}>
            <div className="server-maintenance">
              <p>{awayData.text}</p>
            </div>
          </Grid>
        </Grid>
      )}
      {isUserRevoked && !isLoading && (
        <Grid container style={{ height: "calc(90vh - 64px)" }}>
          <Grid item xs={12} sm={10} md={7} lg={5} style={{ margin: "auto" }}>
            <div className="revoked-user-container">
              <div style={{ width: "100%", display: "flex" }}>
                <img
                  height="200px"
                  src={noaccess}
                  alt=""
                  style={{ margin: "auto" }}
                />
              </div>
              <p style={{ fontSize: 20, fontWeight: 500, textAlign: "center" }}>
                You cannot create a release due to the violation of Fronic Media
                distribution terms. To get back the access, please contact
                support@fronicmedia.com.
              </p>
              <p style={{ float: "right", marginTop: 15 }}>
                -Team Fronic media.
              </p>
            </div>
          </Grid>
        </Grid>
      )}
      {!awayData.status && !isUserRevoked && !isLoading && (
        <Grid container className="create-release-container">
          <Grid item xs={12}>
            <CreateVideoRelease
              recipeFilePreview={recipeFilePreview}
              onFileHandler={onFileHandler}
              setVReleaseData={setVReleaseData}
              vReleaseData={vReleaseData}
              selectedSong={selectedSong}
              setselectedSong={setselectedSong}
              selectedRelease={selectedRelease}
              setSelectedRelease={setSelectedRelease}
              checkTerm={checkTerm}
              setCheckTerm={setCheckTerm}
              onSubmitVRelease={onSubmitVRelease}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default CreateVideoReleases;
