import img1 from "./assets/img/login-bg/bg1.jpg";
import img3 from "./assets/img/login-bg/bg3.jpg";
import img4 from "./assets/img/login-bg/bg4.jpg";
import img6 from "./assets/img/login-bg/bg6.jpg";
import img8 from "./assets/img/login-bg/bg8.jpg";
import img10 from "./assets/img/login-bg/bg10.jpg";
import img12 from "./assets/img/login-bg/bg12.jpg";
import img14 from "./assets/img/login-bg/bg14.jpg";

export const API_URL = "https://backend.fronicmedia.com";

export const spotify_base_url = "https://open.spotify.com/search/";
export const tidal_base_url = "https://listen.tidal.com/search?q=";
export const wynk_base_url = "https://wynk.in/music/detailsearch/";
export const apple_base_url = "https://music.apple.com/in/search?term=";
export const jiosaavn_base_url = "https://www.jiosaavn.com/search/";
export const gaana_base_url = "https://gaana.com/search/";
export const amazon_base_url = "https://music.amazon.in/search/";
export const youtube_base_url = "https://www.youtube.com/results?search_query=";
export const kkbox_base_url = "https://www.kkbox.com/tw/en/search.php?word=";
export const yandex_base_url = "https://music.yandex.ru/search?text=";

export const fronicMedia_FAQ = "https://fronicmedia.com/knowledge-base";
export const webSeriesDestribution =
  "https://fronicmedia.com/web-series-distribution";

export const loginBGimages = [
  img1,
  img3,
  img4,
  img6,
  img8,
  img10,
  img12,
  img14,
];

export const artworkGuidlines = [
  "Your release cover art should be in the following specification. This is a requirement by the many partners we work with.",
  "A minimum size of 3000 x 3000 pixels (a perfect square)",
  "A minimum resolution of 72dpi (we recommend 300dpi)  ",
  "RGB color mode (CMYK will not show up correctly)",
  "JPEG file format",
  "Do not send us thumbnails, .png files or images smaller than the requested size.",
];

export const guidelinesTools = [
  "Please read them carefully and reach out to us if you have any queries.",
  "Remember to pitch your releases via Spotify for artists,  Amazon music for artists etc at least 10 days before the release!",
  "Further, we can't guarantee a place on an editorial playlist when we pitch music. It's at the discretion of the Editorial team when they select tracks.",
  "Don’t forget - released music can still be discovered and added to playlists! The editors are always looking for the hottest and freshest talent. You can make sure you stay on their radar by building your fanbase and engaging with your audience on social media.",
];

export const guidelinesToolsLyrics = [
  "Lyric takedowns - Lyric takedowns are handled differently than the take down of a product. The lyric provider will not remove the lyrics from its system. However, by taking down a product, consequently the listeners will not be able to play the song on DSPs any longer, and thus not see the lyrics either.",
  "General Formatting - The structure of a song should dictate a lyric’s format and structure. When transcribing lyrics, it is important to separate the lyrical sections and changes within a song with line and section breaks. All lyric lines should be single-spaced and a double space should separate each stanza. If you are unsure when or where to input a line or section break, some common identifiers areas may be like ,A defined chorus, verse, intro, bridge, or hook. Changes in the song tempo· Changes in how the artist delivers the lyrics (from singing to rapping)",
  "Capitalization - Capitalization in lyrics should follow traditional grammar rules. Proper nouns must be capitalized. In addition, all lyric lines must begin with a capital letter.",
  "Punctuation- There should be no periods or commas at the end of any lyric line. Exclamation points, question marks, and quotation marks should be the only end-line punctuation and should be included as needed. Within the line, it is most important to follow traditional grammar rules.",
  "Repeated lines- The lyrics should exactly match the audio. If a line is repeated, it should be written out as often as repeated. Do not use (X2), (X3) for repeating lines, you need to write them again.",
];

export const ticketFilterOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Pending",
    value: 0,
  },
  {
    label: "Done",
    value: 1,
  },
];

export const releaseFilters = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "In-Review",
    value: "in_review",
  },
  {
    label: "Draft",
    value: "draft",
  },

  {
    label: "Approved",
    value: "approved",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
  {
    label: "Takendown",
    value: "takendown",
  },
];

export const releaseFiltersForVideo = [
  {
    label: "All",
    value: "",
  },
  {
    label: "In-Review",
    value: "in_review",
  },
  {
    label: "Approved",
    value: "approved",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
  {
    label: "Takendown",
    value: "takendown",
  },
];

export const releaseType = [
  {
    label: "EP",
  },
  {
    label: "Single",
  },
  {
    label: "Album",
  },
  {
    label: "Compilation",
  },
];

export const reportStores = [
  {
    label: "All Stores",
    value: "All Stores",
  },
  {
    label: "Amazon Music",
    value: "Amazon Music",
  },
  {
    label: "Apple Music",
    value: "Apple Music",
  },
  {
    label: "Beatport",
    value: "Beatport",
  },
  {
    label: "Caller Tune Reports",
    value: "Caller Tune Reports",
  },
  {
    label: "Dailymotion",
    value: "Dailymotion",
  },
  {
    label: "Gaana",
    value: "Gaana",
  },
  {
    label: "Hungama",
    value: "Hungama",
  },
  {
    label: "Instagram / Facebook",
    value: "Instagram / Facebook",
  },
  {
    label: "JIOSaavn",
    value: "JIOSaavn",
  },
  {
    label: "MX Player",
    value: "MX Player",
  },
  {
    label: "Shazam",
    value: "Shazam",
  },
  {
    label: "Spotify",
    value: "Spotify",
  },
  {
    label: "Tidal",
    value: "Tidal",
  },
  {
    label: "Wynk",
    value: "Wynk",
  },
  {
    label: "YouTube Music",
    value: "YouTube Music",
  },
];

export const reasonList = [
  {
    label: "Artist Digital Presence",
    value: "Artist Digital Presence",
  },
  {
    label: "Change in Release",
    value: "Change in Release",
  },
  {
    label: "Callertune Codes ",
    value: "Callertune Codes ",
  },
  {
    label: "Delivery Status",
    value: "Delivery Status",
  },
  {
    label: "Takedown Request",
    value: "Takedown Request",
  },
  {
    label: "Others",
    value: "Others",
  },
];

export const staticGenre = [
  {
    label: "African",
    value: "African",
  },
  {
    label: "Alternative",
    value: "Alternative",
  },
  {
    label: "Alternative Rock",
    value: "Alternative Rock",
  },
  {
    label: "Arabic",
    value: "Arabic",
  },
  {
    label: "Asian",
    value: "Asian",
  },
  {
    label: "Audiobook",
    value: "Audiobook",
  },
  {
    label: "Blues",
    value: "Blues",
  },
  {
    label: "Brazilian",
    value: "Brazilian",
  },
  {
    label: "Children",
    value: "Children",
  },
  {
    label: "Christian & Gospel",
    value: "Christian & Gospel",
  },
  {
    label: "Classical",
    value: "Classical",
  },
  {
    label: "Comedy",
    value: "Comedy",
  },
  {
    label: "Country",
    value: "Country",
  },
  {
    label: "Dance",
    value: "Dance",
  },
  {
    label: "Devotional",
    value: "Devotional",
  },
  {
    label: "Educational",
    value: "Educational",
  },
  {
    label: "Electronic",
    value: "Electronic",
  },
  {
    label: "Experimental",
    value: "Experimental",
  },
  {
    label: "Folk",
    value: "Folk",
  },
  {
    label: "Hip Hop / Rap",
    value: "Hip Hop / Rap",
  },
  {
    label: "Holiday",
    value: "Holiday",
  },
  {
    label: "Indian",
    value: "Indian",
  },
  {
    label: "Indie",
    value: "Indie",
  },
  {
    label: "Indo Pop",
    value: "Indo Pop",
  },
  {
    label: "Inspirational",
    value: "Inspirational",
  },
  {
    label: "Instrumental",
    value: "Instrumental",
  },
  {
    label: "Jazz",
    value: "Jazz",
  },
  {
    label: "Latin",
    value: "Latin",
  },
  {
    label: "Metal",
    value: "Metal",
  },
  {
    label: "New Age",
    value: "New Age",
  },
  {
    label: "Pop",
    value: "Pop",
  },
  {
    label: "Punk",
    value: "Punk",
  },
  {
    label: "R & B",
    value: "R & B",
  },
  {
    label: "Reggae",
    value: "Reggae",
  },
  {
    label: "Rock",
    value: "Rock",
  },
  {
    label: "Soul",
    value: "Soul",
  },
  {
    label: "World",
    value: "World",
  },
];

export const trackTitleLanguage = [
  {
    label: "Abhkhazian",
    value: "Abhkhazian",
  },
  {
    label: "Ablbanian",
    value: "Ablbanian",
  },
  {
    label: "Afar",
    value: "Afar",
  },
  {
    label: "Afrikaans",
    value: "Afrikaans",
  },
  {
    label: "Amharic",
    value: "Amharic",
  },
  {
    label: "Arabic",
    value: "Arabic",
  },
  {
    label: "Armenian",
    value: "Armenian",
  },
  {
    label: "Assamese",
    value: "Assamese",
  },
  {
    label: "Aymara",
    value: "Aymara",
  },
  {
    label: "Azerbaijani",
    value: "Azerbaijani",
  },
  {
    label: "Bambara",
    value: "Bambara",
  },
  {
    label: "Bangla",
    value: "Bangla",
  },
  {
    label: "Bashkir",
    value: "Bashkir",
  },
  {
    label: "Basque",
    value: "Basque",
  },
  {
    label: "Bhojpuri",
    value: "Bhojpuri",
  },
  {
    label: "Bhutani",
    value: "Bhutani",
  },
  {
    label: "Bihari",
    value: "Bihari",
  },
  {
    label: "Bislama",
    value: "Bislama",
  },
  {
    label: "Bodo",
    value: "Bodo",
  },
  {
    label: "Breton",
    value: "Breton",
  },
  {
    label: "Bulgarian",
    value: "Bulgarian",
  },
  {
    label: "Burmese",
    value: "Burmese",
  },
  {
    label: "Byelorussian",
    value: "Byelorussian",
  },
  {
    label: "Cambodian",
    value: "Cambodian",
  },
  {
    label: "Cantonese",
    value: "Cantonese",
  },
  {
    label: "Catalan",
    value: "Catalan",
  },
  {
    label: "Chakma",
    value: "Chakma",
  },
  {
    label: "Chhattisgarhi",
    value: "Chhattisgarhi",
  },
  {
    label: "Chinese",
    value: "Chinese",
  },
  {
    label: "Corsican",
    value: "Corsican",
  },
  {
    label: "Croation",
    value: "Croation",
  },
  {
    label: "Czech",
    value: "Czech",
  },
  {
    label: "Danish",
    value: "Danish",
  },
  {
    label: "Dogri",
    value: "Dogri",
  },
  {
    label: "Dutch",
    value: "Dutch",
  },
  {
    label: "English",
    value: "English",
  },
  {
    label: "Esperanto",
    value: "Esperanto",
  },
  {
    label: "Estonian",
    value: "Estonian",
  },
  {
    label: "Faroese",
    value: "Faroese",
  },
  {
    label: "iji",
    value: "iji",
  },
  {
    label: "Finnish",
    value: "Finnish",
  },
  {
    label: "French",
    value: "French",
  },
  {
    label: "Frisian",
    value: "Frisian",
  },
  {
    label: "Galician",
    value: "Galician",
  },
  {
    label: "Garhwali",
    value: "Garhwali",
  },
  {
    label: "Georgian",
    value: "Georgian",
  },
  {
    label: "German",
    value: "German",
  },
  {
    label: "Gora",
    value: "Gora",
  },
  {
    label: "Greek",
    value: "Greek",
  },
  {
    label: "Greenlandic",
    value: "Greenlandic",
  },
  {
    label: "Guarani",
    value: "Guarani",
  },
  {
    label: "Gujarati",
    value: "Gujarati",
  },
  {
    label: "Haitian",
    value: "Haitian",
  },
  {
    label: "Haryanvi",
    value: "Haryanvi",
  },
  {
    label: "Hausa",
    value: "Hausa",
  },
  {
    label: "Hebrew",
    value: "Hebrew",
  },
  {
    label: "Hindi",
    value: "Hindi",
  },
  {
    label: "Hungarian",
    value: "Hungarian",
  },
  {
    label: "Himachali",
    value: "Himachali",
  },
  {
    label: "Icelandic",
    value: "Icelandic",
  },
  {
    label: "Indonesian",
    value: "Indonesian",
  },
  {
    label: "Interlingua",
    value: "Interlingua",
  },
  {
    label: "Inuktitut",
    value: "Inuktitut",
  },
  {
    label: "Inupiak",
    value: "Inupiak",
  },
  {
    label: "Irish",
    value: "Irish",
  },
  {
    label: "Italian",
    value: "Italian",
  },
  {
    label: "Japanese",
    value: "Japanese",
  },
  {
    label: "Javanese",
    value: "Javanese",
  },
  {
    label: "Kannada",
    value: "Kannada",
  },
  {
    label: "Kashmiri",
    value: "Kashmiri",
  },
  {
    label: "Kazakh",
    value: "Kazakh",
  },
  {
    label: "Khasi",
    value: "Khasi",
  },
  {
    label: "Kinyarwanda",
    value: "Kinyarwanda",
  },
  {
    label: "Kirghiz",
    value: "Kirghiz",
  },
  {
    label: "Kirundi",
    value: "Kirundi",
  },
  {
    label: "Kok Borok",
    value: "Kok Borok",
  },
  {
    label: "Konkani",
    value: "Konkani",
  },
  {
    label: "Korean",
    value: "Korean",
  },
  {
    label: "Kumanoi",
    value: "Kumanoi",
  },
  {
    label: "Kurdish",
    value: "Kurdish",
  },
  {
    label: "Kutchi",
    value: "Kutchi",
  },
  {
    label: "Laothian",
    value: "Laothian",
  },
  {
    label: "Latin",
    value: "Latin",
  },
  {
    label: "Latvian",
    value: "Latvian",
  },
  {
    label: "Lingala",
    value: "Lingala",
  },
  {
    label: "Lithuanian",
    value: "Lithuanian",
  },
  {
    label: "Macedonian",
    value: "Macedonian",
  },
  {
    label: "Maithili",
    value: "Maithili",
  },
  {
    label: "Malagasy",
    value: "Malagasy",
  },
  {
    label: "Malay",
    value: "Malay",
  },
  {
    label: "Malayalam",
    value: "Malayalam",
  },
  {
    label: "Maltese",
    value: "Maltese",
  },
  {
    label: "Manipuri",
    value: "Manipuri",
  },
  {
    label: "Maori",
    value: "Maori",
  },
  {
    label: "Marathi",
    value: "Marathi",
  },
  {
    label: "Mizo",
    value: "Mizo",
  },
  {
    label: "Moldavian",
    value: "Moldavian",
  },
  {
    label: "Mongolian",
    value: "Mongolian",
  },
  {
    label: "Nauru",
    value: "Nauru",
  },
  {
    label: "Nepali",
    value: "Nepali",
  },
  {
    label: "Norwegian",
    value: "Norwegian",
  },
  {
    label: "Occitan",
    value: "Occitan",
  },
  {
    label: "Oriya",
    value: "Oriya",
  },
  {
    label: "Pashto",
    value: "Pashto",
  },
  {
    label: "Persian",
    value: "Persian",
  },
  {
    label: "Polish",
    value: "Polish",
  },
  {
    label: "Portuguese",
    value: "Portuguese",
  },
  {
    label: "Punjabi",
    value: "Punjabi",
  },
  {
    label: "Quechua",
    value: "Quechua",
  },
  {
    label: "Rajasthani",
    value: "Rajasthani",
  },
  {
    label: "Reunionese",
    value: "Reunionese",
  },
  {
    label: "Rhaeto",
    value: "Rhaeto",
  },
  {
    label: "Romanian",
    value: "Romanian",
  },
  {
    label: "Russian",
    value: "Russian",
  },
  {
    label: "Samoan",
    value: "Samoan",
  },
  {
    label: "Sangha",
    value: "Sangha",
  },
  {
    label: "Sanskrit",
    value: "Sanskrit",
  },
  {
    label: "Santali",
    value: "Santali",
  },
  {
    label: "Scots Gaelic",
    value: "Scots Gaelic",
  },
  {
    label: "Serbian",
    value: "Serbian",
  },
  {
    label: "Serbo",
    value: "Serbo",
  },
  {
    label: "Sesotho",
    value: "Sesotho",
  },
  {
    label: "Setswana",
    value: "Setswana",
  },
  {
    label: "Shona",
    value: "Shona",
  },
  {
    label: "Sindhi",
    value: "Sindhi",
  },
  {
    label: "Sinhalese",
    value: "Sinhalese",
  },
  {
    label: "Siswati",
    value: "Siswati",
  },
  {
    label: "Slovak",
    value: "Slovak",
  },
  {
    label: "Slovenian",
    value: "Slovenian",
  },
  {
    label: "Somali",
    value: "Somali",
  },
  {
    label: "Spanish",
    value: "Spanish",
  },
  {
    label: "Sundanese",
    value: "Sundanese",
  },
  {
    label: "Swahili",
    value: "Swahili",
  },
  {
    label: "Swedish",
    value: "Swedish",
  },
  {
    label: "Tagalog",
    value: "Tagalog",
  },
  {
    label: "Tajik",
    value: "Tajik",
  },
  {
    label: "Tamil",
    value: "Tamil",
  },
  {
    label: "Tatar",
    value: "Tatar",
  },
  {
    label: "Telugu",
    value: "Telugu",
  },
  {
    label: "Thai",
    value: "Thai",
  },
  {
    label: "Tibetan",
    value: "Tibetan",
  },
  {
    label: "Tigrinya",
    value: "Tigrinya",
  },
  {
    label: "Tonga",
    value: "Tonga",
  },
  {
    label: "Tsonga",
    value: "Tsonga",
  },
  {
    label: "Tulu",
    value: "Tulu",
  },
  {
    label: "Turkish",
    value: "Turkish",
  },
  {
    label: "Turkmen",
    value: "Turkmen",
  },
  {
    label: "Twi",
    value: "Twi",
  },
  {
    label: "Uighur",
    value: "Uighur",
  },
  {
    label: "Ukranian",
    value: "Ukranian",
  },
  {
    label: "Urdu",
    value: "Urdu",
  },
  {
    label: "Uttarakhandi",
    value: "Uttarakhandi",
  },
  {
    label: "Uzbek",
    value: "Uzbek",
  },
  {
    label: "Varhadi-Nagpuri",
    value: "Varhadi-Nagpuri",
  },
  {
    label: "Vietnamese",
    value: "Vietnamese",
  },
  {
    label: "Volapuk",
    value: "Volapuk",
  },
  {
    label: "Welsh",
    value: "Welsh",
  },
  {
    label: "Wolof",
    value: "Wolof",
  },
  {
    label: "Xhosa",
    value: "Xhosa",
  },
  {
    label: "Yoddish",
    value: "Yoddish",
  },
  {
    label: "Yoruba",
    value: "Yoruba",
  },
  {
    label: "Zhuang",
    value: "Zhuang",
  },
  {
    label: "Zulu",
    value: "Zulu",
  },
];

export const priceForSingle = [
  {
    label: "Digital 45 (1.49 €)",
    value: "Digital 45 (1.49 €)",
  },
  {
    label: "Mini EP (1.99 €)",
    value: "Mini EP (1.99 €)",
  },
];

export const priceForEP = [
  {
    label: "Digital 45 (1.49 €)",
    value: "Digital 45 (1.49 €)",
  },
  {
    label: "Mini EP (1.99 €)",
    value: "Mini EP (1.99 €)",
  },
  {
    label: "EP (2.99 €)",
    value: "EP (2.99 €)",
  },
  {
    label: "Mini Album One (3.99 €)",
    value: "Mini Album One (3.99 €)",
  },
];

export const priceForAlbum = [
  {
    label: "Mini Album One (3.99 €)",
    value: "Mini Album One (3.99 €)",
  },
  {
    label: "Maxi Album Two (4.49 €)",
    value: "Maxi Album Two (4.49 €)",
  },
  {
    label: "Budget One (4.99 €)",
    value: "Budget One (4.99 €)",
  },
  {
    label: "Budget Two (5.99 €)",
    value: "Budget Two (5.99 €)",
  },
  {
    label: "Back (6.99 €)",
    value: "Back (6.99 €)",
  },
  {
    label: "Mid (7.99 €)",
    value: "Mid (7.99 €)",
  },
  {
    label: "Mid/Front (8.99 €)",
    value: "Mid/Front (8.99 €)",
  },
  {
    label: "Front One (9.99 €)",
    value: "Front One (9.99 €)",
  },
  {
    label: "Front Two (10.99 €)",
    value: "Front Two (10.99 €)",
  },
  {
    label: "Front/Plus (11.99 €)",
    value: "Front/Plus (11.99 €)",
  },
  {
    label: "Deluxe One (12.99 €)",
    value: "Deluxe One (12.99 €)",
  },
  {
    label: "Deluxe Two (13.99 €)",
    value: "Deluxe Two (13.99 €)",
  },
  {
    label: "Deluxe Three (14.99 €)",
    value: "Deluxe Three (14.99 €)",
  },
  {
    label: "Deluxe Four (15.99 €)",
    value: "Deluxe Four (15.99 €)",
  },
];
