import {
    uploadAPI,
    sendInviteAPI,
    requestReportAPI,
    requestReportListAPI,
    getNotificationsForUserAPI,
    checkNotificationBellAPI,
    videoUploadAPI
} from "./api"

export function upload(data) {
    return new Promise((resolve, reject) => {
        uploadAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function videoUpload(data) {
    return new Promise((resolve, reject) => {
        videoUploadAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function requestReport(data) {
    return new Promise((resolve, reject) => {
        requestReportAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function sendInvite(data) {
    return new Promise((resolve, reject) => {
        sendInviteAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function requestReportList(data) {
    return new Promise((resolve, reject) => {
        requestReportListAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function getNotificationsForUser(data) {
    return new Promise((resolve, reject) => {
        getNotificationsForUserAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function checkNotificationBell() {
    return new Promise((resolve, reject) => {
        checkNotificationBellAPI().then((res) => resolve(res)).catch(err => reject(err))
    })
}