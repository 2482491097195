import React, { useState } from 'react'
import OtpInput from "react-otp-input";
import { Grid, Box, withStyles, Button, CircularProgress, Tooltip } from '@material-ui/core';
import logo from "../../../assets/img/Fronic_Logo_white.png"
import * as actions from "../actions"
import { saveObject, toastError, toastSuccess } from '../../../utils';
import { useHistory } from "react-router-dom"

const StyledButton = withStyles({
    root: {
        background: 'linear-gradient(90deg, rgba(19,153,121,1) 0%, rgba(18,180,161,1) 45%, rgba(5,150,179,1) 100%)',
        fontSize: 18,
        height: 50
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

function OtpVerification(props) {
    const history = useHistory();

    const { user, forgot = false, setShowReset, setShowVerify } = props;
    const [otp, setOtp] = useState("")
    const [loading, setLoading] = useState(false)

    const onVerifyOtp = () => {
        setLoading(true)
        let verifyEmailOTP = actions.verifyEmailOTP
        let sendObj = {
            email: user.email,
            otp
        }

        if (forgot) {
            verifyEmailOTP = actions.VerifyForgetPasswordOTP
            sendObj = {
                email: user.email,
                OTP: otp
            }
        }

        verifyEmailOTP(sendObj).then(res => {
            setLoading(false)
            toastSuccess(res.message, 4000)
            if (forgot) {
                saveObject('tempToken', res.token)
                setShowVerify(false)
                setShowReset(true)
            } else {
                saveObject('auth', res.token)
                return history.push("/")
            }
        }).catch(err => {
            setLoading(false)
            toastError(err.message)
        })
    }

    const onResendOtp = () => {
        setLoading(true)
        actions.resendEmailOTP({ email: user.email, type: forgot ? "" : "verification" }).then(res => {
            setLoading(false)
            toastSuccess(res.message)
        }).catch(err => {
            setLoading(false)
            toastError(err.message)
        })
    }

    return (
        <div className="login-div">

            <Grid container>
                <Grid item xs={12}>
                    <div className="logo-image-div">
                        <img src={logo} alt="" />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <Box pt={4} pb={3}>
                        <p className="received-otp-p">Please enter OTP received on {user.email || "sameerpathania23@gmail.com"}</p>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box pt={2} pb={1}>
                        <OtpInput
                            isInputNum
                            value={otp}
                            numInputs={5}
                            shouldAutoFocus={true}
                            inputStyle="opt-input-field"
                            onChange={otp => setOtp(otp)}
                            containerStyle="container-input"
                        // separator={<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box pt={4} pb={2}>
                        <StyledButton
                            variant="contained"
                            color="primary"
                            style={{ height: 50 }}
                            onClick={onVerifyOtp}
                            fullWidth
                        >
                            {loading ? <CircularProgress size={25} style={{ color: "white" }} /> : "Verify"}
                        </StyledButton>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box pb={2}>
                        <Tooltip title="Please wait for 30 sec" arrow>
                            <p className="resend-otp" onClick={onResendOtp}>Resend OTP</p>
                        </Tooltip>
                    </Box>
                </Grid>

            </Grid>

        </div>
    )
}

export default OtpVerification
