import React, { useEffect, useState } from 'react'
import { Grid, Button, Box, Checkbox, withStyles } from "@material-ui/core"
import * as actions from "../../../containers/Releases/CreateReleases/actions"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { API_URL } from '../../../constants';
import { toastError, toastSuccess } from '../../../utils';
import { useHistory } from "react-router-dom"

const StyledButton = withStyles({
    root: {
        fontFamily: "'DM Sans', sans-serif",
        background: '#2d2d2d',
        fontSize: 14,
        height: 35,
        width: 150,
        borderColor: "#0597b2",
        margin: "auto",
        color: "white",
        '&:hover': {
            color: "black",
            border: '1px solid black'
        }
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

function Submission(props) {

    const history = useHistory();

    const { releaseID } = props;
    const [finalInfo, setFinalInfo] = useState({})
    const [checkTerm, setCheckTerm] = useState(false)

    useEffect(() => {
        props.setLoader(true)
        actions.getSubmissionDetails(releaseID).then(res => {
            props.setLoader(false)
            setFinalInfo(res.body)
        })
    }, [])

    const onSubmit = () => {
        props.setLoader(true)
        actions.submissionButton({ release_id: releaseID }).then(res => {
            props.setLoader(false)
            toastSuccess(res.message)
            history.push('/catalog')
        }).catch(err => {
            props.setLoader(false)
            toastError(err.message)
        })
    }

    const {
        album_artwork = '',
        release_title = '',
        genre = '',
        sub_genre = '',
        lable_name = '',
        primary_artist1 = '',
        number_of_tracks = 0
    } = finalInfo || {}

    return (
        <Grid container className="submission-container">
            <Grid item xs={12}>
                <h1>Release Information </h1>
            </Grid>

            <Grid item xs={12}>
                <Box mt={4}>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={4}>

                            <Box mt={1} mb={1}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <h3>Title</h3>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p>{release_title}</p>
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* <Box mt={1} mb={1}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <h3>Version/Subtitle</h3>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p>{ }</p>
                                    </Grid>
                                </Grid>
                            </Box> */}

                            <Box mt={1} mb={1}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <h3>Artist</h3>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p>{primary_artist1}</p>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box mt={1} mb={1}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <h3>Label</h3>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p>{lable_name}</p>
                                    </Grid>
                                </Grid>
                            </Box>

                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>

                            <Box mt={1} mb={1}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <h3>Genre</h3>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p>{genre}</p>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box mt={1} mb={1}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <h3>Subgenre</h3>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p>{sub_genre}</p>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box mt={1} mb={1}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <h3># songs</h3>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p>{number_of_tracks}</p>
                                    </Grid>
                                </Grid>
                            </Box>

                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box>
                                <div className="image-div">
                                    <img src={`${API_URL}/${album_artwork}`} alt="" />
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box mt={4} display="flex" justifyContent="center" alignItems="center">
                    <Checkbox
                        value={checkTerm}
                        onChange={(e) => setCheckTerm(e.target.checked)}
                    />
                    <h3>I understand and agree to the <a href="https://fronicmedia.com/distribution-agreement" target="_blank">Fronic Media Distribution Terms</a> & <a target="_blank" href="http://fronicmedia.com/privacy-policy">Privacy Policy</a></h3>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box mt={2} display="flex" justifyContent="center">
                    <StyledButton variant="contained" onClick={onSubmit} disabled={!checkTerm}><CheckCircleIcon style={{ marginRight: 8, fontSize: 17 }} />Submit</StyledButton>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Submission
