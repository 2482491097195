import React from 'react'
import { Box, Grid, InputLabel, TextField, withStyles } from "@material-ui/core"

const CssTextField = withStyles({
    root: {
        // background: "transparent",
        fontFamily: "'DM Sans', sans-serif",
        color: "#fff",
        '& label': {
            fontFamily: "'DM Sans', sans-serif",
            // color: '#10baa8',
            // color: "white"
        },
        '& label.Mui-focused': {
            // color: '#10baa8',
            color: "#000"
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'red',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                // borderColor: 'white',
            },
            '&:hover fieldset': {
                // borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#10baa8',
                borderWidth: "1px"
            },
        },
    },
})(TextField);

function AddArtist(props) {
    const { type, onChange, addArtist } = props;
    const isPrimary = type === "primary";
    return (
        <Grid container className="add-song-details-container">

            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel>{isPrimary ? "Primary Artist Name" : "Featuring Artist Name"}</InputLabel>
                    <CssTextField
                        name="artist_name"
                        placeholder={isPrimary ? "Primary Artist Name" : "Featuring Artist Name"}
                        variant="outlined"
                        size="small"
                        onChange={onChange}
                        value={addArtist.artist_name}
                        fullWidth
                    />
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel>{isPrimary ? "Primary Artist Apple Id" : "Featuring Artist Apple Id"}</InputLabel>
                    <CssTextField
                        name="apple_id"
                        placeholder={isPrimary ? "Primary Artist Apple Id" : "Featuring Artist Apple Id"}
                        variant="outlined"
                        size="small"
                        onChange={onChange}
                        value={addArtist.apple_id}
                        fullWidth
                    />
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel>{isPrimary ? "Primary Artist Spotify Id" : "Featuring Artist Spotify Id"}</InputLabel>
                    <CssTextField
                        name="spotify_id"
                        placeholder={isPrimary ? "Primary Artist Spotify Id" : "Featuring Artist Spotify Id"}
                        variant="outlined"
                        size="small"
                        onChange={onChange}
                        value={addArtist.spotify_id}
                        fullWidth
                    />
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box textAlign="center" mt={1}>
                    <a href="https://fronicmedia.com/faqs/how-to-find-apple-spotify-artist-id/" target="_blank" rel="noreferrer">How to find your Apple & Spotify Artist ID ?</a>
                </Box>
            </Grid>
        </Grid>
    )
}

export default AddArtist
