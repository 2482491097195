import React, { useState } from 'react'
import {
    Grid,
    Box,
    InputLabel,
    withStyles,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel
} from "@material-ui/core"
import AddIcon from '@material-ui/icons/Add';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from "moment"
import SelectCmp from "react-select"
import { toastError } from '../../../utils';
import ClearIcon from '@material-ui/icons/Clear';
import { staticGenre, trackTitleLanguage } from '../../../constants';
import { priceForEP, priceForSingle, priceForAlbum } from "../../../constants";
import CustomShowModal from '../../Common/CustomShowModal';

const selectStyles = {
    control: (base, state) => ({
        ...base,
        border: `1px solid ${state.isFocused ? '#00a1ff' : '#767676'} `,
        boxShadow: 'none',
        background: "transparent"
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#c7c7c7',
        }
    }
}

const CssTextField = withStyles({
    root: {
        // background: "transparent",
        fontFamily: "'DM Sans', sans-serif",
        color: "#fff",
        '& label': {
            fontFamily: "'DM Sans', sans-serif",
            // color: '#10baa8',
            color: "white"
        },
        '& label.Mui-focused': {
            // color: '#10baa8',
            color: "white"
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'red',
        },
        '& .MuiOutlinedInput-root': {
            // '& fieldset': {
            // borderColor: 'white',
            // },
            // '&:hover fieldset': {
            // borderColor: 'white',
            // },
            '&.Mui-focused fieldset': {
                borderColor: '#10baa8',
                borderWidth: "1px"
            },
        },
    },
})(TextField);

function SongDetailForm(props) {

    const {
        songInfo,
        setSongInfo,
        primaryArtist = [],
        setOpenAddFeaturing,
        setOpenAddPrimary,
        featuringArtist,
        addArtist,
        releaseInfo,
        setAddArtist,
        selectedSongPrimaryArtist,
        setSelectedSongPrimaryArtist,
        selectedSongfeaturingArtist,
        setSelectedSongfeaturingArtist,
        selectedSongAuthor,
        setSelectedSongAuthor,
        selectedSongComposer,
        setSelectedSongComposer
    } = props;

    let priceOptions = []
    if (releaseInfo.release_type === 'ep') {
        priceOptions = priceForEP
    } else if (releaseInfo.release_type === 'single') {
        priceOptions = priceForSingle
    } else {
        priceOptions = priceForAlbum
    }

    const [selectedDate, setSelectedDate] = useState(songInfo.production_year)
    const [openModal, setOpenModal] = useState(false)

    const onChangeHandler = (e) => {
        let { value, name } = e.target;
        if (name === 'caller_tune') {
            if (String(value).length > 8) return
            value = String(value).replace(/:/g, "");
            if (isNaN(Number(value))) {
                return
            }
            let newValue = ''
            for (let i = 0; i < value.toString().length; i++) {
                if (i === 2 || i === 4) {
                    newValue = newValue + ":"
                }
                newValue = newValue + String(value).charAt(i)

            }
            value = newValue
        }

        if (name === 'destribute_music' && value === 'No') {
            setSongInfo({ ...songInfo, video_isrc: '', download_video_link: '', [name]: value })
        } else {
            setSongInfo({ ...songInfo, [name]: value })
        }
    }

    const onChangeSelect = (e, type) => {
        if (type === 'primary') {
            const isExists = selectedSongPrimaryArtist.find(item => item.artist_id === e.artist_id)
            if (isExists) {
                return toastError("This artist is already added.")
            }
            if (selectedSongfeaturingArtist.length === 1 && selectedSongPrimaryArtist.length > 1) {
                return toastError("Maximum artist limit reached")
            }
            if (selectedSongfeaturingArtist.length === 2 && selectedSongPrimaryArtist.length > 0) {
                return toastError("Maximum artist limit reached")
            }
            if (selectedSongPrimaryArtist.length > 2) {
                return toastError("Maximum artist limit reached")
            }
            setSelectedSongPrimaryArtist([...selectedSongPrimaryArtist, e])
        } else if (type === 'featuring') {
            const isExists = selectedSongfeaturingArtist.find(item => item.artist_id === e.artist_id)
            if (isExists) {
                return toastError("This artist is already added.")
            }
            if (selectedSongfeaturingArtist.length > 1) {
                return toastError("Maximum artist limit reached")
            }
            if (selectedSongPrimaryArtist.length === 1 && selectedSongfeaturingArtist.length > 1) {
                return toastError("Maximum artist limit reached")
            }
            if (selectedSongPrimaryArtist.length === 2 && selectedSongfeaturingArtist.length > 0) {
                return toastError("Maximum artist limit reached")
            }
            if (selectedSongPrimaryArtist.length === 3) {
                return toastError("Maximum artist limit reached")
            }
            setSelectedSongfeaturingArtist([...selectedSongfeaturingArtist, e])
        } else if (type === 'author') {
            const isExists = selectedSongAuthor && selectedSongAuthor.find(item => item.label === e)
            if (isExists) {
                return toastError("This author is already added.")
            }
            if (selectedSongAuthor.length > 2) {
                return toastError("Can't add more author.")
            }
            setSelectedSongAuthor([...selectedSongAuthor, { label: e }])
        } else {
            const isExists = selectedSongComposer && selectedSongComposer.find(item => item.label === e)
            if (isExists) {
                return toastError("This author is already added.")
            }
            if (selectedSongComposer.length > 2) {
                return toastError("Can't add more author.")
            }
            setSelectedSongComposer([...selectedSongComposer, { label: e }])
        }
    }

    const removeSelectedPrimary = (type, index) => {
        if (type === "primary") {
            let newArr = [...selectedSongPrimaryArtist]
            newArr.splice(index, 1)
            setSelectedSongPrimaryArtist(newArr)
        } else if (type === "featuring") {
            let newArr = [...selectedSongfeaturingArtist]
            newArr.splice(index, 1)
            setSelectedSongfeaturingArtist(newArr)
        } else if (type === "author") {
            let newArr = [...selectedSongAuthor]
            newArr.splice(index, 1)
            setSelectedSongAuthor(newArr)
        } else {
            let newArr = [...selectedSongComposer]
            newArr.splice(index, 1)
            setSelectedSongComposer(newArr)
        }
    }

    const onTypeSelectField = (type, e, index) => {
        if (type === 'author') {
            let newSelected = [...selectedSongAuthor]
            newSelected[index].label = e.target.value
            setSelectedSongAuthor(newSelected)
        } else {
            let newSelected = [...selectedSongComposer]
            newSelected[index].label = e.target.value
            setSelectedSongComposer(newSelected)

        }
    }

    const selectedGenre = staticGenre.find(item => item.value === songInfo.genre)
    const selectedPriceTier = priceOptions.find(item => item.value === songInfo.price_tier)
    const selectedTrackTitle = trackTitleLanguage.find(item => item.value === songInfo.track_title_language)
    const selectedLyrics = trackTitleLanguage.find(item => item.value === songInfo.lyrics_language)


    return (
        <Grid container className="add-song-details-container">

            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel>Track Version<span className="required-span">*</span></InputLabel>
                    <RadioGroup name="track_version" value={songInfo.track_version} onChange={onChangeHandler} row>
                        <FormControlLabel classes={{ label: 'from-control-radio' }} value="Original" control={<Radio />} label="Original" />
                        <FormControlLabel classes={{ label: 'from-control-radio' }} value="Karaoke" control={<Radio />} label="Karaoke" />
                        <FormControlLabel classes={{ label: 'from-control-radio' }} value="Medley" control={<Radio />} label="Medley" />
                        <FormControlLabel classes={{ label: 'from-control-radio' }} value="Cover" control={<Radio />} label="Cover" />
                    </RadioGroup>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel>Instrumental<span className="required-span">*</span></InputLabel>
                    <RadioGroup name="instrumental" value={songInfo.instrumental} onChange={onChangeHandler} row>
                        <FormControlLabel classes={{ label: 'from-control-radio' }} value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel classes={{ label: 'from-control-radio' }} value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel>Title<span className="required-span">*</span></InputLabel>
                    <CssTextField
                        variant="outlined"
                        fullWidth
                        placeholder="Title"
                        size="small"
                        name="title"
                        value={songInfo.title}
                        onChange={onChangeHandler}
                    />

                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel>Version/Subtitle</InputLabel>
                    <CssTextField
                        variant="outlined"
                        fullWidth
                        placeholder="Version Subtitle"
                        size="small"
                        name="version_subtitle"
                        value={songInfo.version_subtitle}
                        onChange={onChangeHandler}
                    />

                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel>Primary Artist<span className="required-span">*</span></InputLabel>
                    <Grid container>
                        <Grid item xs={11}>
                            <SelectCmp
                                styles={selectStyles}
                                options={primaryArtist}
                                placeholder="Select Primary Artist"
                                onChange={(e) => onChangeSelect(e, "primary")}
                            />

                        </Grid>
                        <Grid item xs={1} style={{ alignSelf: "center" }}>
                            <AddIcon
                                className="add-icon-button"
                                onClick={() => { setAddArtist({ ...addArtist, 'artist_type': "primary" }); setOpenAddPrimary(true) }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        {
                            selectedSongPrimaryArtist.map((item, key) => <Grid item xs={12} key={key}>
                                <Box display="flex" justifyContent="space-between" p={1}>
                                    <div style={{ fontSize: "12px", display: "flex" }}>
                                        <div><p style={{ fontWeight: 600, marginRight: 5 }}>{key + 1}.</p></div>
                                        <div>
                                            <p style={{ fontWeight: 600 }}>Artist Name: {item.artist_name}</p>
                                            <p>Apple Id: {item.apple_id}</p>
                                            <p>Spotify Id: {item.spotify_id}</p>

                                        </div>
                                    </div>
                                    <div><ClearIcon onClick={() => removeSelectedPrimary("primary", key)} /></div>
                                </Box>
                            </Grid>)
                        }
                    </Grid>

                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel htmlFor="ReleaseTitle">Featuring Artist</InputLabel>
                    <Grid container>
                        <Grid item xs={11}>
                            <SelectCmp
                                styles={selectStyles}
                                options={featuringArtist}
                                placeholder="Select Featuring Artist"
                                onChange={(e) => onChangeSelect(e, "featuring")}
                            />

                        </Grid>
                        <Grid item xs={1} style={{ alignSelf: "center" }}>
                            <AddIcon
                                className="add-icon-button"
                                onClick={() => { setAddArtist({ ...addArtist, 'artist_type': "featuring" }); setOpenAddFeaturing(true) }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        {
                            selectedSongfeaturingArtist.map((item, key) => <Grid item xs={12} key={key}>
                                <Box display="flex" justifyContent="space-between" p={1}>
                                    <div style={{ fontSize: "12px", display: "flex" }}>
                                        <div><p style={{ fontWeight: 600, marginRight: 5 }}>{key + 1}.</p></div>
                                        <div>
                                            <p style={{ fontWeight: 600 }}>Artist Name: {item.artist_name}</p>
                                            <p>Apple Id: {item.apple_id}</p>
                                            <p>Spotify Id: {item.spotify_id}</p>

                                        </div>
                                    </div>
                                    <div><ClearIcon onClick={() => removeSelectedPrimary("featuring", key)} /></div>
                                </Box>
                            </Grid>)
                        }
                    </Grid>

                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel htmlFor="ReleaseTitle">Author<span className="required-span">*</span></InputLabel>

                    <Grid container>
                        {
                            selectedSongAuthor.map((item, key) => <React.Fragment key={key}>
                                <Grid item xs={key === 0 ? 12 : 11} >
                                    <Box display="flex" justifyContent="space-between">
                                        <CssTextField
                                            variant="outlined"
                                            fullWidth
                                            placeholder="Author"
                                            size="small"
                                            style={key === 0 ? { marginBottom: 3 } : { margin: '3px 0px' }}
                                            value={item.label}
                                            onChange={(e) => onTypeSelectField('author', e, key)}
                                        />
                                    </Box>
                                </Grid>
                                {key === 0 ? null : <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
                                    <ClearIcon onClick={() => removeSelectedPrimary("author", key)} />
                                </Grid>}
                            </React.Fragment>
                            )
                        }
                    </Grid>
                    {selectedSongAuthor.length > 2 ? null : <Grid container>
                        <Box display="flex">
                            <button className="custom-add-more-button" onClick={() => setSelectedSongAuthor([...selectedSongAuthor, { label: "" }])}>+ Add</button>
                        </Box>
                    </Grid>}

                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel htmlFor="ReleaseTitle">Composer<span className="required-span">*</span></InputLabel>

                    <Grid container>
                        {
                            selectedSongComposer.map((item, key) => <React.Fragment key={key}>
                                <Grid item xs={key === 0 ? 12 : 11} >
                                    <Box display="flex" justifyContent="space-between">
                                        <CssTextField
                                            variant="outlined"
                                            fullWidth
                                            placeholder="Composer"
                                            size="small"
                                            style={key === 0 ? { marginBottom: 3 } : { margin: '3px 0px' }}
                                            value={item.label}
                                            onChange={(e) => onTypeSelectField('composer', e, key)}
                                        />
                                    </Box>
                                </Grid>
                                {key === 0 ? null : <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
                                    <ClearIcon onClick={() => removeSelectedPrimary("composer", key)} />
                                </Grid>}
                            </React.Fragment>
                            )
                        }
                    </Grid>
                    {selectedSongComposer.length > 2 ? null : <Grid container>
                        <Box display="flex">
                            <button className="custom-add-more-button" onClick={() => setSelectedSongComposer([...selectedSongComposer, { label: "" }])}>+ Add</button>
                        </Box>
                    </Grid>}

                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel htmlFor="ReleaseTitle">Producer</InputLabel>
                    <CssTextField
                        id="ReleaseTitle"
                        variant="outlined"
                        fullWidth
                        placeholder="Producer"
                        size="small"
                        name="producer"
                        value={songInfo.producer}
                        onChange={onChangeHandler}
                    />

                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel><i className="fab fa-product-hunt"></i> Line<span className="required-span">*</span></InputLabel>
                    <CssTextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="p_line"
                        value={songInfo.p_line}
                        onChange={onChangeHandler}
                    />

                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel htmlFor="ReleaseTitle">Production Year<span className="required-span">*</span></InputLabel>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                            views={["year"]}
                            inputVariant="outlined"
                            size="small"
                            format="yyyy"
                            maxDate={moment().add(3, 'y')}
                            value={selectedDate}
                            fullWidth
                            name="production_year"
                            // InputAdornmentProps={{ position: "start" }}
                            onChange={date => { setSongInfo({ ...songInfo, production_year: date.format('yyyy') }); setSelectedDate(date.format('yyyy')) }}
                        />
                    </MuiPickersUtilsProvider>

                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel>Publisher</InputLabel>
                    <CssTextField
                        variant="outlined"
                        fullWidth
                        placeholder="Publisher"
                        size="small"
                        name="publisher"
                        value={songInfo.publisher}
                        onChange={onChangeHandler}
                    />

                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel>Have Your Own ISRC<span className="required-span">*</span></InputLabel>
                    <RadioGroup name="isrc_yes_no" value={songInfo.isrc_yes_no} onChange={onChangeHandler} row>
                        <FormControlLabel classes={{ label: 'from-control-radio' }} value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel classes={{ label: 'from-control-radio' }} value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                </Box>
            </Grid>

            {
                songInfo.isrc_yes_no === 'Yes' ? <Grid item xs={12}>
                    <Box p={1}>
                        <InputLabel>ISRC<span className="required-span">*</span></InputLabel>
                        <CssTextField
                            variant="outlined"
                            fullWidth
                            placeholder="ISRC"
                            size="small"
                            name="isrc"
                            value={songInfo.isrc}
                            onChange={onChangeHandler}
                        />

                    </Box>
                </Grid> : null
            }

            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel htmlFor="ReleaseTitle">Genre<span className="required-span">*</span></InputLabel>
                    <SelectCmp
                        styles={selectStyles}
                        options={staticGenre}
                        value={selectedGenre}
                        placeholder="Select Genre"
                        onChange={e => setSongInfo({ ...songInfo, genre: e.value })}
                    />

                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel>Sub Genre<span className="required-span">*</span></InputLabel>
                    <CssTextField
                        variant="outlined"
                        fullWidth
                        placeholder="Sub Genre"
                        size="small"
                        name="subgenre"
                        value={songInfo.subgenre}
                        onChange={onChangeHandler}
                    />

                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel htmlFor="ReleaseTitle">Price Tier<span className="required-span">*</span></InputLabel>
                    <SelectCmp
                        styles={selectStyles}
                        value={selectedPriceTier}
                        options={priceOptions}
                        placeholder="Select Price Tier"
                        onChange={e => setSongInfo({ ...songInfo, price_tier: e.value })}
                    />
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel>Explicit Version<span className="required-span">*</span></InputLabel>
                    <RadioGroup name="explicit_version" value={songInfo.explicit_version} onChange={onChangeHandler} row>
                        <FormControlLabel classes={{ label: 'from-control-radio' }} value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel classes={{ label: 'from-control-radio' }} value="No" control={<Radio />} label="No" />
                        <FormControlLabel classes={{ label: 'from-control-radio' }} value="Cleaned" control={<Radio />} label="Cleaned" />
                    </RadioGroup>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel htmlFor="ReleaseTitle">Track Title Language<span className="required-span">*</span></InputLabel>
                    <SelectCmp
                        styles={selectStyles}
                        value={selectedTrackTitle}
                        options={trackTitleLanguage}
                        placeholder="Track Title Language"
                        onChange={e => setSongInfo({ ...songInfo, track_title_language: e.value })}
                    />

                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel htmlFor="ReleaseTitle">Lyrics Language<span className="required-span">*</span></InputLabel>
                    <SelectCmp
                        styles={selectStyles}
                        value={selectedLyrics}
                        options={trackTitleLanguage}
                        placeholder="Lyrics Language"
                        onChange={e => setSongInfo({ ...songInfo, lyrics_language: e.value })}
                    />

                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel>Lyrics</InputLabel>
                    <CssTextField
                        variant="outlined"
                        fullWidth
                        placeholder="Lyrics"
                        name="lyrics"
                        size="small"
                        value={songInfo.lyrics}
                        onChange={onChangeHandler}
                    />

                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel>Caller Tune Timing</InputLabel>
                    <CssTextField
                        variant="outlined"
                        fullWidth
                        placeholder="HH:MM:SS"
                        size="small"
                        name="caller_tune"
                        value={songInfo.caller_tune}
                        onChange={onChangeHandler}
                    />

                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box p={1}>
                    <InputLabel>Want to distribute music video?</InputLabel>
                    <RadioGroup name="destribute_music" value={songInfo.destribute_music} onChange={onChangeHandler} row>
                        <FormControlLabel classes={{ label: 'from-control-radio' }} value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel classes={{ label: 'from-control-radio' }} value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                </Box>
            </Grid>

            {
                songInfo.destribute_music === 'Yes' ? <React.Fragment>
                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel>Video ISRC</InputLabel>
                            <CssTextField
                                variant="outlined"
                                fullWidth
                                placeholder="Video ISRC"
                                size="small"
                                name="video_isrc"
                                value={songInfo.video_isrc}
                                onChange={onChangeHandler}
                            />

                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box p={1}>
                            <InputLabel>Download link to video<span className="required-span">*</span></InputLabel>
                            <CssTextField
                                variant="outlined"
                                fullWidth
                                placeholder="Download link to video"
                                size="small"
                                name="download_video_link"
                                value={songInfo.download_video_link}
                                onChange={onChangeHandler}
                            />

                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <p style={{
                            fontSize: '12px',
                            textAlign: 'center',
                            marginTop: '-7px',
                            marginBottom: '12px',
                            color: '#555555'
                        }}>Preferred sites - <a href="https://wetransfer.com/" target="_blank" rel="noreferrer">wetransfer</a>, <a href="https://www.dropbox.com/" target="_blank" rel="noreferrer">Dropbox</a>, <a href="https://www.google.com/intl/en_ca/drive/download/" target="_blank" rel="noreferrer">GoogleDrive</a>, <a href="https://fromsmash.com/" target="_blank" rel="noreferrer">Formsmash</a></p>
                    </Grid>

                    <Grid item xs={12}>
                        <a className="artwork-guidlines" style={{ width: '100%', float: 'right', fontSize: 13, textAlign: 'end' }} onClick={() => setOpenModal(true)}>MUSIC VIDEO GUIDELINES/SPECIFICATIONS</a>
                    </Grid>

                </React.Fragment> : null
            }

            <CustomShowModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
                maxWidth="md"
                title="MUSIC VIDEO GUIDELINES/SPECIFICATIONS"
            >
                <h2>Music Video Guidelines</h2>
                <p>Graphic Overlays.
                Music videos must not contain chyrons, static or scrolling lyrics, or lower-third graphics.
                <br /><br />
                Release Dates or Advertisements. Music videos must not contain release dates, logos, or advertisements.
                <br /><br />
                Nonstandard Music Videos. Music videos that are artist interviews, commentaries, behind the scenes, or similar in content must be bundled with a music album and cannot be sold individually.
                <br /><br />
                Promotional Still Image Videos. Promotional videos (such as videos displaying only the cover art and audio or slide shows) will not be accepted.
                <br /><br />
                Teasers, Trailers and Partial Videos. Music videos which have been shortened or edited into a promotional teaser, trailer or partial version will not be accepted.
                <br /><br />
                Music Video Quality. Music videos with poor quality (such as glitches, blurriness, incomplete video or audio, no audio, out of sync audio and video, and so on) will not be accepted.
                <br /><br />
                Explicit Content. Music videos that contain explicit language, nudity, drug references, or depictions of drug use must be marked “Explicit.”
                <br /><br />
                Music Videos from Films. Music videos taken from musical films must contain a title version that refers to the film such as (From “Name of Film”).</p>
                <br />
                <h2>Music Video Specifications.</h2>
                &#8226;&nbsp;<span style={{ fontWeight: 600 }}>File Format:</span> QuickTime (.mov files)<br />
                &#8226;&nbsp;<span style={{ fontWeight: 600 }}>Codec :</span>  Apple PRO RES 422 (HQ)<br />
                &#8226;&nbsp;<span style={{ fontWeight: 600 }}>Size :</span> 1920×1080 pixels<br />
                &#8226;&nbsp;<span style={{ fontWeight: 600 }}>Video Bitrate :</span> ~220 Mb/s<br />
                &#8226;&nbsp;<span style={{ fontWeight: 600 }}>Framerate :</span> 24 or 25 fps<br />
                &#8226;&nbsp;<span style={{ fontWeight: 600 }}>Colorspace :</span> YUV 422<br />
                &#8226;&nbsp;<span style={{ fontWeight: 600 }}>Gamma Range :</span> Between  2.15 and 2.25<br />
                &#8226;&nbsp;<span style={{ fontWeight: 600 }}>Pixel ratio :</span> Ratio must be 1:1 (square pixel)<br />
                &#8226;&nbsp;<span style={{ fontWeight: 600 }}>Progressive :</span> The video must not be interlaced<br />
                &#8226;&nbsp;<span style={{ fontWeight: 600 }}>Black frame begining/ending :</span> The video must contain one black frame at the beginning and at the end<br />
                &#8226;&nbsp;<span style={{ fontWeight: 600 }}>Black bar scopes and logos :</span> No logo will be accepted Black bars accepted but we need crop size information (in pixels). * crop left: The number of whole pixels from the left of the encoded image to remove. * crop top: The number of whole pixels from the top of the encoded image to remove. * crop right: The number of whole pixels from the right of the encoded image to remove. * crop bottom: The number of whole pixels from the bottom of the encoded image to remove.
                Sound Quality : Codec – PCM Sampling frequency, 48 kHz Sample size, 16 bits Audio bitrate, ~1536 kb/s, 2 Channels (stereo)
                {/* <p style={{ marginTop: 20 }}>Format: mp3 or wav</p>
                <p style={{ marginTop: 10 }}><span style={{ fontWeight: 600 }}>Requirements:</span> Minimum of 16 bit, 44.1 Khz, stereo</p>
                <p><span style={{ fontWeight: 600 }}>Recommended</span> 24 bits, 48Khz or 24 bits 96Khz.</p> */}
            </CustomShowModal>
        </Grid >
    )
}

export default SongDetailForm
