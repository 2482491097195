import React, { useState } from 'react'
import {
    Box,
    Button,
    Grid,
    withStyles,
    LinearProgress
} from "@material-ui/core"
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { API_URL } from '../../../constants';
import UpdateIcon from '@material-ui/icons/Update';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CustomShowModal from '../../Common/CustomShowModal';

const StyledButton = withStyles({
    root: {
        fontFamily: "'DM Sans', sans-serif",
        // background: 'linear-gradient(90deg, rgba(19,153,121,1) 0%, rgba(18,180,161,1) 45%, rgba(5,150,179,1) 100%)',
        fontSize: 14,
        height: 35,
        width: 150,
        borderColor: "#0597b2",
        // margin: "auto",
        color: "#0597b2"
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

const StyledSubmit = withStyles({
    root: {
        fontFamily: "'DM Sans', sans-serif",
        backgroundColor: "green",
        // background: 'linear-gradient(90deg, rgba(19,153,121,1) 0%, rgba(18,180,161,1) 45%, rgba(5,150,179,1) 100%)',
        fontSize: 14,
        height: 35,
        width: 150,
        borderColor: "#0597b2",
        margin: "auto",
        color: "white",
        '&:hover': {
            backgroundColor: "#1cb91c",

        }

    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

const StyledContained = withStyles({
    root: {
        fontFamily: "'DM Sans', sans-serif",
        background: '#2d2d2d',
        fontSize: 14,
        height: 35,
        width: 200,
        borderColor: "#0597b2",
        // margin: "auto",
        color: "white",
        '&:hover': {
            color: "black",
            border: '1px solid black'
        }
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

function SongInfo(props) {
    const {
        isSingleTrack = false,
        onSongFileHandler,
        showUpload,
        isProgressDone,
        uploadProgress,
        setCurrentEditIndex,
        savedSongsDetails = [],
        addNewTrack,
        deleteSavedSongDetails,
        setOpenSongDetail,
        onSaveSongDetails,
        songInfoMusicLink,
        onClickSongEdit,
        showSubmitSong,
        songInfo
    } = props;

    const [open, setOpen] = useState(false)

    return (
        <Grid container className="songinfo-container">
            <Grid item xs={12}>
                <h3>Upload Assets</h3>
            </Grid>

            <Grid item xs={12}>
                <a className="artwork-guidlines" onClick={() => setOpen(true)}>AUDIO FILE GUIDELINES</a>
            </Grid>
            <Grid item xs={12}>

                {savedSongsDetails && savedSongsDetails.length ? savedSongsDetails.map((item, index) => {
                    if (item.saved) {
                        return <Grid container className="song-detail-saved" key={index}>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <span>{item.title}</span>
                                    <StyledButton variant="outlined" onClick={() => onClickSongEdit(index)}>Edit</StyledButton>
                                </Box>

                            </Grid>
                        </Grid>
                    } else {
                        return <Grid container className="song-upload-details" key={index}>
                            <Grid item xs={12} sm={12} md={6}>
                                <div className="image-div">
                                    <label htmlFor="song-upload-file" className="song-upload-file-label">
                                        {showUpload ?
                                            <div className="upload-progress">
                                                <div className="upload-div">
                                                    {isProgressDone ? <audio controls className="audio-media-player">
                                                        <source src={`${API_URL}/${songInfoMusicLink}`} type="audio/ogg" />
                                                    </audio> : <LinearProgress
                                                            variant="determinate"
                                                            value={uploadProgress}
                                                            className={`linearProgress ${isProgressDone ? "progress-done" : ""}`}
                                                        />}
                                                </div>
                                                {isProgressDone ? <p className="upload-p-tag"><CheckCircleIcon /></p> : <p className="upload-p-tag">{uploadProgress} %</p>}
                                            </div> :
                                            <div className="upload-span">
                                                <span className="span-1">Choose File</span><span className="span-2">Click here to select a file ....</span>
                                            </div>
                                        }
                                    </label>
                                </div>
                                {/* {
                                    recipeFilePreview ? <label htmlFor="song-upload-file" className="change-image">Change</label> : null
                                } */}
                                {showUpload ? null : <input
                                    type="file"
                                    id="song-upload-file"
                                    accept=".mp3,audio/*"
                                    onChange={(e) => onSongFileHandler(e, index)}
                                />}
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <Box display="flex" height="100%" alignItems="center">
                                    <StyledContained variant="contained" onClick={() => { setCurrentEditIndex(index); setOpenSongDetail(true) }}>{songInfo.edit ? <UpdateIcon style={{ fontSize: "18px", marginRight: "5px" }} /> : <AddCircleIcon style={{ fontSize: "18px", marginRight: "5px" }} />}{songInfo.edit ? 'Edit' : 'Add'} Song Details</StyledContained>
                                    {showSubmitSong ? <StyledSubmit onClick={onSaveSongDetails}>{songInfo.edit ? 'Save' : 'Submit'}</StyledSubmit> : null}
                                    {(!showSubmitSong && !songInfo.edit && index !== 0) ? <DeleteForeverIcon onClick={() => deleteSavedSongDetails(index)} className="delete-svg" /> : null}
                                </Box>
                            </Grid>
                        </Grid>
                    }
                }) : null}

            </Grid>

            {isSingleTrack ? null : <Grid item xs={12}>
                <div style={{ width: "100%", paddingRight: 10 }}>
                    <StyledButton variant="outlined" style={{ float: "right" }} onClick={addNewTrack}>
                        <AddIcon style={{ fontSize: "18px", marginRight: "5px" }} />
                        Add Track
                    </StyledButton>
                </div>
            </Grid>}
            <CustomShowModal
                open={open}
                handleClose={() => setOpen(false)}
                maxWidth="sm"
                title="AUDIO FILE GUIDELINES"
            >
                <p>The audio files should not have any sound imperfection. Audio files containing any background noise and other sound imperfections will not be accepted for distribution.</p>

                <p style={{ marginTop: 20 }}>Format: mp3 or wav</p>
                <p style={{ marginTop: 10 }}><span style={{ fontWeight: 600 }}>Requirements:</span> Minimum of 16 bit, 44.1 Khz, stereo</p>
                <p><span style={{ fontWeight: 600 }}>Recommended</span> 24 bits, 48Khz or 24 bits 96Khz.</p>
            </CustomShowModal>
        </Grid >
    )
}

export default SongInfo
