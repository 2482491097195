import { 
  getAudioTitlesByReleaseIdAPI, 
  getReleaseTitlesAPI, 
  getReleaseTitlesPlaylistAPI, 
  getUGCClaimHistoryAPI, 
  submitUGCClaimAPI,
  getPlaylistPitchHistoryAPI,
  getProfileLinkingHistoryAPI,
  submitPitchPlaylistAPI,
  submitProfileLinkingAPI, 
  getLyricsHistoryAPI,
  submitDistributeLyricsAPI,
} from "./api";

export function getReleaseTitles(data) {
  return new Promise((resolve, reject) => {
    getReleaseTitlesAPI(data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}

export function getUGCClaimHistory(data) {
  return new Promise((resolve, reject) => {
    getUGCClaimHistoryAPI(data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}

export function getAudioTitlesByReleaseId(data) {
  return new Promise((resolve, reject) => {
    getAudioTitlesByReleaseIdAPI(data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}

export function submitUGCClaim(data) {
  return new Promise((resolve, reject) => {
    submitUGCClaimAPI(data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}

export function getReleaseTitlesPlaylist(data) {
  return new Promise((resolve, reject) => {
    getReleaseTitlesPlaylistAPI(data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}
export function getPlaylistPitchHistory(data) {
  return new Promise((resolve, reject) => {
    getPlaylistPitchHistoryAPI(data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}
export function getProfileLinkingHistory(data) {
  return new Promise((resolve, reject) => {
    getProfileLinkingHistoryAPI(data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}
export function submitPitchPlaylist(data) {
  return new Promise((resolve, reject) => {
    submitPitchPlaylistAPI(data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}
export function submitProfileLinking(data) {
  return new Promise((resolve, reject) => {
    submitProfileLinkingAPI(data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}

export function getLyricsHistory(data) {
  return new Promise((resolve, reject) => {
    getLyricsHistoryAPI(data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}

export function submitDistributeLyrics(data) {
  return new Promise((resolve, reject) => {
    submitDistributeLyricsAPI(data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}