import React, { useState, useEffect } from 'react'
import { Box, Grid, Tabs, Tab, CircularProgress } from '@material-ui/core';
import ReleaseInfo from '../../../components/Releases/CreateReleases/ReleaseInfo';
import SongInfo from '../../../components/Releases/CreateReleases/SongInfo';
import Platform from '../../../components/Releases/CreateReleases/Platform';
import Submission from '../../../components/Releases/CreateReleases/Submission';
import * as actions from "./actions"
import CustomAddModal from '../../../components/Common/CustomAddModal';
import AddArtist from './AddArtist';
import { getHeaders, removeObject, saveObject, toastError, toastSuccess } from '../../../utils';
import * as commonActions from "../../common/actions"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ConfirmModal from '../../../components/Common/CommonModal';
import axios from "axios"
import { API_URL } from '../../../constants';
import SongDetailForm from '../../../components/Releases/CreateReleases/SongDetailForm';
import moment from "moment"
import { useParams } from "react-router-dom"
import noaccess from "../../../assets/img/noaccess.png"

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const initialArtist = {
    artist_type: "primary",
    artist_name: "",
    apple_id: "",
    spotify_id: ""
}

const initialReleaseInfo = {
    album_artwork: "",
    release_title: "",
    release_type: "",
    primary_artist1: "",
    primary_artist2: "",
    primary_artist3: "",
    featuring1: "",
    featuring2: "",
    featuring3: "",
    genre: "",
    sub_genre: "",
    lable_name: "",
    release_date: "",
    p_line: "",
    c_line: "",
    upc_ean: ""
}

const initialSongInfo = {
    release_id: "",
    audio_track: "",
    track_version: "Original",
    instrumental: "No",
    title: "",
    version_subtitle: "",
    primary_artist1: "",
    primary_artist2: "",
    primary_artist3: "",
    featuring1: "",
    featuring2: "",
    featuring3: "",
    author1: "",
    author2: "",
    author3: "",
    composer1: "",
    composer2: "",
    composer3: "",
    producer: "",
    p_line: "",
    production_year: moment().format('yyyy'),
    publisher: "",
    isrc_yes_no: "No",
    isrc: "",
    genre: "",
    subgenre: "",
    price_tier: "",
    explicit_version: "No",
    track_title_language: "",
    lyrics_language: "",
    lyrics: "",
    caller_tune: "",
    destribute_music: "No",
    video_isrc: "",
    download_video_link: "",
    saved: false,
    edit: false,
}

export default function CreateReleases(props) {

    const params = useParams()

    const [tabIndex, setTabIndex] = useState(1)
    const [recipeFilePreview, setRecipeFilePreview] = useState('')
    const [primaryArtist, setPrimaryArtist] = useState([])
    const [featuringArtist, setFeaturingArtist] = useState([])
    const [openAddPrimary, setOpenAddPrimary] = useState(false)
    const [openAddFeaturing, setOpenAddFeaturing] = useState(false)
    const [addArtist, setAddArtist] = useState({ ...initialArtist })
    const [selectedPrimaryArtist, setSelectedPrimaryArtist] = useState([])
    const [selectedFeaturingArtist, setSelectedFeaturingArtist] = useState([])
    const [releaseInfo, setReleaseInfo] = useState({ ...initialReleaseInfo })
    const [stepsDone, setStepsDone] = useState({
        first: false,
        second: false,
        third: false,
        fourth: false
    })
    const [disableTab, setDisableTab] = useState({
        first: false,
        second: true,
        third: true,
        fourth: true
    })
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [showUpload, setShowUpload] = useState(false)
    const [songInfo, setSongInfo] = useState({ ...initialSongInfo });
    const [uploadProgress, setUploadProgress] = useState(0)
    const [isProgressDone, setIsProgressDone] = useState(false)
    const [openSongDetail, setOpenSongDetail] = useState(false)

    const [selectedSongPrimaryArtist, setSelectedSongPrimaryArtist] = useState([])
    const [selectedSongfeaturingArtist, setSelectedSongfeaturingArtist] = useState([])
    const [selectedSongAuthor, setSelectedSongAuthor] = useState([{ label: "" }])
    const [selectedSongComposer, setSelectedSongComposer] = useState([{ label: "" }])

    const [currentEditIndex, setCurrentEditIndex] = useState(0)
    const [savedSongsDetails, setSavedSongsDetails] = useState([{ ...initialSongInfo }])
    const [releaseID, setReleaseID] = useState(null);
    const [platformLogo, setPlatformLogo] = useState([])
    const [showSubmitSong, setShowSubmitSong] = useState(false)
    const [insertDraft, setInsertDraft] = useState(false)
    const [editMode, setEditMode] = useState(false)

    const [platform, setPlatform] = useState({
        release_id: null,
        crbt: "no",
        video_platforms: "no"
    })
    const [isUserRevoked, setIsUserRevoked] = useState(false)
    const [initialLoader, setInitialLoader] = useState(true)
    const [songInfoMusicLink, setSongInfoMusicLink] = useState(null);
    const [awayData, setAwayData] = useState({
        status: false,
        text: ''
    })

    useEffect(() => {
        props.setLoader(true)
        actions.checkAwayStatus().then(res => {
            const isAway = (res && res.body && res.body.status && res.body.status === "yes" && true) || false
            const awayText = (res && res.body && res.body.text && res.body.text) || ''
            if (isAway) {
                setInitialLoader(false)
                props.setLoader(false)
                setAwayData({
                    status: true,
                    text: awayText
                })
            } else {
                actions.checkUserRevokeStatus().then(res => {
                    props.setLoader(false)
                    setInitialLoader(false)
                    if (res.revoked === 1) {
                        setIsUserRevoked(true)
                    } else {
                        setIsUserRevoked(false)
                        onFetchArtists();
                        onFetchLogos();
                        if (params.id) {
                            props.setLoader(true)
                            setEditMode(true)
                            setReleaseID(Number(params.id))
                        }
                    }
                }).catch(err => {
                    props.setLoader(false)
                    // setInitialLoader(false)
                    toastError(err.message)
                })
            }
        })
        return () => {
            setReleaseID(null)
            removeObject('releaseId')
            removeObject('releaseInfo')
        }
    }, [])

    useEffect(() => {
        if (params.id && insertDraft) {
            actions.getDraft(params.id).then(res => {
                props.setLoader(false)
                const { album_artwork = '', release_title = '', release_type = '', genre = '', sub_genre = '', lable_name = '', release_date = '',
                    p_line = '', c_line = '', upc_ean = '', primary_artist1 = '', primary_artist2 = '', primary_artist3 = '', featuring1 = '', featuring2 = '', featuring3 = '',
                    audios = [], crbt = '', video_platforms = '' } = res.body;
                let audioExists = (Array.isArray(audios) && audios.length > 0 ? true : false) || false
                setStepsDone({ ...stepsDone, first: true, second: audioExists, third: (crbt === 'yes' || video_platforms === 'yes') })
                setPlatform({ release_id: Number(params.id), crbt, video_platforms })
                setDisableTab({ first: false, second: false, third: !audioExists, fourth: true })
                setReleaseInfo({
                    ...res.body,
                    album_artwork: album_artwork,
                    release_title: release_title,
                    release_type: release_type,
                    primary_artist1: "",
                    primary_artist2: "",
                    primary_artist3: "",
                    featuring1: "",
                    featuring2: "",
                    featuring3: "",
                    genre: genre,
                    sub_genre: sub_genre,
                    lable_name: lable_name,
                    release_date: '',
                    p_line: p_line,
                    c_line: c_line,
                    upc_ean: upc_ean
                })
                let primary = []
                if (primary_artist1) {
                    primary.push(Number(primary_artist1))
                }
                if (primary_artist2) {
                    primary.push(Number(primary_artist2))
                }
                if (primary_artist3) {
                    primary.push(Number(primary_artist3))
                }

                primary = primary.map(artist => primaryArtist.find(item => item.artist_id === artist))
                setSelectedPrimaryArtist(primary)

                let featuring = []
                if (featuring1) {
                    featuring.push(Number(featuring1))
                }
                if (featuring2) {
                    featuring.push(Number(featuring2))
                }
                if (featuring3) {
                    featuring.push(Number(featuring3))
                }
                featuring = featuring.map(artist => featuringArtist.find(item => item.artist_id === artist))
                setSelectedFeaturingArtist(featuring)

                if (Array.isArray(audios) && audios.length > 0) {
                    const newSavedSongsDetails = audios.map(audio => {
                        const { primary_artist1 = '', primary_artist2 = '', primary_artist3 = '', featuring1 = '', featuring2 = '', featuring3 = '',
                            author1 = '', author2 = '', author3 = '', composer1 = '', composer2 = '', composer3 = '' } = audio || {}
                        let primary = []
                        if (primary_artist1) {
                            primary.push(Number(primary_artist1))
                        }
                        if (primary_artist2) {
                            primary.push(Number(primary_artist2))
                        }
                        if (primary_artist3) {
                            primary.push(Number(primary_artist3))
                        }

                        primary = primary.map(artist => primaryArtist.find(item => item.artist_id === artist))

                        let featuring = []
                        if (featuring1) {
                            featuring.push(Number(featuring1))
                        }
                        if (featuring2) {
                            featuring.push(Number(featuring2))
                        }
                        if (featuring3) {
                            featuring.push(Number(featuring3))
                        }

                        featuring = featuring.map(artist => featuringArtist.find(item => item.artist_id === artist))

                        let authorList = []
                        if (author1) {
                            authorList.push({ label: author1 })
                        }
                        if (author2) {
                            authorList.push({ label: author1 })
                        }
                        if (author3) {
                            authorList.push({ label: author1 })
                        }

                        let composerList = []
                        if (composer1) {
                            composerList.push({ label: composer1 })
                        }
                        if (composer2) {
                            composerList.push({ label: composer2 })
                        }
                        if (composer3) {
                            composerList.push({ label: composer3 })
                        }

                        return {
                            ...audio,
                            saved: true,
                            edit: true,
                            selectedSongPrimaryArtist: primary,
                            selectedSongfeaturingArtist: featuring,
                            selectedSongAuthor: authorList,
                            selectedSongComposer: composerList

                        }

                    })
                    setSavedSongsDetails(newSavedSongsDetails)
                }
                setRecipeFilePreview(`${API_URL}/${album_artwork}`)
            }).catch(err => {
                props.setLoader(false)
            })
        }
    }, [insertDraft])

    const onFetchLogos = () => {
        actions.getLogos().then(res => {
            setPlatformLogo(res.body)
        })
    }

    const onFetchArtists = async () => {
        await actions.getArtist('primary').then(res => setPrimaryArtist(res.body.map(item => ({ ...item, label: item.artist_name, value: item.artist_id }))))
        await actions.getArtist('featuring').then(res => setFeaturingArtist(res.body.map(item => ({ ...item, label: item.artist_name, value: item.artist_id }))))
        if (params.id) setInsertDraft(true)
    }

    const handleTabChange = (e, value) => {
        setTabIndex(value)
    }

    const onChangeArtist = ({ target: { name = '', value = '' } }) => {
        setAddArtist({ ...addArtist, [name]: value })
    }

    const onChangeHandler = ({ target: { name = '', value = '' } }) => {

    }

    const onSongFileHandler = (e, index) => {
        if (showUpload) return;
        const file = e.target.files[0];
        if (file.size > 104857600) {
            return toastError('Audio file more than 100 MB is not allowed.')
        }
        if (!isValidExtension(file)) {
            return toastError('Supported Audio files (.mp3 & .wav)')
        }
        const data1 = new FormData();
        data1.append("file", file)
        setShowUpload(true)
        axios.post(`${API_URL}/api/upload`, data1, {
            headers: {
                ...getHeaders()
            },
            onUploadProgress: (progressEvent) => {
                const uploadProgress = Math.round(progressEvent.loaded / progressEvent.total * 100);
                setUploadProgress(uploadProgress)
            }
        }).then(res => {
            toastSuccess(res.data.message)
            // setSongInfo({ ...songInfo, audio_track: res.data.body.filename })
            setSongInfoMusicLink(res.data.body.filename)
            setIsProgressDone(true)
        }).catch(err => {
            toastError(err.message)
        })
    }

    const isValidExtension = (file) => {
        let lastDot = file.name.lastIndexOf(".")
        let ext = file.name.substring(lastDot + 1)
        if (ext === 'mp3' ||
            ext === 'wav'
        ) {
            return true
        }
        return false
    }

    const onFileHandler = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 1572864) {
                return toastError('File more than 1.5 MB is not allowed.')
            }
            props.setLoader(true)
            commonActions.upload(file).then(res => {
                props.setLoader(false)
                setReleaseInfo({ ...releaseInfo, album_artwork: res.body.filename })
                previewsFile(file)
                toastSuccess("Artwork uploaded successfully.")
            }).catch(err => {
                props.setLoader(false)
                toastError(err.message || 'Something went wrong')
            })
        }
    }

    const previewsFile = (file) => {
        const reader = new FileReader();
        reader.addEventListener("load", function () {
            //convert
            setRecipeFilePreview(reader.result)
        }, false);
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const onSubmitAddArtist = () => {
        props.setLoader(true)
        actions.addArtist(addArtist).then(res => {
            props.setLoader(false)
            setAddArtist({ ...initialArtist })
            toastSuccess(res.message)
            setOpenAddPrimary(false)
            setOpenAddFeaturing(false)
            onFetchArtists();
        }).finally(() => props.setLoader(false))
    }

    const removeSelectedPrimary = (type, index) => {
        if (type === "primary") {
            let newArr = [...selectedPrimaryArtist]
            newArr.splice(index, 1)
            setSelectedPrimaryArtist(newArr)
        } else {
            let newArr = [...selectedFeaturingArtist]
            newArr.splice(index, 1)
            setSelectedFeaturingArtist(newArr)
        }
    }

    const onChangeSelect = (e, type) => {
        if (type === "primary") {
            const isExists = selectedPrimaryArtist.find(item => item.artist_id === e.artist_id)
            if (isExists) {
                return toastError("This artist is already added.")
            }
            if (selectedFeaturingArtist.length === 1 && selectedPrimaryArtist.length > 1) {
                return toastError("Maximum artist limit reached")
            }
            if (selectedFeaturingArtist.length === 2 && selectedPrimaryArtist.length > 0) {
                return toastError("Maximum artist limit reached")
            }
            if (selectedPrimaryArtist.length > 2) {
                return toastError("Maximum artist limit reached")
            }
            setSelectedPrimaryArtist([...selectedPrimaryArtist, e])
        } else {
            const isExists = selectedFeaturingArtist.find(item => item.artist_id === e.artist_id)
            if (isExists) {
                return toastError("This artist is already added.")
            }
            if (selectedPrimaryArtist.length === 1 && selectedFeaturingArtist.length > 1) {
                return toastError("Maximum artist limit reached")
            }
            if (selectedPrimaryArtist.length === 2 && selectedFeaturingArtist.length > 0) {
                return toastError("Maximum artist limit reached")
            }
            if (selectedPrimaryArtist.length === 3) {
                return toastError("Maximum artist limit reached")
            }
            if (selectedFeaturingArtist.length > 1) {
                return toastError("Maximum artist limit reached")
            }
            setSelectedFeaturingArtist([...selectedFeaturingArtist, e])
        }
    }

    const createReleaseInfo = () => {
        let newReleaseInfo = { ...releaseInfo }
        selectedPrimaryArtist.forEach((item, key) => {
            newReleaseInfo = { ...newReleaseInfo, [`primary_artist${key + 1}`]: item.artist_id }
        })
        selectedFeaturingArtist.forEach((item, key) => {
            newReleaseInfo = { ...newReleaseInfo, [`featuring${key + 1}`]: item.artist_id }
        })
        props.setLoader(true)
        if (editMode) {
            actions.updateReleaseInfo(newReleaseInfo).then(res => {
                props.setLoader(false)
                toastSuccess(res.message)
                handleTabChange({}, 2)
                setConfirmOpen(false)
            }).catch(err => {
                toastError(err.message)
                props.setLoader(false)
            })
            return
        }
        actions.createReleaseInfo(newReleaseInfo).then(res => {
            toastSuccess(res.message)
            setReleaseID(res.release_id)
            setPlatform({ ...platform, release_id: res.release_id })
            saveObject('releaseId', res.release_id)
            saveObject('releaseInfo', releaseInfo)
            setStepsDone({ ...stepsDone, first: true })
            setDisableTab({ ...disableTab, second: false })
            handleTabChange({}, 2)
            setEditMode(true)
            setConfirmOpen(false)
            setTimeout(() => {
                props.setLoader(false)
            }, 1000)
        }).catch(err => {
            toastError(err.message)
            props.setLoader(false)
        })
    }

    const onSaveSongDetails = () => {
        if (!isProgressDone) return toastError('File upload is pending.')
        let newSongInfo = { ...songInfo }
        let newSavedSongsDetails = [...savedSongsDetails]
        selectedSongPrimaryArtist.forEach((item, key) => {
            newSongInfo = { ...newSongInfo, [`primary_artist${key + 1}`]: item.artist_id }
        })
        selectedSongfeaturingArtist.forEach((item, key) => {
            newSongInfo = { ...newSongInfo, [`featuring${key + 1}`]: item.artist_id }
        })
        selectedSongAuthor.forEach((item, key) => {
            newSongInfo = { ...newSongInfo, [`author${key + 1}`]: item.label }
        })
        selectedSongComposer.forEach((item, key) => {
            newSongInfo = { ...newSongInfo, [`composer${key + 1}`]: item.label }
        })
        newSongInfo.selectedSongPrimaryArtist = selectedSongPrimaryArtist
        newSongInfo.selectedSongfeaturingArtist = selectedSongfeaturingArtist
        newSongInfo.selectedSongAuthor = selectedSongAuthor
        newSongInfo.selectedSongComposer = selectedSongComposer
        newSongInfo.audio_track = songInfoMusicLink
        props.setLoader(true)
        if (newSongInfo.edit) {
            actions.updateSongInfo({ ...newSongInfo, release_id: releaseID }).then(res => {
                props.setLoader(false)
                toastSuccess(res.message)
                setOpenSongDetail(false)
                setSongInfoMusicLink(null)
                setSelectedSongPrimaryArtist([])
                setSelectedSongfeaturingArtist([])
                setSelectedSongAuthor([{ label: "" }])
                setSelectedSongComposer([{ label: "" }])
                newSavedSongsDetails[currentEditIndex] = { ...newSongInfo, saved: true, edit: true }
                setSavedSongsDetails(newSavedSongsDetails)
            }).catch(err => {
                props.setLoader(false)
                toastError(err.message)
            })
            return
        }

        actions.addSongInfo({ ...newSongInfo, release_id: releaseID }).then(res => {
            props.setLoader(false)
            newSavedSongsDetails[currentEditIndex] = { ...newSongInfo, saved: true, edit: true, audio_id: res.audio_id }
            setOpenSongDetail(false)
            setSongInfoMusicLink(null)
            setSelectedSongPrimaryArtist([])
            setSelectedSongfeaturingArtist([])
            setSelectedSongAuthor([{ label: "" }])
            setSelectedSongComposer([{ label: "" }])
            setStepsDone({ ...stepsDone, second: true })
            setDisableTab({ ...disableTab, third: false })
            setSavedSongsDetails(newSavedSongsDetails)
        }).catch(err => {
            props.setLoader(false)
            toastError(err.message)
        })
    }

    const addNewTrack = () => {
        const isSaved = savedSongsDetails.find(item => !item.saved) || null;
        if (!isSaved) {
            setShowUpload(false)
            setIsProgressDone(false)
            setUploadProgress(0)
            setShowSubmitSong(false)
            setSavedSongsDetails([...savedSongsDetails, { ...initialSongInfo }])
            setSongInfo({ ...initialSongInfo })
        } else {
            toastError('Please save the song details first.')
        }
    }

    const onClickSongEdit = (index) => {
        const isSaved = savedSongsDetails.find(item => !item.saved) || null;
        if (!isSaved) {
            let newSavedSongsDetails = [...savedSongsDetails]
            setShowUpload(true)
            setIsProgressDone(true)
            setUploadProgress(100)
            setCurrentEditIndex(index)
            newSavedSongsDetails[index].saved = false
            setSavedSongsDetails(newSavedSongsDetails)
            setSongInfo(newSavedSongsDetails[index])
            setSongInfoMusicLink(newSavedSongsDetails[index].audio_track)
            setSelectedSongPrimaryArtist(newSavedSongsDetails[index].selectedSongPrimaryArtist)
            setSelectedSongfeaturingArtist(newSavedSongsDetails[index].selectedSongfeaturingArtist)
            setSelectedSongAuthor(newSavedSongsDetails[index].selectedSongAuthor)
            setSelectedSongComposer(newSavedSongsDetails[index].selectedSongComposer)
        } else {
            toastError('Please save the song details first.')
        }
    }

    const deleteSavedSongDetails = (index) => {
        let newSavedSongsDetails = [...savedSongsDetails]
        newSavedSongsDetails.splice(index, 1)
        setSavedSongsDetails(newSavedSongsDetails)
    }

    const validOnSaveSongDetails = () => {
        const { title, p_line,
            production_year, genre, subgenre, price_tier, track_title_language, lyrics_language, destribute_music,
            download_video_link } = songInfo
        if (!title || selectedSongPrimaryArtist.length === 0 || selectedSongAuthor.length === 0 || selectedSongComposer.length === 0
            || !p_line || !production_year || !genre || !subgenre || !price_tier || !track_title_language || !lyrics_language
        ) {
            return toastError('Please fill all required fields')
        }
        if (destribute_music === 'Yes' && !download_video_link) {
            return toastError('Link to music video is required.')
        }
        setOpenSongDetail(false)
        setShowSubmitSong(true)
    }

    return (
        <>{initialLoader ? <Grid container style={{ height: "100vh" }}></Grid> : awayData.status ? <Grid container style={{ height: "calc(90vh - 64px)" }}>
            <Grid item xs={12} sm={10} md={7} lg={6} style={{ margin: "auto" }}>
                <div className="server-maintenance">
                    <p>
                        {awayData.text}
                    </p>
                </div>
            </Grid>
        </Grid> : isUserRevoked ? <Grid container style={{ height: "calc(90vh - 64px)" }}>
            <Grid item xs={12} sm={10} md={7} lg={5} style={{ margin: "auto" }}>
                <div className="revoked-user-container">
                    <div style={{ width: "100%", display: "flex" }}>
                        <img height="200px" src={noaccess} alt="" style={{ margin: "auto" }} />
                    </div>
                    <p style={{ fontSize: 20, fontWeight: 500, textAlign: "center" }}>You cannot create a release due to the violation of Fronic Media distribution terms. To get back the access, please contact support@fronicmedia.com.</p>
                    <p style={{ float: "right", marginTop: 15 }}>-Team Fronic media.</p>
                </div>
            </Grid>
        </Grid> : <Grid container className="create-release-container">
            <Grid item xs={12} className="create-release-container p-0">
                <Box>
                    <Tabs value={tabIndex} onChange={handleTabChange}>
                        <Tab
                            label={
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                    <p>Release Info</p>
                                    <span style={{ marginTop: 3, color: "#2ac12a" }}>{stepsDone.first ? <CheckCircleIcon /> : null}</span>
                                </div>
                            }
                            value={1}
                        />
                        <Tab
                            label={
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                    <p>Song Info</p>
                                    <span style={{ marginTop: 3, color: "#2ac12a" }}>{stepsDone.second ? <CheckCircleIcon /> : null}</span>
                                </div>
                            }
                            value={2}
                            disabled={disableTab.second}
                        />
                        <Tab
                            label={
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                    <p>Platform</p>
                                    <span style={{ marginTop: 3, color: "#2ac12a" }}>{stepsDone.third ? <CheckCircleIcon /> : null}</span>
                                </div>
                            }
                            value={3}
                            disabled={disableTab.third}
                        />
                        <Tab
                            label="Submission"
                            value={4}
                            disabled={disableTab.fourth}
                        />
                    </Tabs>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <TabPanel value={tabIndex} index={1}>
                    <ReleaseInfo
                        addArtist={addArtist}
                        editMode={editMode}
                        releaseInfo={releaseInfo}
                        setAddArtist={setAddArtist}
                        primaryArtist={primaryArtist}
                        onFileHandler={onFileHandler}
                        setReleaseInfo={setReleaseInfo}
                        onChangeSelect={onChangeSelect}
                        onChangeHandler={onChangeHandler}
                        featuringArtist={featuringArtist}
                        createReleaseInfo={() => setConfirmOpen(true)}
                        recipeFilePreview={recipeFilePreview}
                        setOpenAddPrimary={setOpenAddPrimary}
                        setOpenAddFeaturing={setOpenAddFeaturing}
                        selectedPrimaryArtist={selectedPrimaryArtist}
                        removeSelectedPrimary={removeSelectedPrimary}
                        selectedFeaturingArtist={selectedFeaturingArtist}
                    />
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                    <SongInfo
                        isSingleTrack={releaseInfo.release_type === 'single'}
                        songInfo={songInfo}
                        showUpload={showUpload}
                        isProgressDone={isProgressDone}
                        addNewTrack={addNewTrack}
                        songInfoMusicLink={songInfoMusicLink}
                        deleteSavedSongDetails={deleteSavedSongDetails}
                        onSaveSongDetails={onSaveSongDetails}
                        onClickSongEdit={onClickSongEdit}
                        uploadProgress={uploadProgress}
                        showSubmitSong={showSubmitSong}
                        setCurrentEditIndex={setCurrentEditIndex}
                        savedSongsDetails={savedSongsDetails}
                        setOpenSongDetail={setOpenSongDetail}
                        onSongFileHandler={onSongFileHandler}
                        recipeFilePreview={recipeFilePreview}
                    />
                </TabPanel>
                <TabPanel value={tabIndex} index={3}>
                    <Platform
                        editMode={editMode}
                        releaseID={releaseID}
                        platformLogo={platformLogo}
                        setStepsDone={setStepsDone}
                        platform={platform}
                        setPlatform={setPlatform}
                        stepsDone={stepsDone}
                        handleTabChange={handleTabChange}
                        setLoader={props.setLoader}
                        setDisableTab={setDisableTab}
                        disableTab={disableTab}
                    />
                </TabPanel>
                <TabPanel value={tabIndex} index={4}>
                    <Submission
                        setLoader={props.setLoader}
                        releaseID={releaseID}
                    />
                </TabPanel>
            </Grid>
            <CustomAddModal
                open={openAddPrimary}
                maxWidth="xs"
                title={`Add Primary Artist`}
                onSubmitConfirm={onSubmitAddArtist}
                handleClose={() => { setAddArtist({ ...initialArtist }); setOpenAddPrimary(false) }}
            >
                <AddArtist
                    type="primary"
                    onChange={onChangeArtist}
                    addArtist={addArtist}
                />
            </CustomAddModal>

            <CustomAddModal
                maxWidth="xs"
                open={openAddFeaturing}
                title={`Add Featuring Artist`}
                onSubmitConfirm={onSubmitAddArtist}
                handleClose={() => { setAddArtist({ ...initialArtist }); setOpenAddFeaturing(false) }}
            >
                <AddArtist
                    type="featuring"
                    onChange={onChangeArtist}
                    addArtist={addArtist}
                />
            </CustomAddModal>

            <ConfirmModal
                message={`Are you sure to proceed?`}
                open={confirmOpen}
                title={`Confirmation`}
                onSubmitConfirm={createReleaseInfo}
                handleClose={() => setConfirmOpen(false)}
            />

            <CustomAddModal
                open={openSongDetail}
                title={`Add Details`}
                saveLabel="Save"
                onSubmitConfirm={validOnSaveSongDetails}
                handleClose={() => setOpenSongDetail(false)}
            >
                <SongDetailForm
                    songInfo={songInfo}
                    releaseInfo={releaseInfo}
                    setSongInfo={setSongInfo}
                    addArtist={addArtist}

                    featuringArtist={featuringArtist}
                    setAddArtist={setAddArtist}
                    openAddPrimary={openAddPrimary}
                    setOpenAddPrimary={setOpenAddPrimary}
                    setOpenAddFeaturing={setOpenAddFeaturing}
                    primaryArtist={primaryArtist}
                    selectedSongPrimaryArtist={selectedSongPrimaryArtist}
                    setSelectedSongPrimaryArtist={setSelectedSongPrimaryArtist}
                    selectedSongfeaturingArtist={selectedSongfeaturingArtist}
                    setSelectedSongfeaturingArtist={setSelectedSongfeaturingArtist}
                    selectedSongAuthor={selectedSongAuthor}
                    setSelectedSongAuthor={setSelectedSongAuthor}
                    selectedSongComposer={selectedSongComposer}
                    setSelectedSongComposer={setSelectedSongComposer}
                />
            </CustomAddModal>

        </Grid >}
        </>
    )
}