import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { toastError, toastSuccess, validateEmail } from "../../../utils"
import { loginBGimages } from '../../../constants';
import * as actions from "../actions"
import Signup from "./Signup"
import OtpVerification from './OtpVerification';
import axios from "axios"
import SnowStorm from 'react-snowstorm';
import queryString from 'query-string';
import { useLocation } from "react-router-dom"

export default function SignupMain() {

    const location = useLocation();

    const [showPassword, setShowPassword] = useState({
        first: false,
        second: false
    })
    const [loginLoader, setLoginLoader] = useState(false)
    const [user, setUser] = useState({
        name: "",
        email: "",
        phone: "",
        password: "",
        address: "",
        ip: ""
    })
    const [showImgNum, setShowImgNum] = useState(Math.floor(Math.random() * loginBGimages.length))
    const [errors, setErrors] = useState({})
    const [showOtp, setShowOtp] = useState(false)
    const [refCustomer, setRefCustomer] = useState('')
    const [ipAddress, setIpAddress] = useState('')

    useEffect(() => {
        const config = {
            method: 'get',
            url: 'https://api.ipify.org/',
            headers: {}
        };

        axios(config)
            .then(function (response) {
                // setUser({ ...user, ip: response.data })
                setIpAddress(response.data)
            }).catch(() => {

            })
        const parsed = queryString.parse(location.search);
        if (parsed.name) {
            setRefCustomer(parsed.name)
        }
        return () => {
            setRefCustomer(null)
        }
    }, [])

    useEffect(() => {
        setUser({ ...user, name: refCustomer })
    }, [refCustomer])

    useEffect(() => {
        setUser({ ...user, ip: ipAddress })
    }, [ipAddress])

    const validate = (user) => {
        let isValid = true
        let errors = {}
        if (!user.name) {
            isValid = false
            errors.name = "Name is Required"
            toastError("Name is Required")
        }
        else if (!user.email) {
            isValid = false
            errors.email = "Email is Required"
            toastError("Email is Required")
        }
        else if (user.email && !validateEmail(user.email)) {
            isValid = false
            errors.email = "Email is Invalid"
            toastError("Email is Invalid")
        }
        else if (!user.phone) {
            isValid = false
            errors.phone = "Phone number is Required"
            toastError("Phone number is Required")
        }
        else if (user.phone.length != 10) {
            isValid = false
            errors.phone = "Phone number is invalid"
            toastError("Phone number is invalid")
        }
        else if (!user.address) {
            isValid = false
            errors.address = "Address is Required"
            toastError("Address is Required")
        }
        else if (!user.password) {
            isValid = false
            errors.password = "Password is Required"
            toastError("Password is Required")
        }
        else if (user.password && user.password.length < 6) {
            isValid = false
            errors.password = "Password must be of 6 characters"
            toastError("Password must be of 6 characters")
        }
        return { isValid, errors }
    }

    const onChangeHandler = (key, { target: { value = "" } }) => {
        setUser({ ...user, [key]: value })
        setErrors({ ...errors, [key]: '' })
    }

    const onSignup = () => {
        const { isValid, errors } = validate(user)
        setErrors(errors)
        if (!isValid) return
        setLoginLoader(true)
        actions.signup(user).then(res => {
            setLoginLoader(false)
            toastSuccess(res.message, 5000)
            setShowOtp(true)
        }).catch(err => {
            setLoginLoader(false)
            toastError(err.message)
        })
    }

    return (
        <>
            {refCustomer ? <SnowStorm animationInterval={5} snowStick={false} /> : null}
            <Grid container className="login-container">
                <img src={loginBGimages[showImgNum]} alt="" className="login-background-image" />
                <Grid item xs={12} className="login-container-grid">
                    {showOtp ? <OtpVerification
                        user={user}
                    /> : <><Signup
                        user={user}
                        refCustomer={refCustomer}
                        errors={errors}
                        onSignup={onSignup}
                        loginLoader={loginLoader}
                        showPassword={showPassword}
                        onChangeHandler={onChangeHandler}
                        setShowPassword={setShowPassword}
                    /></>
                    }
                </Grid>
            </Grid>
        </>
    )
}
