import React, { useEffect, useState } from 'react'
import { Grid, Box, Paper, Table, TableHead, TableRow, TableCell, TableContainer, TableBody, Button } from "@material-ui/core"
import * as actions from "./actions"
import { useParams } from "react-router-dom"
import { toastError } from '../../utils';
import CheckIcon from '@material-ui/icons/Check';
import { API_URL } from '../../constants';
import moment from "moment";
import CancelIcon from '@material-ui/icons/Cancel';
import { useHistory } from "react-router-dom"

function ViewRelease(props) {

    const params = useParams();
    const history = useHistory();
    const [catalog, setCatalog] = useState({})

    useEffect(() => {
        if (params.id) {
            props.setLoader(true)
            actions.getCatelogById(params.id).then(res => {
                props.setLoader(false)
                setCatalog(res.body)
            }).catch(err => {
                props.setLoader(false)
                toastError(err.message)
            })
        }
    }, [])

    const {
        album_artwork = "",
        audios = [],
        status = 0,

        lable_name = "",
        primary_artist1 = "",
        release_title = "",
        genre = "",

        release_id = '',
        reason = '',
        number_of_tracks = 0,
        release_date = "",
        crbt = "",
        video_platforms = "",

    } = catalog || {}

    return (
        <Grid container className="view-release-container">

            <Grid item xs={12}>
                {!status ? null : <Box p={2} className="status-box" style={{ background: status === 'in_review' ? "rgb(222 214 42)" : status === 'approved' ? "#a5e4ad" : (status === 'rejected' || status === 'takendown') ? 'red' : '' }}>
                    <p style={{ color: (status === 'rejected' || status === 'takendown') ? 'white' : '' }}>{status === 'in_review' ? <span><CheckIcon /> In-review</span> : status === 'approved' ? <span><CheckIcon /> Approved</span> : (status === 'rejected' || status === 'takendown') ? <span><CancelIcon />{status === 'rejected' ? 'Rejected' : 'Takendown'}</span> : null}</p>
                </Box>}
            </Grid>

            {(status === 'rejected') ? <Grid item xs={12}>
                <Box mt={2}>
                    <Paper>
                        <Box p={2}>
                            <h3 style={{ color: 'red' }}>{status === 'rejected' ? 'Rejected Reason:' : 'Takendown'}</h3>
                            {status === 'rejected' ? <p style={{ fontWeight: 500 }}>{reason}</p> : null}
                        </Box>
                    </Paper>
                </Box>
                <Box>
                    <Button className="edit-re-submit" onClick={() => history.push(`/create_releases/${release_id}`)}>Edit & re-submit</Button>
                </Box>
            </Grid> : null}
            <Grid item xs={12}>
                <Paper>
                    <Box mt={4} mb={2} pt={4} pb={4}>
                        <Grid container className="catalog-image">
                            <Grid item xs={12} sm={6} md={4}>
                                <div className="image-div">
                                    <img src={`${API_URL}/${album_artwork}`} alt="" />
                                </div>
                            </Grid>

                            {/* first section */}
                            <Grid item xs={12} sm={12} md={4}>
                                <Box pl={2} mt={2}>
                                    <Grid container className="item-container">
                                        <Grid item xs={5}>
                                            <label>Label:</label>
                                        </Grid>

                                        <Grid item xs={7}>
                                            <p>{lable_name}</p>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box pl={2} mt={2}>
                                    <Grid container className="item-container">
                                        <Grid item xs={5}>
                                            <label>Primary Artist:</label>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <p>{primary_artist1}</p>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box pl={2} mt={2}>
                                    <Grid container className="item-container">
                                        <Grid item xs={5}>
                                            <label>Release Title:</label>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <p>{release_title}</p>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box pl={2} mt={2}>
                                    <Grid container className="item-container">
                                        <Grid item xs={5}>
                                            <label>Genre:</label>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <p>{genre}</p>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box pl={2} mt={2}>
                                    <Grid container className="item-container">
                                        <Grid item xs={5}>
                                            <label>Explicit Version:</label>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <p>{Array.isArray(audios) && audios.length ? audios[0].explicit_version : '-' || '-'}</p>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            {/* second section */}
                            <Grid item xs={12} sm={6} md={4}>
                                <Box pl={2} mt={2}>
                                    <Grid container className="item-container">
                                        <Grid item xs={5}>
                                            <label>Number of Tracks:</label>
                                        </Grid>

                                        <Grid item xs={7}>
                                            <p>{number_of_tracks}</p>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box pl={2} mt={2}>
                                    <Grid container className="item-container">
                                        <Grid item xs={5}>
                                            <label>Release Date:</label>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <p>{moment(Number(release_date)).format('DD/MM/yyyy')}</p>
                                        </Grid>
                                    </Grid>
                                </Box>
                                {/* <Box pl={2} mt={2}>
                                    <Grid container className="item-container">
                                        <Grid item xs={5}>
                                            <label>CRBT:</label>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <p>{crbt}</p>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box pl={2} mt={2}>
                                    <Grid container className="item-container">
                                        <Grid item xs={5}>
                                            <label>Video Platforms:</label>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <p>{video_platforms}</p>
                                        </Grid>
                                    </Grid>
                                </Box> */}
                                <Box pl={2} mt={2}>
                                    <Grid container className="item-container">
                                        <Grid item xs={5}>
                                            <label>Price Tier:</label>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <p>{Array.isArray(audios) && audios.length ? audios[0].price_tier : '-' || '-'}</p>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <Box pt={2} pb={2}>
                    <h3>Tracks</h3>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table className="universal-table">
                        <TableHead>
                            <TableRow>
                                <TableCell>No.</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Artist</TableCell>
                                <TableCell>Genre</TableCell>
                                <TableCell>Product Number</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                audios && audios.length ? audios.map((item, key) => {
                                    return <TableRow key={key}>
                                        <TableCell>{key + 1}</TableCell>
                                        <TableCell>{item.title}</TableCell>
                                        <TableCell>{item.primary_artist1}</TableCell>
                                        <TableCell>{item.genre}</TableCell>
                                        <TableCell>{`FM-AU-21${item.audio_id}`}</TableCell>
                                        <TableCell>{(item.status !== 2 && status === 'rejected') ? <span>On-hold</span> : item.status === 2 ? <span style={{ color: "red" }}>Rejected</span> : item.status === 1 ? <span style={{ color: "green" }}>Approved</span> : item.status === 3 ? <span style={{ color: "red" }}>Takendown</span> : <span>In-Review</span>}</TableCell>
                                    </TableRow>
                                }) : null
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

        </Grid>
    )
}

export default ViewRelease
