import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import audioReleaseImg from "../../assets/img/audio_release.jpg";
import videoReleaseImg from "../../assets/img/video_release.jpg";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import * as actions from "../Releases/CreateReleases/actions";
import { toastError, toastSuccess } from "../../utils";
import { CircularProgress } from "@material-ui/core";
import noaccess from "../../assets/img/noaccess.png";

function ReleaseCheck(props) {
  const history = useHistory();
  const [awayData, setAwayData] = useState({
    status: false,
    text: "",
  });
  const [isUserRevoked, setIsUserRevoked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    props.setLoader(true);
    actions.checkAwayStatus().then((res) => {
      const isAway =
        (res &&
          res.body &&
          res.body.status &&
          res.body.status === "yes" &&
          true) ||
        false;
      const awayText =
        (res && res.body && res.body.text && res.body.text) || "";
      if (isAway) {
        setAwayData({
          status: true,
          text: awayText,
        });
        props.setLoader(false);
        setIsLoading(false);
      } else {
        actions
          .checkUserRevokeStatus()
          .then((res) => {
            if (res.revoked === 1) {
              setIsUserRevoked(true);
            }
          })
          .catch((err) => {
            toastError(err.message);
          })
          .finally(() => {
            props.setLoader(false);
            setIsLoading(false);
          });
      }
    });
  }, []);

  return (
    <>
      {awayData.status && !isLoading && (
        <Grid container style={{ height: "calc(90vh - 64px)" }}>
          <Grid item xs={12} sm={10} md={7} lg={6} style={{ margin: "auto" }}>
            <div className="server-maintenance">
              <p>{awayData.text}</p>
            </div>
          </Grid>
        </Grid>
      )}
      {isUserRevoked && !isLoading && (
        <Grid container style={{ height: "calc(90vh - 64px)" }}>
          <Grid item xs={12} sm={10} md={7} lg={5} style={{ margin: "auto" }}>
            <div className="revoked-user-container">
              <div style={{ width: "100%", display: "flex" }}>
                <img
                  height="200px"
                  src={noaccess}
                  alt=""
                  style={{ margin: "auto" }}
                />
              </div>
              <p style={{ fontSize: 20, fontWeight: 500, textAlign: "center" }}>
                You cannot create a release due to the violation of Fronic Media
                distribution terms. To get back the access, please contact
                support@fronicmedia.com.
              </p>
              <p style={{ float: "right", marginTop: 15 }}>
                -Team Fronic media.
              </p>
            </div>
          </Grid>
        </Grid>
      )}
      {!awayData.status && !isUserRevoked && !isLoading && (
        <>
          <p style={{ fontSize: "20px", textAlign: "center" }}>
            Welcome to Create Release feature. Select the type of release you
            want to create.
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10vh",
            }}
          >
            <Card
              sx={{ maxWidth: 330, marginRight: "15%" }}
              onClick={() => history.push("/create_releases")}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="250"
                  image={audioReleaseImg}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Create Audio Release
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Distribute your music content as Single / EP / Album or
                    Compilation to music platforms across the globe.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card
              sx={{ maxWidth: 330 }}
              onClick={() => history.push("/create_video_releases")}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="250"
                  image={videoReleaseImg}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Create Video Release
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Distribute your music videos to music platforms across the
                    globe and make your presence.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
        </>
      )}
    </>
  );
}

export default ReleaseCheck;
