import {
    loginAPI,
    signupAPI,
    verifyEmailOTPAPI,
    resendEmailOTPAPI,
    forgetPasswordAPI,
    VerifyForgetPasswordOTPAPI,
    ChangeUserPasswordAPI
} from "./api"

export function login(data) {
    return new Promise((resolve, reject) => {
        loginAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function signup(data) {
    return new Promise((resolve, reject) => {
        signupAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function verifyEmailOTP(data) {
    return new Promise((resolve, reject) => {
        verifyEmailOTPAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function resendEmailOTP(data) {
    return new Promise((resolve, reject) => {
        resendEmailOTPAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function forgetPassword(data) {
    return new Promise((resolve, reject) => {
        forgetPasswordAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function VerifyForgetPasswordOTP(data) {
    return new Promise((resolve, reject) => {
        VerifyForgetPasswordOTPAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function ChangeUserPassword(data, headers) {
    return new Promise((resolve, reject) => {
        ChangeUserPasswordAPI(data, headers).then((res) => resolve(res)).catch(err => reject(err))
    })
}