import React, { useState, useEffect } from 'react'
import { Grid, Box, Checkbox, Button, withStyles, CircularProgress } from "@material-ui/core"
import SaveIcon from '@material-ui/icons/Save';
import Loader from 'react-loader-advanced';
import * as actions from "../../../containers/Releases/CreateReleases/actions";
import { toastError, toastSuccess } from '../../../utils';
import { Link } from 'react-router-dom';

const StyledButton = withStyles({
    root: {
        fontFamily: "'DM Sans', sans-serif",
        background: '#2d2d2d',
        fontSize: 14,
        height: 35,
        width: 150,
        borderColor: "#0597b2",
        margin: "auto",
        color: "white",
        '&:hover': {
            color: "black",
            border: '1px solid black'
        }
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

function Platform(props) {

    const { platformLogo = [], setStepsDone, setDisableTab, stepsDone, disableTab, releaseID, handleTabChange, setPlatform, platform } = props;
    const [crbt, setCrbt] = useState(false)
    const [video, setVideo] = useState(false)

    const [loader, setLoader] = useState(true)
    const [platfromVerify, setPlatfromVerify] = useState({
        caller_tune: false,
        destribute_music: false
    })

    useEffect(() => {
        setPlatformVerification()
    }, [])

    const setPlatformVerification = () => {
        actions.getSubmissionDataChecks(releaseID).then(res => {
            let obj = { ...platfromVerify }
            if (res.body && res.body.caller_tune && res.body.caller_tune === 'Yes') {
                obj.caller_tune = true
            }
            if (res.body && res.body.destribute_music && res.body.destribute_music === 'Yes') {
                obj.destribute_music = true
            }
            setPlatfromVerify(obj)
            setTimeout(() => {
                setLoader(false)
            }, [2000])
            setPlatform({ crbt: obj.caller_tune ? 'yes' : 'no', video_platforms: obj.destribute_music ? 'yes' : 'no' })
        }).catch((err) => {
            toastError(err.message)
        })
    }

    const onSubmit = () => {
        props.setLoader(true)
        actions.checkboxCrbtVideoPlatforms(platform).then(res => {
            props.setLoader(false)
            toastSuccess(res.message)
            setStepsDone({ ...stepsDone, third: true })
            setDisableTab({ ...disableTab, fourth: false })
            handleTabChange({}, 4)
        }).catch(err => {
            props.setLoader(false)
            toastError(err.message)
        })
    }

    let audioLogo = []
    let crbtLogo = []
    let videoPlatformLogo = []

    platformLogo.forEach((item) => {
        if (item.type === '1') {
            audioLogo.push({ ...item, checked: true })
        } else if (item.type === '2') {
            crbtLogo.push({ ...item, checked: platform.crbt === 'yes' })
        } else {
            videoPlatformLogo.push({ ...item, checked: platform.video_platforms === 'yes' })
        }
    })

    const handleChange = ({ target: { name = '', checked } }) => {
        if (name === 'crbt') {
            if (!platfromVerify.caller_tune) {
                return toastError('Please set caller tune first.')
            }
            setPlatform({ ...platform, crbt: checked ? 'yes' : 'no' })
        } else {
            if (!platfromVerify.destribute_music) {
                return toastError('Provide Music Video details first')
            }
            setPlatform({ ...platform, video_platforms: checked ? 'yes' : 'no' })

        }
    }

    const LoaderStyle = {
        height: "100%",
        overflow: "hidden",
    }

    return (
        <Loader className="platform-loader" style={loader ? LoaderStyle : null} message={<CircularProgress size={50} style={{ color: "black" }} />} show={loader}>
            <Grid container className="platform-container">

                <Grid item xs={12}>
                    <Box>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={3}>
                                <Box p={1} display="flex">
                                    <h1>Audio (190+) </h1>
                                    <Checkbox
                                        checked={true}
                                        size="medium"
                                        disabled
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={9}>
                                <Box p={2}>
                                    <p style={{ fontSize: 15, fontWeight: 500 }}>For delivering content to only certain stores/services, please create a <Link to="/tickets" target="_blank">ticket.</Link></p>
                                </Box>
                            </Grid>
                            {
                                audioLogo.map((item, key) => {
                                    return <Grid key={key} item xs={12} sm={6} md={4}>
                                        <Box pt={1} pb={1} display="flex" alignItems="center">
                                            <img src={item.logo} height="40px" alt="" />
                                            <Checkbox
                                                checked={item.checked}
                                                size="small"
                                                disabled
                                            />
                                            <h4>{item.name}</h4>
                                        </Box>
                                    </Grid>
                                })
                            }
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box mt={5}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box p={1} display="flex">
                                    <h1>CRBT (4+)</h1>
                                    <Checkbox
                                        checked={platform.crbt === 'yes'}
                                        size="medium"
                                        name="crbt"
                                        onChange={handleChange}
                                    />
                                </Box>
                            </Grid>
                            {
                                crbtLogo.map((item, key) => {
                                    return <Grid key={key} item xs={12} sm={6} md={4}>
                                        <Box pt={1} pb={1} display="flex" alignItems="center">
                                            <img src={item.logo} height="40px" alt="" />
                                            <Checkbox
                                                checked={item.checked}
                                                size="small"
                                                name="crbt"
                                                onChange={handleChange}
                                            />
                                            <h4>{item.name}</h4>
                                        </Box>
                                    </Grid>
                                })
                            }
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box mt={5}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box p={1} display="flex">
                                    <h1>Video Platform (8+)</h1>
                                    <Checkbox
                                        checked={platform.video_platforms === 'yes'}
                                        size="medium"
                                        name="video"
                                        onChange={handleChange}
                                    />
                                </Box>
                            </Grid>
                            {
                                videoPlatformLogo.map((item, key) => {
                                    return <Grid key={key} item xs={12} sm={6} md={4}>
                                        <Box pt={1} pb={1} display="flex" alignItems="center">
                                            <img src={item.logo} height="40px" alt="" />
                                            <Checkbox
                                                checked={item.checked}
                                                size="small"
                                                name="video"
                                                onChange={handleChange}
                                            />
                                            <h4>{item.name}</h4>
                                        </Box>
                                    </Grid>
                                })
                            }
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box mt={8} mb={10} display="flex" justifyContent="center">
                        <StyledButton variant="contained" onClick={onSubmit}><SaveIcon style={{ marginRight: 8, fontSize: 17 }} />Save</StyledButton>
                    </Box>
                </Grid>


            </Grid>
        </Loader>
    )
}

export default Platform
