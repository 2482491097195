import React, { useEffect, useState } from 'react'
import { Grid } from "@material-ui/core"
import logo from "../../../assets/img/Fronic_Logo_white.png"

import { useHistory } from "react-router-dom"
import * as actions from "../actions"
import { removeObject, saveObject, toastError, toastSuccess, validateEmail } from '../../../utils';
import { loginBGimages } from '../../../constants';
import Login from './Login';
import ForgotPassword from './ForgotPassword'
import OtpVerification from '../Signup/OtpVerification'
import ResetPassword from './ResetPassword'



export default function LoginMain() {

    const history = useHistory();


    const [user, setUser] = useState({
        email: "",
        password: ""
    });
    const [errors, setErrors] = useState({})
    const [loginLoader, setLoginLoader] = useState(false)
    const [showImgNum, setShowImgNum] = useState(Math.floor(Math.random() * loginBGimages.length))
    const [showforgot, setShowforgot] = useState(false)
    const [showVerify, setShowVerify] = useState(false)
    const [showReset, setShowReset] = useState(false)

    const onChangeHandler = (key, { target: { value = "" } }) => {
        setUser({ ...user, [key]: value })
        setErrors({ ...errors, [key]: '' })
    }

    const validate = (user) => {
        let isValid = true
        let errors = {}
        if (!user.email) {
            isValid = false
            errors.email = "Email is Required"
            toastError("Email is Required")
        }
        else if (user.email && !validateEmail(user.email)) {
            isValid = false
            errors.email = "Email is Invalid"
            toastError("Email is Invalid")
        }
        else if (!user.password) {
            isValid = false
            errors.password = "Password is Required"
            toastError("Password is Required")
        }
        return { isValid, errors }
    }

    const onLoginIn = () => {
        const { isValid, errors } = validate(user)
        setErrors(errors)
        if (!isValid) return
        setLoginLoader(true)
        actions.login(user).then(res => {
            setLoginLoader(false)
            toastSuccess(res.message)
            saveObject('auth', res.token)
            return history.push("/")
        }).catch(err => {
            setLoginLoader(false)
            toastError(err.message)
        })
    }

    const onSendForgotOTP = () => {
        setLoginLoader(true)
        actions.forgetPassword({ email: user.email }).then(res => {
            toastSuccess(res.message, 4000)
            setLoginLoader(false)
            setShowforgot(false)
            setShowVerify(true)
        }).catch(err => {
            setLoginLoader(false)
            toastError(err.message)
        })
    }

    return (
        <Grid container className="login-container">
            <img src={loginBGimages[showImgNum]} alt="" className="login-background-image" />
            <Grid item xs={12} className="login-container-grid">

                {showforgot ? <ForgotPassword
                    user={user}
                    errors={errors}
                    loginLoader={loginLoader}
                    setShowforgot={setShowforgot}
                    onSendForgotOTP={onSendForgotOTP}
                    onChangeHandler={onChangeHandler}
                /> : showVerify ? <OtpVerification
                    user={user}
                    setShowReset={setShowReset}
                    setShowVerify={setShowVerify}
                    forgot
                /> : showReset ? <ResetPassword setShowReset={setShowReset} /> : <Login
                    user={user}
                    errors={errors}
                    onLoginIn={onLoginIn}
                    loginLoader={loginLoader}
                    setShowforgot={setShowforgot}
                    onChangeHandler={onChangeHandler}
                />}

            </Grid>
        </Grid>
    )
}
