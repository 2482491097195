import React, { useEffect, useState } from 'react'
import { Grid, Box, Paper, CircularProgress, Button } from "@material-ui/core"
import { useParams } from "react-router-dom"
import * as actions from "./actions"
import { toastError } from '../../utils';
import moment from 'moment';
import SendIcon from '@material-ui/icons/Send';

function ViewTicket(props) {
    const params = useParams();

    const [ticket, setTicket] = useState({})
    const [sendReply, setSendReply] = useState({
        ticket_id: params.id,
        reply: '',
        attachement: "",
        sent_by: "user"
    })
    const [sendLoader, setSendLoader] = useState(false)

    useEffect(() => {
        if (params.id) {
            fetchTicketsById()
        }
    }, [])

    const fetchTicketsById = () => {
        props.setLoader(true)
        actions.getTicketById(params.id).then(res => {
            props.setLoader(false)
            setTicket({ ...res.body, attachement: (res.body.attachement && res.body.attachement.length) ? res.body.attachement.split(',') : [res.body.attachement] })
        }).catch(err => {
            props.setLoader(false)
            toastError(err.message)
        })
    }

    const onSubmitReply = () => {
        if (!sendReply.reply) return toastError('Please enter a reply first.')
        setSendLoader(true)
        actions.replyTicket(sendReply).then(res => {
            setSendLoader(false)
            setSendReply({ ...sendReply, reply: "" })
            fetchTicketsById()
        }).catch(err => {
            setSendLoader(false)
            toastError(err.message)
        })
    }

    // const keyPressed = e => {
    //     if (e.key === "Enter") {
    //         onSubmitReply()
    //     }
    // }

    const keyPressed = e => {
        if (e.key === 'Enter' && e.shiftKey) {
            return
        }
        if (e.key === "Enter") {
            e.preventDefault();
            onSubmitReply()
        }
    }

    const leftStyle = {
        padding: '8px 11px',
        borderRadius: 6,
        clear: 'both',
        float: 'left',
        width: "fit-content",
        fontSize: 15,
        background: 'rgba(18, 180, 161, 1)',
        color: 'white',
        marginBottom: 26,
        maxWidth: 800,
        position: 'relative',
    }

    const rightStyle = {
        padding: '8px 11px',
        borderRadius: 5,
        clear: 'both',
        float: 'right',
        width: "fit-content",
        fontSize: 15,
        color: 'white',
        background: '#2d2d2d',
        marginBottom: 26,
        maxWidth: 800,
        position: 'relative'
    }

    const { reason = '', date = '', reply = '', attachement = [], sent_by = '', status, replies = [], created_at = '' } = ticket || {}

    return (
        <Grid className="view-tickets-container">
            <Grid item xs={12}>
                <Paper>
                    <Box mt={2} mb={2}>
                        <Grid container style={{ background: '#2d2d2d', color: 'white', borderRadius: 4 }}>

                            <Grid item xs={12} sm={6} md={4}>
                                <Box p={2} className="box-div">
                                    <label>Reason</label>
                                    <p>{reason}</p>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Box p={2} className="box-div">
                                    <label>Date</label>
                                    <p>{moment(date).format('DD/MM/yyyy')}</p>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Box p={2} className="box-div">
                                    <label>Status</label>
                                    <p>{status ? 'Done' : 'Pending'}</p>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Box mt={2} mb={2}>
                    <Paper>
                        <Box p={2} display="flex" justifyContent="space-between" className="box-div" style={{ background: 'rgba(18, 180, 161, 1)', color: 'white', borderRadius: 4 }}>
                            <div>
                            <label>Your Concern:</label>
                            <p>{reply}</p>
                            </div>
                            <div style={{ minWidth: "150px"}}>
                                <label>Created At</label>
                                <p>{moment(created_at).format('DD/MM/yyyy hh:mm A')}</p>
                            </div>
                        </Box>
                    </Paper>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Paper>
                    <Box p={2}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box mt={2} mb={2}>
                                    {
                                        replies && replies.length ? replies.map((item, key) => {
                                            return <div style={item.sent_by === sent_by ? rightStyle : leftStyle}>
                                                <p style={{ fontWeight: 600, marginBottom: 6 }}>{item.sent_by === sent_by ? "Your Reply:" : "Support Team:"}</p>
                                                {/* <span style={{ display: "flex", flexWrap: "wrap" }}>{
                                                    item.reply.split(" ").map(replySplit => {
                                                        if(replySplit.includes("http")) {
                                                            return <a style={{color: "blue"}} href={replySplit} target="_blank" rel="noreferrer" >{replySplit}&nbsp;</a>
                                                        }
                                                        return <span>{replySplit}&nbsp;</span>
                                                    })
                                                    }</span> */}
                                                    <span style={{ whiteSpace: 'pre-wrap' }}>{item.reply}</span>
                                                <span className={`ticket-reply-created ${item.sent_by === sent_by ? 'right' : 'left'}`}>{moment(item.created_at).format('DD/MM/yyyy hh:mm A')}</span>
                                            </div>
                                        }) : null
                                    }
                                </Box>
                            </Grid>
                            {status === 1 ? null : <Grid item xs={12}>
                                < Box md={2} display="flex">
                                    <textarea
                                        type="text"
                                        placeholder="Type your reply..."
                                        value={sendReply.reply}
                                        onKeyPress={keyPressed}
                                        onChange={(e) => setSendReply({ ...sendReply, reply: e.target.value })}
                                        className="reply-ticket-input"
                                    />
                                    <div className="svg-div">
                                        <button onClick={() => onSubmitReply()} disabled={sendLoader}>{sendLoader ? <CircularProgress size={22} style={{ color: "white", marginTop: 3 }} /> : <SendIcon className="svg-send" />}</button>
                                    </div>
                                </Box>
                            </Grid>}
                        </Grid>
                    </Box>
                </Paper>
            </Grid >
        </Grid >
    )
}

export default ViewTicket
