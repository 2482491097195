import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
  Checkbox,
} from "@material-ui/core";
import axios from "axios";
import { withStyles, makeStyles, fade } from "@material-ui/core/styles";
import logo from "../../../assets/img/Fronic_Logo_white.png";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { toastError, toastSuccess } from "../../../utils";
import GlitchSquiggly from "react-glitch-effect/core/GlitchSquiggly";

const StyledButton = withStyles({
  root: {
    background:
      "linear-gradient(90deg, rgba(19,153,121,1) 0%, rgba(18,180,161,1) 45%, rgba(5,150,179,1) 100%)",
    fontSize: 18,
    height: 50,
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

const CssTextField = withStyles({
  root: {
    // fontFamily: "'DM Sans', sans-serif",
    "& label": {
      fontFamily: "'DM Sans', sans-serif",
      // color: '#10baa8',
      color: "white",
    },
    "& label.Mui-focused": {
      // color: '#10baa8',
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "red",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#10baa8",
      },
    },
  },
})(TextField);

const useStylesReddit = makeStyles((theme) => ({
  root: {
    fontFamily: "'DM Sans', sans-serif",
    border: "1px solid #e2e2e1",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "#fcfcfb",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}));

function Signup(props) {
  const history = useHistory();
  const classes = useStylesReddit();
  const [checked, setChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [isCapchaGood, setIsCapchaGood] = useState(false);

  //---capcha code starts--------------------------

  async function onChangeCapcha(value) {
    await axios
      .post(
        `https://backend.fronicmedia.com/api/capchaVerification?responseCapcha=${value}`
      )
      .then((res) => {
        if (res?.data?.body?.success === false) {
          setIsCapchaGood(false);
          toastError("Captcha expired. Try again ?");
        } else if (res?.data?.body?.success === true) {
          setIsCapchaGood(true);
          toastSuccess("Good to see you Human.");
        } else {
          toastError("Captcha verification failed.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //---code ends-----------------------------------

  const {
    onChangeHandler,
    user,
    errors,
    showPassword,
    setShowPassword,
    onSignup,
    loginLoader,
    refCustomer,
  } = props;

  return (
    <div className="login-div">
      <Grid container>
        {refCustomer ? (
          <Grid item xs={12}>
            <div className="welcome-by-ref">
              <GlitchSquiggly component="h1" color1="#FF8383">
                <p>
                  Welcome, <span>{refCustomer}</span>
                </p>
              </GlitchSquiggly>
              {/* <p></p> */}
            </div>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <div className="logo-image-div">
            <img src={logo} alt="" />
          </div>
        </Grid>

        <Grid item xs={12}>
          <Box pt={3} pb={1}>
            <CssTextField
              className={classes.margin}
              label="Name"
              variant="outlined"
              id="custom-css-outlined-input"
              value={user.name}
              error={!!errors.name}
              onChange={(e) => onChangeHandler("name", e)}
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <p
            style={{
              color: "white",
              fontSize: "11px",
              textAlign: "center",
              paddingBottom: 3,
            }}
          >
            Typing your full name in this box will act as your digital
            signature.
          </p>
        </Grid>
        <Grid item xs={12}>
          <Box pt={0.3} pb={1}>
            <CssTextField
              label="Email"
              variant="outlined"
              value={user.email}
              error={!!errors.email}
              autoComplete="off"
              onChange={(e) => onChangeHandler("email", e)}
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box pt={0.3} pb={1}>
            <CssTextField
              type="number"
              label="Phone"
              variant="outlined"
              value={user.phone}
              error={!!errors.phone}
              onChange={(e) => {
                if (e.target.value.toString().length > 10) {
                  return;
                }
                onChangeHandler("phone", e);
              }}
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box pt={0.3} pb={1}>
            <CssTextField
              label="Address"
              variant="outlined"
              value={user.address}
              error={!!errors.address}
              autoComplete="off"
              onChange={(e) => onChangeHandler("address", e)}
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box pt={0.3} pb={1}>
            <CssTextField
              type={showPassword.first ? "text" : "password"}
              label="Password"
              variant="outlined"
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ color: "white" }}
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          first: !showPassword.first,
                        })
                      }
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showPassword.first ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={user.password}
              error={!!errors.password}
              onChange={(e) => onChangeHandler("password", e)}
              fullWidth
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box pt={0.5} display="flex" alignItems="center">
            <Checkbox
              style={{ color: "white" }}
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
            <p style={{ color: "white", fontSize: 13 }}>
              I understand and agree to the Fronic Media{" "}
              <a
                className="a-tag-term-conditions"
                href="https://fronicmedia.com/distribution-agreement/"
                target="_blank"
                rel="noreferrer"
              >
                Distribution Terms
              </a>{" "}
              &{" "}
              <a
                className="a-tag-term-conditions"
                href="https://fronicmedia.com/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </p>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box pt={0.5} display="flex" alignItems="center">
            <Checkbox
              style={{ color: "white" }}
              checked={emailChecked}
              onChange={(e) => setEmailChecked(e.target.checked)}
            />
            <p style={{ color: "white", fontSize: 13 }}>
              I agree to receive information and offers relevant to your
              services via email.
            </p>
          </Box>
        </Grid>

        {checked && emailChecked && (
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <ReCAPTCHA
              sitekey="6LdUG_gjAAAAAEXj3qkc5X-jlW4Z4JOpqPiopmmm"
              onChange={onChangeCapcha}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Box pt={0.5}>
            <StyledButton
              variant="contained"
              color="primary"
              // style={{ height: 50 }}
              onClick={
                checked && emailChecked && isCapchaGood
                  ? onSignup
                  : () => {
                      !checked
                        ? toastError("Please read & accept terms")
                        : toastError(" Please accept email updates.");
                    }
              }
              style={
                checked && emailChecked && isCapchaGood
                  ? {}
                  : { background: "grey" }
              }
              fullWidth
            >
              {loginLoader ? (
                <CircularProgress size={25} style={{ color: "white" }} />
              ) : (
                "Signup"
              )}
            </StyledButton>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box pt={1} pb={1}>
            <p
              className="dont-have-account"
              onClick={() => history.push("/login")}
            >
              Already have an account? Login
            </p>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Signup;
