import {
    getTicketByIdAPI,
    getTicketListForUserAPI,
    createTicketAPI,
    replyTicketAPI
} from "./api"

export function getTicketById(data) {
    return new Promise((resolve, reject) => {
        getTicketByIdAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function getTicketListForUser(data) {
    return new Promise((resolve, reject) => {
        getTicketListForUserAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function createTicket(data) {
    return new Promise((resolve, reject) => {
        createTicketAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function replyTicket(data) {
    return new Promise((resolve, reject) => {
        replyTicketAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}