import React from 'react';
import ReactDOM from 'react-dom';
import App from './routes';
import { toast } from 'react-toastify';
import "./assets/styles/index.scss"
import 'react-toastify/dist/ReactToastify.css';

toast.configure({
    limit: 1,
    newestOnTop: true
});

ReactDOM.render(<App />, document.getElementById('root')
);
