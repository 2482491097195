import { apiPost } from "../../utils"

export function loginAPI(data) {
    return apiPost("/api/login", data)
}

export function signupAPI(data) {
    return apiPost("/api/signup", data)
}

export function verifyEmailOTPAPI(data) {
    return apiPost("/api/verifyEmailOTP", data)
}

export function resendEmailOTPAPI(data) {
    return apiPost("/api/resendEmailOTP", data)
}

export function forgetPasswordAPI(data) {
    return apiPost("/api/forgetPassword", data)
}

export function VerifyForgetPasswordOTPAPI(data) {
    return apiPost("/api/VerifyForgetPasswordOTP", data)
}

export function ChangeUserPasswordAPI(data, headers) {
    return apiPost("/api/ChangeUserPassword", data, headers)
}