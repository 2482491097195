import { apiGet, apiPost } from "../../utils";

// release and songs
export function getReleaseTitlesAPI() {
  return apiGet("/api/getReleaseTitles");
}

export function getReleaseTitlesPlaylistAPI() {
  return apiGet(`/api/getReleaseTitlesPlaylist`);
}

export function getAudioTitlesByReleaseIdAPI(release_id = "") {
  return apiGet(`/api/getAudioTitles?release_id=${release_id}`);
}

// history
export function getUGCClaimHistoryAPI(data = {}) {
  return apiGet("/api/getUGCClaimHistory", data);
}

export function getProfileLinkingHistoryAPI(data = {}) {
  return apiGet("/api/getProfileLinkingHistory", data);
}

export function getPlaylistPitchHistoryAPI(data, ) {
  return apiGet(`/api/getPlaylistPitchHistory`, data);
}

// create
export function submitUGCClaimAPI(data) {
  return apiPost(`/api/submitUGCClaim`, data);
}

export function submitProfileLinkingAPI(data) {
  return apiPost(`/api/submitProfileLinking`, data);
}

export function submitPitchPlaylistAPI(data) {
  return apiPost(`/api/submitPitchPlaylist`, data);
}

//--- lyrics
export function getLyricsHistoryAPI(data, ) {
  return apiGet(`/api/getLyricsHistory`, data);
}

export function submitDistributeLyricsAPI(data) {
  return apiPost(`/api/submitDistributeLyrics`, data);
}
