import React, { useEffect, useState, useCallback } from "react";
import * as actions from "./actions";
import {
  Box,
  Grid,
  TextField,
  withStyles,
  Table,
  TableBody,
  Tooltip,
  TableRow,
  TableHead,
  TableCell,
  TableContainer,
  Paper,
  CircularProgress,
  Button,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  amazon_base_url,
  API_URL,
  apple_base_url,
  gaana_base_url,
  jiosaavn_base_url,
  kkbox_base_url,
  releaseFilters,
  spotify_base_url,
  tidal_base_url,
  wynk_base_url,
  yandex_base_url,
  youtube_base_url,
} from "../../constants";
import moment from "moment";
import CustomShowModal from "../../components/Common/CustomShowModal";
import * as actionRelease from "../Releases/CreateReleases/actions";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CancelIcon from "@material-ui/icons/Cancel";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { toastError, toastSuccess } from "../../utils";
import SelectCmp from "react-select";
import Loader from "react-loader-advanced";
import Pagination from "@material-ui/lab/Pagination";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const CssTextField = withStyles({
  root: {
    // background: "transparent",
    fontFamily: "'DM Sans', sans-serif",
    color: "#fff",
    width: "100%",
    "& label": {
      fontFamily: "'DM Sans', sans-serif",
      // color: '#10baa8',
      color: "white",
    },
    "& label.Mui-focused": {
      // color: '#10baa8',
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "red",
    },
    "& .MuiOutlinedInput-root": {
      // '& fieldset': {
      // borderColor: 'white',
      // },
      // '&:hover fieldset': {
      // borderColor: 'white',
      // },
      "&.Mui-focused fieldset": {
        borderColor: "#10baa8",
        borderWidth: "1px",
      },
    },
  },
})(TextField);

const selectStyles = {
  control: (base, state) => ({
    ...base,
    border: `1px solid ${state.isFocused ? "#00a1ff" : "#767676"} `,
    boxShadow: "none",
    background: "transparent",
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#c7c7c7",
    };
  },
};

export default function Catalog(props) {
  const history = useHistory();

  const [catelogs, setCatelogs] = useState([]);
  const [openStore, setOpenStore] = useState(false);
  const [logos, setLogos] = useState([]);
  // const [search, setSearch] = useState('')
  // const [selectedSort, setSelectedSort] = useState(releaseFilters[0])
  const [loader, setLoader] = useState(true);
  const [selectedReleaseTitle, setSelectedReleaseTitle] = useState(null);
  const [count, setCount] = useState(0);

  const [filters, setFilters] = useState({
    sort: releaseFilters[0],
    limit: 25,
    offset: 0,
    page: 1,
    search: "",
  });

  const delaySearch = useCallback(
    _.debounce((filters) => onGetCatelogList(filters), 500),
    []
  );

  useEffect(() => {
    onGetCatelogList(filters);
    setTimeout(() => {
      setLoader(false);
    }, 5000);
  }, []);

  const onGetCatelogList = (filters) => {
    props.setLoader(true);
    actions
      .getCatelogList({ ...filters, sort: filters.sort.value })
      .then((res) => {
        props.setLoader(false);
        setCatelogs(res.body);
        setCount(res.total);
        onFetchLogos();
      })
      .catch((err) => {
        props.setLoader(false);
        toastError(err.message);
      });
  };

  const onFetchLogos = () => {
    actionRelease.getLogos().then((res) => {
      setLogos(
        _.compact(
          res.body.map((item) => {
            if (item.type === "1") return { ...item, checked: true };
            else return null;
          })
        )
      );
    });
  };

  const onDeleteDraft = (e, id) => {
    e.stopPropagation();
    props.setLoader(true);
    actionRelease
      .deleteDraft({ release_id: id })
      .then((res) => {
        props.setLoader(false);
        toastSuccess(res.message);
        onGetCatelogList();
      })
      .catch((err) => {
        props.setLoader(false);
      });
  };

  const onPageChange = (event, page) => {
    const offset = (page - 1) * filters.limit;
    const newFilters = { ...filters, page, offset };
    setFilters(newFilters);
    onGetCatelogList(newFilters);
  };

  const onChangeSearch = (e) => {
    const newFilters = { ...filters, search: e.target.value };
    setFilters(newFilters);
    delaySearch(newFilters);
  };

  const onSelectType = (e) => {
    const newFilters = { ...filters, offset: 0, page: 1, sort: e };
    setFilters(newFilters);
    onGetCatelogList(newFilters);
  };

  let newCatelogs = catelogs;
  // if (search) {
  //     newCatelogs = catelogs.filter(item => (
  //         (item.artist_name ? item.artist_name.toLowerCase().includes(search.toLowerCase()) : null) ||
  //         (item.release_title ? item.release_title.toLowerCase().includes(search.toLowerCase()) : null) ||
  //         (item.lable_name ? item.lable_name.toLowerCase().includes(search.toLowerCase()) : null)
  //     ))
  // }

  const LoaderStyle = {
    height: "100%",
    overflow: "hidden",
  };

  let totalPages = Math.ceil(Number(count) / filters.limit);

  return (
    <Grid container className="catelog-container">
      <Grid item xs={12}>
          <Button
            className="edit-re-submit"
            onClick={() => history.push("/video-catalog")}
          >
            Go To Video Catalog
          </Button>
      </Grid>
      <Grid item xs={12}>
        <Box mt={3}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <CssTextField
                type="text"
                variant="outlined"
                size="small"
                onChange={onChangeSearch}
                value={filters.search}
                placeholder="Search..."
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div style={{ width: "90%", marginLeft: 10 }}>
                <SelectCmp
                  styles={selectStyles}
                  placeholder="Filters"
                  value={filters.sort}
                  onChange={onSelectType}
                  options={releaseFilters}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <h2 style={{ float: "right" }}>
                <span style={{ fontWeight: 500 }}>Total Audio Releases:</span>&nbsp;
                {count}
              </h2>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box mt={4}>
          <TableContainer component={Paper}>
            <Table className="universal-table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">No.</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Artwork</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Artist Name</TableCell>
                  <TableCell>Genre</TableCell>
                  <TableCell>Label</TableCell>
                  <TableCell># of tracks</TableCell>
                  <TableCell>Relase Date</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {newCatelogs && newCatelogs.length ? (
                  newCatelogs.map((item, key) => {
                    let goTo = `/catalog/${item.release_id}`;
                    if (item.status === "draft") {
                      goTo = `/create_releases/${item.release_id}`;
                    }

                    return (
                      <TableRow
                        hover
                        key={key}
                        onClick={() => history.push(goTo)}
                      >
                        <TableCell align="center">
                          {(filters.page - 1) * filters.limit + (key + 1)}
                        </TableCell>
                        <TableCell align="center">
                          <p onClick={(e) => e.stopPropagation()}>
                            {item.status === "in_review" ? (
                              <LightTooltip title="In Review">
                                <AccessTimeIcon />
                              </LightTooltip>
                            ) : item.status === "draft" ? (
                              <LightTooltip title="Draft">
                                <BookmarksIcon />
                              </LightTooltip>
                            ) : item.status === "rejected" ? (
                              <LightTooltip title="Rejected">
                                <CancelIcon style={{ color: "red" }} />
                              </LightTooltip>
                            ) : item.status === "takendown" ? (
                              <LightTooltip title="Takendown">
                                <RemoveCircleOutlineIcon
                                  style={{ color: "red" }}
                                />
                              </LightTooltip>
                            ) : (
                              <LightTooltip title="Approved">
                                <CheckCircleIcon style={{ color: "green" }} />
                              </LightTooltip>
                            )}
                          </p>
                        </TableCell>
                        <TableCell style={{ padding: 4 }}>
                          <div className="album-artwork-catalog">
                            <img
                              src={`${API_URL}/${item.album_artwork}`}
                              height="60px"
                              alt=""
                            />
                          </div>
                        </TableCell>
                        <TableCell>{item.release_title}</TableCell>
                        <TableCell>{item.artist_name}</TableCell>
                        <TableCell>{item.genre}</TableCell>
                        <TableCell>{item.lable_name}</TableCell>
                        <TableCell>{item.number_of_tracks}</TableCell>
                        <TableCell>
                          {moment(Number(item.release_date)).format(
                            "DD/MM/yyyy"
                          )}
                        </TableCell>
                        <TableCell>
                          {item.status === "draft" ||
                          item.status === "rejected" ? (
                            <div
                              className="delete-draft"
                              onClick={(e) => onDeleteDraft(e, item.release_id)}
                            >
                              <span>Delete</span>
                            </div>
                          ) : item.status === "approved" ? (
                            <div
                              className="more-stores"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedReleaseTitle(item.release_title);
                                setOpenStore(true);
                              }}
                            >
                              <span>Stores</span>
                            </div>
                          ) : (
                            "--"
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan="10"
                      style={{ fontWeight: 600 }}
                    >
                      No Release Created.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Pagination
              className="table-pagination"
              count={totalPages}
              showFirstButton
              showLastButton
              onChange={onPageChange}
              page={filters.page || 0}
            />
          </TableContainer>
        </Box>
      </Grid>

      <CustomShowModal
        open={openStore}
        handleClose={() => setOpenStore(false)}
        maxWidth="md"
      >
        <Loader
          className="platform-loader"
          style={loader ? LoaderStyle : null}
          message={<CircularProgress size={50} style={{ color: "black" }} />}
          show={loader}
        >
          <Grid container>
            {logos && logos.length
              ? logos.map((item, key) => {
                  if (item.name === "Spotify") {
                    return (
                      <Grid item xs={12} sm={6} md={4} key={key}>
                        <Box display="flex" p={2} alignItems="center">
                          <img src={item.logo} alt="" height="35" />
                          <a
                            href={spotify_base_url.concat(selectedReleaseTitle)}
                            style={{
                              marginLeft: 15,
                              fontWeight: 500,
                              textDecoration: "underline",
                            }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.name}
                          </a>
                        </Box>
                      </Grid>
                    );
                  }
                  if (item.name === "Apple Music") {
                    return (
                      <Grid item xs={12} sm={6} md={4} key={key}>
                        <Box display="flex" p={2} alignItems="center">
                          <img src={item.logo} alt="" height="35" />
                          <a
                            href={apple_base_url.concat(selectedReleaseTitle)}
                            style={{
                              marginLeft: 15,
                              fontWeight: 500,
                              textDecoration: "underline",
                            }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.name}
                          </a>
                        </Box>
                      </Grid>
                    );
                  }
                  if (item.name === "Amazon Music") {
                    return (
                      <Grid item xs={12} sm={6} md={4} key={key}>
                        <Box display="flex" p={2} alignItems="center">
                          <img src={item.logo} alt="" height="35" />
                          <a
                            href={amazon_base_url.concat(selectedReleaseTitle)}
                            style={{
                              marginLeft: 15,
                              fontWeight: 500,
                              textDecoration: "underline",
                            }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.name}
                          </a>
                        </Box>
                      </Grid>
                    );
                  }
                  if (item.name === "JioSaavn") {
                    return (
                      <Grid item xs={12} sm={6} md={4} key={key}>
                        <Box display="flex" p={2} alignItems="center">
                          <img src={item.logo} alt="" height="35" />
                          <a
                            href={jiosaavn_base_url.concat(
                              selectedReleaseTitle
                            )}
                            style={{
                              marginLeft: 15,
                              fontWeight: 500,
                              textDecoration: "underline",
                            }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.name}
                          </a>
                        </Box>
                      </Grid>
                    );
                  }
                  if (item.name === "Wynk Music") {
                    return (
                      <Grid item xs={12} sm={6} md={4} key={key}>
                        <Box display="flex" p={2} alignItems="center">
                          <img src={item.logo} alt="" height="35" />
                          <a
                            href={wynk_base_url.concat(selectedReleaseTitle)}
                            style={{
                              marginLeft: 15,
                              fontWeight: 500,
                              textDecoration: "underline",
                            }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.name}
                          </a>
                        </Box>
                      </Grid>
                    );
                  }
                  if (item.name === "Gaana") {
                    return (
                      <Grid item xs={12} sm={6} md={4} key={key}>
                        <Box display="flex" p={2} alignItems="center">
                          <img src={item.logo} alt="" height="35" />
                          <a
                            href={gaana_base_url.concat(selectedReleaseTitle)}
                            style={{
                              marginLeft: 15,
                              fontWeight: 500,
                              textDecoration: "underline",
                            }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.name}
                          </a>
                        </Box>
                      </Grid>
                    );
                  }
                  if (item.name === "Tidal") {
                    return (
                      <Grid item xs={12} sm={6} md={4} key={key}>
                        <Box display="flex" p={2} alignItems="center">
                          <img src={item.logo} alt="" height="35" />
                          <a
                            href={tidal_base_url.concat(selectedReleaseTitle)}
                            style={{
                              marginLeft: 15,
                              fontWeight: 500,
                              textDecoration: "underline",
                            }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.name}
                          </a>
                        </Box>
                      </Grid>
                    );
                  }
                  if (item.name === "Youtube Music") {
                    return (
                      <Grid item xs={12} sm={6} md={4} key={key}>
                        <Box display="flex" p={2} alignItems="center">
                          <img src={item.logo} alt="" height="35" />
                          <a
                            href={youtube_base_url.concat(selectedReleaseTitle)}
                            style={{
                              marginLeft: 15,
                              fontWeight: 500,
                              textDecoration: "underline",
                            }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.name}
                          </a>
                        </Box>
                      </Grid>
                    );
                  }
                  if (item.name === "KK Box") {
                    return (
                      <Grid item xs={12} sm={6} md={4} key={key}>
                        <Box display="flex" p={2} alignItems="center">
                          <img src={item.logo} alt="" height="35" />
                          <a
                            href={kkbox_base_url.concat(selectedReleaseTitle)}
                            style={{
                              marginLeft: 15,
                              fontWeight: 500,
                              textDecoration: "underline",
                            }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.name}
                          </a>
                        </Box>
                      </Grid>
                    );
                  }
                  if (item.name === "Yandex") {
                    return (
                      <Grid item xs={12} sm={6} md={4} key={key}>
                        <Box display="flex" p={2} alignItems="center">
                          <img src={item.logo} alt="" height="35" />
                          <a
                            href={yandex_base_url.concat(selectedReleaseTitle)}
                            style={{
                              marginLeft: 15,
                              fontWeight: 500,
                              textDecoration: "underline",
                            }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.name}
                          </a>
                        </Box>
                      </Grid>
                    );
                  }
                  return (
                    <Grid item xs={12} sm={6} md={4} key={key}>
                      <Box display="flex" p={2} alignItems="center">
                        <img src={item.logo} alt="" height="35" />
                        <span style={{ marginLeft: 15, fontWeight: 500 }}>
                          {item.name}
                        </span>
                      </Box>
                    </Grid>
                  );
                })
              : null}
          </Grid>
        </Loader>
      </CustomShowModal>
    </Grid>
  );
}
