import React, { useState } from "react";
import {
  Grid,
  Box,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import logo from "../../../assets/img/Fronic_Logo_white.png";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { withStyles, makeStyles, fade } from "@material-ui/core/styles";
import axios from "axios";
import { toastError, toastSuccess } from "../../../utils";

const StyledButton = withStyles({
  root: {
    fontFamily: "'DM Sans', sans-serif",
    background:
      "linear-gradient(90deg, rgba(19,153,121,1) 0%, rgba(18,180,161,1) 45%, rgba(5,150,179,1) 100%)",
    fontSize: 18,
    height: 50,
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

const CssTextField = withStyles({
  root: {
    // background: "transparent",
    fontFamily: "'DM Sans', sans-serif",
    color: "#fff",
    "& label": {
      fontFamily: "'DM Sans', sans-serif",
      // color: '#10baa8',
      color: "white",
    },
    "& label.Mui-focused": {
      // color: '#10baa8',
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "red",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#10baa8",
      },
    },
  },
})(TextField);

const useStylesReddit = makeStyles((theme) => ({
  root: {
    border: "1px solid #e2e2e1",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "#fcfcfb",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}));

export default function Login(props) {
  //ankush
  const [isCapchaGood, setIsCapchaGood] = useState(true);
  const {
    user,
    errors,
    onChangeHandler,
    onLoginIn,
    loginLoader,
    setShowforgot,
  } = props;
  const classes = useStylesReddit();
  const history = useHistory();

  const keyPressed = (e) => {
    if (e.key === "Enter") {
      if (!loginLoader && isCapchaGood) {
        onLoginIn();
      }
    }
  };

  //---capcha code starts--------------------------

  async function onChangeCapcha(value) {
    await axios
      .post(
        `https://backend.fronicmedia.com/api/capchaVerification?responseCapcha=${value}`
      )
      .then((res) => {
        if (res?.data?.body?.success === false) {
          setIsCapchaGood(false);
          toastError("Captcha expired. Try again ?");
        } else if (res?.data?.body?.success === true) {
          setIsCapchaGood(true);
          toastSuccess("Good to see you Human.");
        } else {
          toastError("Captcha verification failed.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //---code ends-----------------------------------

  return (
    <div className="login-div">
      <Grid container>
        <Grid item xs={12}>
          <div className="logo-image-div">
            <img src={logo} alt="" />
          </div>
        </Grid>

        <Grid item xs={12}>
          <Box pt={5} pb={3}>
            <CssTextField
              className={classes.margin}
              label="Email"
              variant="outlined"
              id="custom-css-outlined-input"
              value={user.email}
              error={!!errors.email}
              onChange={(e) => onChangeHandler("email", e)}
              fullWidth
              autoFocus
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box pt={0.3}>
            <CssTextField
              type="password"
              className={classes.margin}
              value={user.password}
              error={!!errors.password}
              onChange={(e) => onChangeHandler("password", e)}
              label="Password"
              variant="outlined"
              id="custom-css-outlined-input"
              onKeyPress={keyPressed}
              fullWidth
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box pt={1} pb={5}>
            <p className="forgot-password" onClick={() => setShowforgot(true)}>
              Forgot Password?
            </p>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ReCAPTCHA
            sitekey="6LdUG_gjAAAAAEXj3qkc5X-jlW4Z4JOpqPiopmmm"
            onChange={onChangeCapcha}
          />
        </Grid>

        <Grid item xs={12}>
          <Box pt={0.5}>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={onLoginIn}
              disabled={!isCapchaGood}
              fullWidth
              style={isCapchaGood ? {} : { background: "grey" }}
            >
              {loginLoader ? (
                <CircularProgress size={25} style={{ color: "white" }} />
              ) : (
                "Login"
              )}
            </StyledButton>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box pt={1} pb={4}>
            <p
              className="dont-have-account"
              onClick={() => history.push("/signup")}
            >
              Don't have an account? Sign up
            </p>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
